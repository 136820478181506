<template>
    <g :transform='transform' :visibility='show'>
        <!-- delete -->
        <delete-button-svg :x='offset' :y='buttonSize * 0' :size='buttonSize' v-on:delete-component="$emit('delete-component')"></delete-button-svg>
        <!-- duplicate -->
        <duplicate-button-svg :x='offset' :y='buttonSize * 1' :size='buttonSize' v-on:duplicate-component="$emit('duplicate-component')"></duplicate-button-svg>
        <!-- text input -->
        <text-button-svg :x='offset' :y='buttonSize * 2' :size='buttonSize' v-on:open-text-input="$emit('open-text-input')"></text-button-svg>
        <!-- settings -->
        <settings-button-svg :x='offset' :y='buttonSize * 3' :size='buttonSize' v-on:open-settings="$emit('open-settings')"></settings-button-svg>

        <!-- mirrorX -->
        <mirror-button-svg v-if="editor.mirror" :x='offset' :y='buttonSize * 4' :size='buttonSize' v-on:mirror-component="$emit('mirror-component')"></mirror-button-svg>
        <!-- rotate -->
        <rotate-button-svg v-if="editor.rotate" :x='offset' :y='buttonSize * 5' :size='buttonSize' v-on:rotate-component="$emit('rotate-component')"></rotate-button-svg>
        <!-- backward -->
        <backward-button-svg v-if="editor.changeInOut" :x='offset' :y='buttonSize * 6' :size='buttonSize' v-on:backward-component="$emit('backward-component')"></backward-button-svg>


    </g>
</template>

<script>
import DeleteComponentButtonSVG from './DeleteComponentButtonSVG'
import DuplicateButtonSVG from './DuplicateButtonSVG'
import SettingsButtonSVG from './SettingsButtonSVG'
import TextButtonSVG from './TextButtonSVG'
import MirrorButtonSVG from './MirrorButtonSVG'
import RotateButtonSVG from './RotateButtonSVG'
import BackwardButtonSVG from './BackwardButtonSVG'

export default {
    name: 'SideMenu',
    components:{
        "delete-button-svg": DeleteComponentButtonSVG,
        "duplicate-button-svg": DuplicateButtonSVG,
        "settings-button-svg": SettingsButtonSVG,
        "text-button-svg": TextButtonSVG,
        "mirror-button-svg": MirrorButtonSVG,
        "rotate-button-svg": RotateButtonSVG,
        "backward-button-svg": BackwardButtonSVG,
    },
    props: {
        position: {
            type: Object,
            required: true,
            default: function () {
                return { position: { left: 0, top: 0 } };
            }
        }, 
        editor: {
            type: Object,
            default: function () {
                return {
                    mirror: false,
                    rotate: false,
                    changeInOut: false,
                };
            }
        },
        show: {
            type: String,
            required: true,
        }
    },
    data(){
        return{
            buttonSize: 32,
            offset: 5,
            // display: 'visible',
        }
    },
    computed: {
        transform(){
                // console.log("this.translateGroup");
                // console.log(this.translateGroup);
                return `translate( ${-this.position.left},  ${this.position.top} )`;
        },
        // visibility(){
        //     return this.display;
        // }
    },
    methods:{

    }
}
</script>