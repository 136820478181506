<template>
    <g :transform='transform'>
        <!-- <ellipse cx=0 cy=0 rx=2 ry=2 /> -->
        <g v-html="innerHtml" :transform="createTranslation()"></g><!-- {{{innerHtml}}} -->
    </g>

</template>

<script>
//import xmlParser from 'fast-xml-parser';
import svgson from 'svgson'
import axios from 'axios'

export default {
    name: 'SVGEmbed',
    // components: {
    //     "settings-button-svg": SettingsButtonSVG,
    //     "delete-button-svg": DeleteComponentButtonSVG,
    //     "duplicate-button-svg": DuplicateButtonSVG,
    // },
    props: {
        svgName: {
            type: String,
            required: true,
        },
        svgFile: {
            type: String,
            required: true
        },
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        transform: {
            type: String,
            required: true
        },
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        scale: {
            type: Number,
        }
    },
    data(){
        return {
                innerHtml: "test",
        }
    },
    methods: {
        createTranslation: function(){
            return `translate( ${this.x},  ${this.y} ), scale(${this.scale/9}, ${this.scale/9})`;
            

        },

        replaceAll: function(string, search, replace) {
            return string.split(search).join(replace);
        },
        loadSvg: function(){
            axios.get(this.svgFile)
            .then((response) =>{
                //console.log(response.data);
                //let file = xmlParser.parse(response.data);
                let file = svgson.parseSync(response.data);
                //console.log("XML File:");
                //console.log(file);  
                
                // match ".st62{"     /\.[a-zA-Z0-9]+?(?=\{)/g
                // console.log(svgson.stringify(file.children[0]));
                let classNames = svgson.stringify(file.children[0]).match(/\.[a-zA-Z][a-zA-Z0-9- ]*?(?=[{,])/g);
                //console.log("classNames");
                //console.log(classNames);

                let reducedClassNames = [];
                for(let i = 0; i<classNames.length; i++){
                    if(!reducedClassNames.includes(classNames[i].trim().slice(1))){
                        reducedClassNames.push(classNames[i].trim().slice(1));
                    }
                }

                //console.log("reducedClassNames");
                //console.log(reducedClassNames);

                let svgString = svgson.stringify(file.children);
                for(let i = 0; i<reducedClassNames.length; i++){
                    
                    let newClassName = reducedClassNames[i].slice(1)+this.svgName;
                    // replace '_' with '-' in classnames:
                    newClassName = this.replaceAll(newClassName, '_', '-');

                    svgString = this.replaceAll(svgString, reducedClassNames[i], newClassName);
                }
                this.innerHtml = svgString;
            }).catch(function(error){
                console.log("failed loading svg");
                console.log(error);
            })
        }
        
        //...mapActions(['updateComponentOffset']),
        // getScalefactor: function(){
        //     return globalStore.zoomfactor;
        // },  
    },
    computed: {
    }, 
    //mounted(){
    created(){
        this.loadSvg();
    },
    watch: {
        svgName: {
            deep: true,
            handler() {
                this.loadSvg();
                /*setTimeout(() => {
                // unhover all connectors
                console.log("changed components");
                this.removeConnections();
                this.createConnections();
                }, 150);*/
            },
        },
    }

}
</script>

<style scoped>
</style>