<template>
    <b-dropdown-item href="#"  @click="askForName"><slot></slot></b-dropdown-item>
</template>

<script>
import { mapGetters } from 'vuex';
import posHelper from './PositionHelperFunctions'
import cloneDeep from "lodash.clonedeep";

export default {
    name: 'SaveModule',
    methods:{
        ...mapGetters(["getPlant"]),
        askForName(){
            let fileName = prompt("Please enter module name", "module_name");
            if (fileName != null) {
                this.saveModule(fileName);
            }
        },

        saveModule(name){
            console.log("plant: ");
            console.log(JSON.stringify(this.getPlant()));

            let plant = this.getPlant();
            let _module = {};

            // get center offset of _module:
            
            let minX = 9999999;
            let maxX = -9999999;
            let minY = 9999999;
            let maxY = -9999999

            for(let i = 0; i<plant.components.length; i++) {
                let comp = plant.components[i];
                let bbox = posHelper.getComponentBoundingBox(comp.id);
                console.log(bbox);
                if(minX > bbox.minX){
                    minX = bbox.minX;
                }
                if(maxX < bbox.maxX){
                    maxX = bbox.maxX;
                }
                if(minY > bbox.minY){
                    minY = bbox.minY;
                }
                if(maxY < bbox.maxY){
                    maxY = bbox.maxY;
                }
            }

            console.log(minX  + " " + minY + " " + maxX + " " + maxY);

            let offset = {};
            offset.x = Math.round((minX + maxX)/2);
            offset.y = Math.round((minY + maxY)/2);

            _module.components = [];
            _module.connections = [];


            let idParing = {};

            for(let i = 0; i<plant.components.length; i++) {
                let comp = plant.components[i];

                idParing[comp.id] = i;

                let modComp = {
                    moduleElementId: i,
                    name: comp.name,
                    position: cloneDeep(comp.position), 
                };

                modComp.position.left -= offset.x;
                modComp.position.top -= offset.y;

                _module.components.push(modComp);      
            }

            for(let i = 0; i<plant.connections.length; i++) {
                let con = plant.connections[i];

                let modCon = {
                    in: {
                        id: idParing[con.in.id],
                        no: con.in.no,
                    },
                    out: {
                        id: idParing[con.out.id],
                        no: con.out.no,
                    },
                    type: con.type,
                }
                // handles
                if(con.handles){
                    modCon.handles = cloneDeep(con.handles);
                }



                _module.connections.push(modCon);    
            }

            console.log("NEW Module to copy:");
            console.log(JSON.stringify(_module));
            
            this.download(JSON.stringify(_module), name + '.txt', 'text/plain');
            
        },
        download(content, fileName, contentType) {
            let a = document.createElement("a");
            let file = new Blob([content], {type: contentType});
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
        }
    }
}
</script>