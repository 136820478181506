<template>
<div class="overlayBackground">
    <div class="overlay">
        
        <p>Settings for component: <br />
        <b>ID: {{componentId}}</b> ({{componentName}})</p>
        <b-form-textarea
            id="textarea"
            v-model="text"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
        ></b-form-textarea>
        <!-- <pre class="mt-3 mb-0">{{ text }}</pre> -->
        <br />
        <b-button @click="saveChanges" style="margin-right: 1em">apply</b-button> <delete-window label="cancel" v-on:close-window="removeSelf"></delete-window>
    </div>
</div>
</template>

<script>
// import Vue from 'vue'
import DeleteWindow from './DeleteWindow';
import { EventBus } from './event-bus';
// import { mapGetters } from 'vuex'
//import Vue from 'vue'
//import { mapGetters } from 'vuex'

export default {
    name: "TextInputWindow",
    components:{
        'delete-window': DeleteWindow,
    },
    data(){
        return{
            text: '',
            /*option: '',
            version: '',
            versions: [],*/
        }
    },
    methods:{
        saveChanges(){
            //console.log("save changes to Text: " );
            //console.log(this.text);
            let data = {
                    id: this.componentId,
                    text: this.text,
                };
            // console.log(data)
            EventBus.$emit('changeComponent', data);
            this.removeSelf();
        },
        removeSelf(){
            console.log("remove Window");
            this.$el.remove();
        }
    },
    props: {
        // plantComponentID
        componentName: {
            type: String,
            required: true,
        },
        componentId: {
            type: Number,
            required: true,
        },
        lastText: {
            type: String,
        }
    },
    computed: {
        // ...mapGetters(["allComponentTypes"]),
    },
    mounted() {
        if(this.lastText && this.lastText.length > 0){
            this.text = this.lastText;
        }
        /*this.option = this.componentName;
        this.version = this.componentVersion;
        this.getVersions();*/

            // const current = this.allComponentTypes[this.componentName];
            // let selection = [];

            // Object.keys(this.allComponentTypes).forEach(function(name){
            //     if(this.allComponentTypes[name].class == current.class){
            //         selection.push(name);
            //     }
            // });

            // console.log(selection);
            // //return "test";
        
    },

}
</script>

<style scoped>

    .overlay {
        position: relative;
        min-width: 300px;
        /* min-height: 150px; */
        background-color: #fff;
        /*border: #000 solid 1px;*/
        padding: 20px;
        
    }
    .overlayBackground {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;

    }
    label {
        padding-right: 1em;
    }
</style>