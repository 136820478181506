<template>
    <g :transform="transform">
        <g :transform="transformIcon">
            <path class="noStroke" :fill="fill" d="M10,10H-10V-10H10V10z M-9,9H9V-9H-9V9z"/>
            <path class="noStroke" :fill="fill" d="M1.3,7.7h-9V-1h9V7.7z M-6.7,6.7h7V0h-7V6.7z"/>
            <polygon class="noStroke" :fill="fill" points="-0.9,2.9 -2.9,2.9 -2.9,1 -3.9,1 -3.9,2.9 -5.8,2.9 -5.8,3.9 -3.9,3.9 -3.9,5.9 -2.9,5.9 -2.9,3.9 -0.9,3.9 "/>
            <path class="noStroke" :fill="fill" d="M-1-3.5h-6.7V-8H-1V-3.5z M-6.7-4.5H-2V-7h-4.7V-4.5z"/>
            <path class="noStroke" :fill="fill" d="M7.5,3.7H2.5v-9.2h4.9V3.7z M3.5,2.7h2.9v-7.2H3.5V2.7z"/>
        </g>
        <!-- :class='[{deleteHover: hover}]' class="button" -->
        <rect @click="$emit('enable-edit-module');" :class="{addMode: active}" :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'EditModuleButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;

            if(val){
                this.fill = "#006eee"
            } else {
                this.fill = "#999"
            }
        },
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true,        
        },
        active: {
            type: Boolean,
        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(1.2, 1.2)`;
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999",
            //active: false,
        }
    }
}
</script>

<style scoped>
rect {
    fill: rgba(255, 255,255, 0);
    cursor: pointer;
}

.addMode {
    fill: rgba(89, 158, 242, 0.3) !important;
}
.noStroke {
    stroke-width:0;
}
</style>