<template>
    <g :transform="transform">
        <g :transform='transformIcon'>
            <polygon class="noStroke" :fill="fill" points="10.6,-4.6 8.8,-7.7 7,-10.8 5.2,-7.7 3.4,-4.6 7,-4.6 "/>
            <polygon class="noStroke" :fill="fill" points="-10.6,4.5 -8.8,7.6 -7,10.6 -5.2,7.6 -3.4,4.5 -7,4.5 "/>
            <polygon class="noStroke" :fill="fill" points="8,6.6 3.2,6.6 3.2,3.9 -0.5,7.6 3.2,11.2 3.2,8.6 10,8.6 10,0.4 8,0.4 "/>
            <polygon class="noStroke" :fill="fill" points="-8.3,-6.6 -3.5,-6.6 -3.5,-3.9 0.1,-7.6 -3.5,-11.2 -3.5,-8.6 -10.3,-8.6 -10.3,-0.5 -8.3,-0.5 "/>
        </g>
        <!-- :class='[{deleteHover: hover}]' class="button" -->
        <rect @click="$emit('backward-component')" :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'BackwardButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;

            if(val){
                this.fill = "#006eee"
            } else {
                this.fill = "#999"
            }
        },
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true,        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(1.2, 1.2)`;
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999",
        }
    }
}
</script>

<style scoped>
rect {
    fill: rgba(255, 255,255, 0);
    cursor: pointer;
}

.noStroke {
    stroke-width:0;
}
</style>