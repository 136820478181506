<template>
    <g :transform='transform' :id='"component_group_" + compValues.id'>
        <!-- temp transform -->
        <g transform='translate(0, 0)'>
            <svg-embed :svgFile="item.svg" :svgName="compValues.name" :x='origCornerPosition.left' :y='origCornerPosition.top' :transform='scaleRotate' :scale="sizeMultiplicator" :width="item.origWidth * sizeMultiplicator" :height="item.origHeight * sizeMultiplicator"></svg-embed>
            <image v-if='!item.svg && item.image && workspace' :x='origCornerPosition.left' :y='origCornerPosition.top' :width="item.origWidth * sizeMultiplicator" :height="item.origHeight * sizeMultiplicator" :xlink:href="item.image" v-on:touchstart='startDragElement' />
            <text v-if="compValues.text" :x='xTextField()' :y='yTextField()' font-family="Verdana" :font-size='textFontSize()' fill="grey">  
                <tspan v-for="span in getFormattedText()" :x='xTextField()' fill="grey" dy="1.3em" :key="span.key">{{span}}</tspan>
            </text>
            <rect v-if="workspace || (compValues.assignment && compValues.assignment != 'coperion')" :id='"component_rect_" + compValues.id' :class="[{hoverClass: hover}, {fillClass: fillMode}, compValues.assignment]" :width="item.width * sizeMultiplicator" :height="item.height * sizeMultiplicator"  :x='cornerPosition().left' :y='cornerPosition().top' @click="showConfigure();checkCreateConnection();" v-on:mousedown='startDragElement' v-on:touchstart='startDragElement();showConfigure();' v-on:mouseenter='setHover(true)' v-on:mouseleave='setHover(false)'></rect>
            <text v-if="workspace" :x='cornerPosition().left + 2' :y='cornerPosition().top + 8' font-family="Verdana" font-size=8 fill="grey">{{compValues.id}}</text>
            <!-- <configure-button-svg v-if="workspace" :class="{ hide: !hover }" :x='-cornerPosition().left - 34' :y='cornerPosition().top + 5' :size='buttonSize' v-on:open-configure='showConfigure' v-on:set-hover="setHover"></configure-button-svg> -->
            <a v-if="workspace && item.showcase" :href="item.showcase" target="_blank"><showcase-button-svg :x='cornerPosition().left + 2' :y='-cornerPosition().top - 20' :size='buttonSize-10'></showcase-button-svg></a>
            <module-button-svg v-if="workspace" :class="{ hide: !showAddTo }" :x='0' :y='0' :size='buttonSize' v-on:toggle-module='addToModule' v-on:set-hover="setHover" :addTo="addTo"></module-button-svg>
            <io-connector-group-svg v-on:create-connection='createConnection' :item='item' :componentId='compValues.id' :connectors='item.connectors.top' position='top' :translateGroup='translateTop' :workspace='workspace' ></io-connector-group-svg> <!-- :visibility='connectorsVisible' -->
            <io-connector-group-svg v-on:create-connection='createConnection' :item='item' :componentId='compValues.id' :connectors='item.connectors.right' position='right' :translateGroup='translateRight' :workspace='workspace'></io-connector-group-svg><!-- :visibility='connectorsVisible' -->
            <io-connector-group-svg v-on:create-connection='createConnection' :item='item' :componentId='compValues.id' :connectors='item.connectors.bottom' position='bottom' :translateGroup='translateBottom' :workspace='workspace'></io-connector-group-svg><!-- :visibility='connectorsVisible' -->
            <io-connector-group-svg v-on:create-connection='createConnection' :item='item' :componentId='compValues.id' :connectors='item.connectors.left' position='left' :translateGroup='translateLeft' :workspace='workspace'></io-connector-group-svg><!-- :visibility='connectorsVisible' -->
            <side-menu-svg v-if="workspace" :editor="item.editor" :position='cornerPosition()' v-on:delete-component="$emit('delete-component-id', compValues.id)" v-on:duplicate-component="$emit('duplicate-component-id', compValues.id)" v-on:mirror-component="$emit('mirror-component-id', compValues.id)" v-on:rotate-component="$emit('rotate-component-id', compValues.id)" v-on:backward-component="$emit('backward-component-id', compValues.id)" v-on:open-text-input='openTextInput' v-on:open-settings='showSettings' :show='configure'></side-menu-svg>
        </g>        
    </g>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import IOConnectorGroupSVG from './IOConnectorGroupSVG'
import ShowcaseButtonSVG from './ShowcaseButtonSVG'
// import ConfigureButtonSVG from './ConfigureButtonSVG'
import ModuleButtonSVG from './ModuleButtonSVG'
// import DeleteComponentButtonSVG from './DeleteComponentButtonSVG'
// import SettingsButtonSVG from './SettingsButtonSVG'
import SettingsWindow from './SettingsWindow.vue'
import TextInputWindow from './TextInputWindow.vue'
import SideMenu from './SideMenu.vue'
import SVGEmbed from './SVGEmbed'
import posHelper from './PositionHelperFunctions';

import { EventBus } from './event-bus.js'
import { globalStore } from '../main.js'
import cloneDeep from "lodash.clonedeep";

export default {
    name: 'PlantComponentSVG',
    components:{
        "io-connector-group-svg": IOConnectorGroupSVG,
        "showcase-button-svg": ShowcaseButtonSVG,
        // "configure-button-svg": ConfigureButtonSVG,
        "module-button-svg": ModuleButtonSVG,
        "svg-embed": SVGEmbed,
        // "delete-button-svg": DeleteComponentButtonSVG,
        // "settings-button-svg": SettingsButtonSVG,
        "side-menu-svg": SideMenu,
    },
    data(){
            return{
                sizeMultiplicator: 30,
                buttonSize: 28,
                connectorOffset: 5,
                pos1: '',
                pos2: '',
                pos3: '',
                pos4: '',
                moveX: 0,
                moveY: 0,
                touchElement: null,
                configure: "hidden",
                //connectorsVisible: "visible",
                hover: false,
                showAddTo: false,
                addTo: false,
                fillMode: false,
            }
    },
    props: {
            compValues: {
                type: Object,
                required: true,
                default: function(){
                    return {}
                }
            },
            workspace: {
                type: Boolean,
                default: true,
            }
    },
    computed: {
            ...mapGetters(["allComponentTypes", "getConnectionPoint", "getConnectionId", "isIdInModule"]),
            
            item: function(){
                //let copy = { ...this.allComponentTypes[this.compValues.name] };

                // console.log("create Plant component:");
                // console.log(this.compValues.name);
                // console.log(JSON.stringify(this.allComponentTypes[this.compValues.name]));

                let copy = cloneDeep(this.allComponentTypes[this.compValues.name]);

                // Ausnahme textfelder mit variabler höhe
                if(this.compValues.position.height){
                    copy.height = this.compValues.position.height;
                }
                //console.log("copy");
                //console.log(copy);
                // optional backward: in / outs tauschen.
                if(this.compValues.position.backward){

                    //console.log(this.compValues.position.backward);

                    for(let i = 0; i < copy.connectors.top.length; i++){
                        if(copy.connectors.top[i].inOut == 'input'){
                            //console.log("input to output");
                            copy.connectors.top[i].inOut = 'output';
                        } else {
                            copy.connectors.top[i].inOut = 'input';
                            //console.log("output to input");
                        }
                    }
                    for(let i = 0; i < copy.connectors.right.length; i++){
                        if(copy.connectors.right[i].inOut == 'input'){
                            copy.connectors.right[i].inOut = 'output';
                            //console.log("input to output");
                        } else {
                            copy.connectors.right[i].inOut = 'input';
                            //console.log("output to input");
                        }
                    }
                    for(let i = 0; i < copy.connectors.bottom.length; i++){
                        if(copy.connectors.bottom[i].inOut == 'input'){
                            //console.log("input to output");
                            copy.connectors.bottom[i].inOut = 'output';
                        } else {
                            copy.connectors.bottom[i].inOut = 'input';
                            //console.log("output to input");
                        }
                    }
                    for(let i = 0; i < copy.connectors.left.length; i++){
                        if(copy.connectors.left[i].inOut == 'input'){
                            copy.connectors.left[i].inOut = 'output';
                            //console.log("input to output");
                        } else {
                            copy.connectors.left[i].inOut = 'input';
                            //console.log("output to input");
                        }
                    }
                }


                // optional: zuerst spiegeln:
                if(this.compValues.position.mirror){
                    // TODO connectors: 
                    let left = [ ...copy.connectors.left ];

                    copy.connectors.left = copy.connectors.right;
                    copy.connectors.right = left;
                }

                copy.origWidth = copy.width;
                copy.origHeight = copy.height;
                // optional: danach alles rotieren:
                if(this.compValues.position.rotate){
                    if(this.compValues.position.rotate%2 == 1){
                        let w = copy.width;
                        copy.width = copy.height;
                        copy.height = w;
                        //console.log("rotated!!!");
                    }

                    let left = [ ...copy.connectors.left ];
                    let right = [ ...copy.connectors.right ];
                    let top = [ ...copy.connectors.top ];
                    let bottom = [ ...copy.connectors.bottom ];

                    switch(this.compValues.position.rotate){
                        case 1:
                            copy.connectors.top = left;
                            copy.connectors.right = top;
                            copy.connectors.bottom = right;
                            copy.connectors.left = bottom;
                            break;
                        case 2:
                            copy.connectors.top = bottom;
                            copy.connectors.right = left;
                            copy.connectors.bottom = top;
                            copy.connectors.left = right;
                            break;
                        case 3:
                            copy.connectors.top = right;
                            copy.connectors.right = bottom;
                            copy.connectors.bottom = left;
                            copy.connectors.left = top;
                            break;
                    }
                }

                return copy;
                //return this.allComponentTypes[this.compValues.name]
            },  
            origCornerPosition: function(){
                let x = - parseInt(this.item.origWidth * this.sizeMultiplicator)/2;
                let y = - parseInt(this.item.origHeight * this.sizeMultiplicator)/2;
                return { top: y, left: x};
            },
            translateTop: function(){
                return {
                    x: 0,
                    y: -parseInt(this.item.height * this.sizeMultiplicator)/2 - this.connectorOffset,
                }
            },
            translateRight: function(){
                return {
                    x: parseInt(this.item.width * this.sizeMultiplicator)/2 + this.connectorOffset,
                    y: 0,
                }
            },
            translateBottom: function(){
                return {
                    x: 0,
                    y: parseInt(this.item.height * this.sizeMultiplicator)/2 + this.connectorOffset,
                }
            },
            translateLeft: function(){
                return {
                    x: -parseInt(this.item.width * this.sizeMultiplicator)/2 - this.connectorOffset,
                    y: 0,
                }
            },
            transform: function(){
                // console.log("this.translateGroup");
                // console.log(this.translateGroup);
                return `translate( ${this.compValues.position.left},  ${this.compValues.position.top} )`;
            },
            scaleRotate: function(){
                let value = "";
                if(this.compValues.position.rotate){
                //[{"scale(-1, 1)": compValues.mirror}]
                value += "rotate(" + (90 * this.compValues.position.rotate) + ")";
                }
                if(this.compValues.position.mirror){
                //[{"scale(-1, 1)": compValues.mirror}]
                value += " scale(-1, 1)";
                }
                
                return value;
            }
    },
    methods:{
            ...mapActions(['updateComponentPosition', 'addNewConnection', 'setConnectionPoint', 'updateConnectionId', 'addComponentToModule', 'removeFromModule', 'updateComponentAttributes']),
            getScalefactor: function(){
                return globalStore.zoomfactor;
            }, 
            cornerPosition: function(){
                let x = - parseInt(this.item.width * this.sizeMultiplicator)/2;
                let y = - parseInt(this.item.height * this.sizeMultiplicator)/2;
                return { top: y, left: x};
            },
            xTextField: function(){
                if(this.item.type == "textfield"){
                    return this.cornerPosition().left + 8;
                } else {
                    return this.cornerPosition().left;
                }
            },
            textFontSize: function(){
                if(this.item.type == "textfield"){
                    return 12;
                } else {
                    return 8;
                }
            },
            yTextField: function(){
                if(this.item.type == "textfield"){
                    return this.cornerPosition().top;
                } else {
                    return -this.cornerPosition().top;
                }
            },
            getFormattedText: function(){
                let lines = [];
                
                lines = this.compValues.text.split('\n');

                const lineLength = 43;
                // check if an lines is more than 36 lettes
                for(let i = 0; i<lines.length; i++){
                    if(lines[i].length > lineLength){
                        const tmpLine = lines[i];
                        // suche nach leerzeichen:
                        let cut = lines[i].substring(0, lineLength+3).lastIndexOf(' ');

                        if(cut > 0){
                            lines[i] = lines[i].substring(0, cut);
                            lines.splice(i+1, 0, tmpLine.substring(cut+1));
                        } else {
                            lines[i] = lines[i].substring(0, 36) + "-";
                            lines.splice(i+1, 0, tmpLine.substring(36));
                        }
                    }
                }

                // hoehe nur bei textfield anpassen
                if(this.compValues.name == "textfield"){
                    /*console.log("compValues:");
                    console.log(this.compValues);
                    console.log("item:");
                    console.log(this.item);*/

                    let _height = this.item.height;
                    this.item.height = Math.ceil(lines.length/1.8);
                    
                    // um differenz nach oben verschieben:
                    let yDiff = _height - this.item.height;

                    // neue eigenschaft height für compValue -> ausnahme für Textfield
                    this.compValues.position.height = this.item.height;
                    this.compValues.position.top -= (yDiff/2) * this.sizeMultiplicator;
                }

                return lines; 
            },
            setHover(val){
                if(globalStore.fillMode){
                    this.fillMode = val;
                    this.hover = false;
                } else {
                    this.hover = val;
                    if(val == false){
                        this.showAddTo = val;
                    } else if(globalStore.editModuleID >= 0){
                        this.showAddTo = val;
                        // check if part of module & set addTo value:
                        this.addTo = ! this.isIdInModule({
                            moduleId: globalStore.editModuleID,
                            id: this.compValues.id,
                        });
                    }
                }
            },
            startDragElement(e){	
                if(globalStore.fillMode == false){					
                    this.pos3 = e.clientX;
                    this.pos4 = e.clientY;
                    
                    if(e.touches){
                        //console.log(e.touches)
                        var touch = e.touches[0];
                        this.touchElement =  document.elementFromPoint(touch.pageX,touch.pageY);
                        //console.log(this.touchElement);
                        document.ontouchmove = this.dragElementTouch;

                        // todo: safari kann kein ontouchend!
                        document.ontouchend = this.closeDragElement;
                    } else {
                        document.onmouseup = this.closeDragElement;
                        document.onmousemove = this.dragElement;
                    }
                }
            },
            dragElementTouch(e){
                e = e || window.event;
                //e.preventDefault();
                // calculate the new cursor position:
                this.pos1 = this.pos3 - e.touches[0].clientX;
                this.pos2 = this.pos4 - e.touches[0].clientY;
                this.pos3 = e.touches[0].clientX;
                this.pos4 = e.touches[0].clientY;

                // set Temp Position:
                this.moveX -= this.pos1;
                this.moveY -= this.pos2;
                this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX / this.getScalefactor()},  ${this.moveY / this.getScalefactor()} )`);
            },
            dragElement(e){
                //this.position.top = e.
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                this.pos1 = this.pos3 - e.clientX;
                this.pos2 = this.pos4 - e.clientY;
                this.pos3 = e.clientX;
                this.pos4 = e.clientY;
                // set Temp Position:
                //console.log("dragElement");

                // temp transform:
                this.moveX -= this.pos1;
                this.moveY -= this.pos2;
                this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX / this.getScalefactor()},  ${this.moveY / this.getScalefactor()} )`);

                if(e.touches){
                    var touch = e.touches[0];
                    if (this.touchElement !== document.elementFromPoint(touch.pageX,touch.pageY)) {
                        this.closeDragElement();
                    }
                }
            },


            closeDragElement(){
                //console.log();
                //console.log("called closeDrag Element");
                document.ontouchend = null;
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchmove = null;

                this.touchElement = null;

                let pos = { top: posHelper.roundToRaster(parseInt(this.compValues.position.top) + (this.moveY/ this.getScalefactor())), left: posHelper.roundToRaster(parseInt(this.compValues.position.left) + (this.moveX / this.getScalefactor())) };
                // console.log("new position");
                // console.log(pos);

                this.$el.setAttribute('transform', `translate( ${pos.left},  ${pos.top} )`);

                // reset temp position
                this.moveX = 0;
                this.moveY = 0;
                this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX},  ${this.moveY} )`);
                
                this.updateComponentPosition({ id: this.compValues.id, position: pos});                
                //this.$emit('dragged-element', { this.$el.getElementsByTagName('rect')[0].style.top, this.$el.getElementsByTagName('rect')[0].style.left });
            },
            getTranslate(s){
                var reg = /-?\d+/g
                return s.match(reg);
            },
            checkCreateConnection(){
                //console.log("checkCreateConnection");

                var conP = this.getConnectionPoint;

                if(conP.out && conP.out.id != this.compValues.id){
                    //console.log(`input, ${conP.type}`);
                    //console.log(this.$el);
                    //console.log(this.$el.querySelectorAll(`.input.${conP.type}`));
                    //console.log(this.$el.querySelectorAll(`.input.${conP.type}`).length );
                    // check if item has corresponding input:
                    if(this.$el.querySelectorAll(`.input.${conP.type}`).length > 0){
                        //console.log("start + end - create new connection");
                        let conID = this.getConnectionId + 1;
                        //console.log("new ConnectionId: " + conID);
                        // create new connection:
                        let data = {
                            id: conID,
                            type: conP.type,
                            assignment: globalStore.assignment,
                            out: {
                                id: conP.out.id,
                            },
                            in: {
                                id: this.compValues.id,
                            }
                        }

                        if (conP.out.no) {
                            data.out.no = conP.out.no;
                        }

                        console.log("connection data;");
                        console.log(data);

                        //console.log(data);

                        this.addNewConnection(data);

                        this.updateConnectionId(conID);

                        // reset Connection Point:
                        this.setConnectionPoint({});
                        EventBus.$emit('reset-connectors', -1);
                    }
                } else if(conP.in && conP.in.id != this.compValues.id) {
                    // console.log(`output, ${conP.type}`);
                    // console.log(this.$el);
                    // console.log(this.$el.querySelectorAll(`.output.${conP.type}`));
                    // console.log(this.$el.querySelectorAll(`.output.${conP.type}`).length );
                    if(this.$el.querySelectorAll(`.output.${conP.type}`).length > 0){
                        //console.log("start + end - create new connection");
                        let conID = this.getConnectionId + 1;
                        //console.log("new ConnectionId: " + conID);
                        // create new connection:
                        let data = {
                            id: conID,
                            type: conP.type,
                            assignment: globalStore.assignment,
                            in: {
                                id: conP.in.id,
                            },
                            out: {
                                id: this.compValues.id,
                            }
                        }

                        console.log("connection data;");
                        console.log(data);

                        if (conP.in.no) {
                            data.in.no = conP.in.no;
                        }
                        this.addNewConnection(data);

                        this.updateConnectionId(conID);

                        // reset Connection Point:
                        this.setConnectionPoint({});
                        EventBus.$emit('reset-connectors', -1);
                    }   
                }
            },
            createConnection(data){
                //console.log("createConnection: ");
                let conID = this.getConnectionId + 1;
                data.id = conID;
                data.assignment = globalStore.assignment,
                //console.log(data);
                this.addNewConnection(data);

                this.updateConnectionId(conID);

                // reset Connection Point:
                this.setConnectionPoint({});
                EventBus.$emit('reset-connectors', -1);
            },
            openTextInput(){
                // create HTML Window
                var app = document.getElementById("svg_container").parentNode;

                var TextInputWindowClass = Vue.extend(TextInputWindow);

                    //console.log(options);
                    //console.log(versions);
                    //return "test";

                let win = new TextInputWindowClass({
                        propsData: {
                            componentName: this.compValues.name,
                            componentId: this.compValues.id,
                            lastText: this.compValues.text,
                            //componentVersion: this.compValues.version,
                            //allComponentTypes: this.allComponentTypes,
                            //options: options,
                            //versions: versions,
                        }
                });

                win.$mount();

                console.log(win.$el);
                app.appendChild(win.$el);
            },
            showSettings(){

                // create HTML Window
                var app = document.getElementById("svg_container").parentNode;
                var SettingsWindowClass = Vue.extend(SettingsWindow);
                let win = new SettingsWindowClass({
                        propsData: {
                            componentName: this.compValues.name,
                            componentId: this.compValues.id,
                            componentVersion: this.compValues.version,
                            allComponentTypes: this.allComponentTypes,
                            //options: options,
                            //versions: versions,
                        }
                });

                win.$mount();

                console.log(win.$el);
                app.appendChild(win.$el);
            },
            showConfigure(){
                if(globalStore.fillMode == false){
                    if(this.configure == "hidden"){
                        this.configure = 'visible';
                        //this.connectorsVisible = 'hidden';
                        EventBus.$emit('reset-configure', this.compValues.id);
                    } else {
                        this.configure = 'hidden';
                        //this.connectorsVisible = 'visible';
                    }
                } else {
                    this.configure = 'hidden';

                    // set assignment:
                    let data = {
                        id: this.compValues.id,
                        assignment: globalStore.assignment,
                    }
                    this.updateComponentAttributes(data);
                }
            },
            addToModule(){
                if(globalStore.editModuleID >= 0){
                    let data = {
                        moduleId: globalStore.editModuleID,
                        id: this.compValues.id,
                    };

                    if(this.addTo){
                        this.addComponentToModule(data);
                    } else {
                        this.removeFromModule(data);
                    }
                } else {
                        console.log("ERROR: no editModuleID set!!!!!!!!!");
                }
            }
    },
    created(){
		EventBus.$on('reset-configure', (id) => {
            if(this.compValues.id != id){
                this.configure = 'hidden';
                //this.connectorsVisible = 'visible';
            }
		});
    },
    /*watch(){

    },*/
    
}
</script>

<style scoped>
    g {
        stroke-width:1;
        stroke:rgb(0,0,0);
        

        /* Firefox */
        -moz-transition: fill 0.3s ease-in;
        /* WebKit */
        -webkit-transition: fill 0.3s ease-in;
        /* Opera */
        -o-transition: fill 0.3s ease-in;
        /* Standard */
        transition: fill 0.3s ease-in;
    }
    rect {
        /*fill: #fff3;*/
        fill: rgba(255, 255, 255, 0.01);
    }
    .hoverClass {
        fill: rgba(220, 220, 220, 0.333);;
        cursor: move;
    }
    .fillClass {
        cursor: copy !important;
    }

    .existing {
        stroke:rgb(173, 179, 184);
    }
    .coperion{

    }
    .other{
        stroke:rgb(2, 148, 61);
    }
    .future{
        stroke:rgb(211, 81, 0);
    }
</style>