<template>
  <div>
    <div
      v-bind:class="{ hide: !initialized && !enabledebugmode }"
      id="three-scene"
    >
      <canvas id="canvas" ref="page"></canvas>
    </div>
    <div
      v-bind:class="{ hide: initialized }"
      style="text-align: center; padding-top: 10%"
    >
      <h1>
        Lade 3d-Modelle ({{ totalmodels - modelstoload }} von
        {{ totalmodels }} )
      </h1>
      <img src="valve_loading.gif" />
    </div>
  </div>
</template>

<script>
import * as THREE from "three";

global.THREE = THREE;
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";

import { mapGetters, mapState } from "vuex";
import cloneDeep from "lodash.clonedeep";
import { Vector3 } from 'three';

export default {
  name: "three-scene",
  computed: {
    ...mapGetters([
      "allComponentTypes",
      "allModels",
      "getPlantComponents",
      "getPlantConnections",
    ]),
    ...mapState(["plant"]),

    initialized() {
      // return false;
      return this.modelstoload <= 0;
    },
  },
  data() {
    return {
      page: null,
      scene: null,
      camera: null,
      renderer: null,
      models: {},
      pComponents: [],
      components: [
        {
          row: Number,
          column: Number,
          position: null,
          size: null,
          mesh: null,
        },
      ],
      aufbaustraenge: [],
      //sortTree: [],

      pipes: [],
      modelstoload: 0, // um zu prüfen wie viele models es sind und ob alle geladen wurden (siehe load models)
      totalmodels: 0,
      enabledebugmode: false,
      debugMeshes: [],
      plantBounds: null,
      scenescale: 2.5,
      modelscale: 0.035,
      dummyModel: {x: 30, y: 20, z: 30},
      zIndent: 300,
      positioningscale: 2.5, // Um Positionen der Komponenten zusammenzurücken (debug)
      gridSpacing: 12,

      zHierarchy: 0, // index der angibt, auf welchem level in z sich die componenten gerade befinden
      pOffset: 0, //x-verschiebung der letzten komponente (um von position der komponente um z-axis verschieben zu können) 
      // problem: -> nicht nur die letzte componente ist relevant, sondern der gesamte offset vom stapel!
      // dafür neue variable:
      groundLayerOffset: 0,
      zPipes: [], // speichert die Positionen von Rohren für z-Achse-Komponenten
      spacer: {x: 0.5, y: 1.2, z: 0},

      columnXOffset: 40,
      columnYOffset: 25,
      columnZOffset: 140,

      materials: [{ metal: null, basic: null, existing: null, coperion: null, other: null, future: null, existingTransparent: null, coperionTransparent: null, otherTransparent: null, futureTransparent: null, existingWire: null, coperionWire: null, otherWire: null, futureWire: null, existingPipe: null, coperionPipe: null, otherPipe: null, futurePipe: null  }],
    };
  },
  props: {
    // sceneWidth: {
    //   type: Number,
    //   required: true,
    // },
    // sceneHeight: {
    //   type: Number,
    //   required: true,
    // },
  },
  mounted() {
    console.log("three scene mounted");
    this.init();

    this.GameLoop();
  },
  methods: {
    init: function() {

      this.scene = new THREE.Scene();
      //this.scene.background = new THREE.Color(clearColor);
      this.scene.background = new THREE.Color("white");

      // camera
      //this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 1, 2000);
      this.camera = new THREE.PerspectiveCamera(
        40,
        window.innerWidth / (window.innerHeight - 100),
        10,
        3000
      );

      // renderer

      this.page = this.$refs.page;

      this.renderer = new THREE.WebGLRenderer({
        //antialias: true,
        canvas: this.page,
        //alpha: true
      });
      //this.renderer.setClearColor(clearColor, 1);
      //this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setSize(window.innerWidth, window.innerHeight - 100);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.res;

      document
        .getElementById("three-scene")
        .appendChild(this.renderer.domElement);

      this.camera.position.x = -60;
      this.camera.position.y = 80;
      this.camera.position.z = 200;
      this.orbitControls = new OrbitControls(this.camera, this.page);
      this.orbitControls.target = new THREE.Vector3(60, 20, 0);
      //this.orbitControls.maxPolarAngle = Math.PI / 2;
      this.orbitControls.enablePan = true;
      this.orbitControls.enableZoom = true;
      this.orbitControls.enableRotate = true;
      this.orbitControls.update();

      // MATERIALS

      // REFLECTION ENVIRONMENT
      var loader = new THREE.CubeTextureLoader().setPath("textures/cube/");

      var textureCube = loader.load([
        "01.jpg",
        "01.jpg",
        "01.jpg",
        "01.jpg",
        "01.jpg",
        "01.jpg",
      ]);

      var normScale = new THREE.Vector2(0.025, 0.025);

      this.materials.basic = new THREE.MeshStandardMaterial({
        color: 0xffffff,
      });

      this.materials.metal = new THREE.MeshStandardMaterial({
        color: 0xc7c7c7,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.metal.normalScale = normScale;
      this.materials.metal.envMap = textureCube;

      // Component Material
      this.materials.existing = new THREE.MeshStandardMaterial({
        color: 0xadb3b8,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.existing.normalScale = normScale;
      this.materials.existing.envMap = textureCube;

      this.materials.coperion = new THREE.MeshStandardMaterial({
        //color: 0xc7c7c7,
        color: 0x7ab4e4,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.coperion.normalScale = normScale;
      this.materials.coperion.envMap = textureCube;

      this.materials.other = new THREE.MeshStandardMaterial({
        color: 0x02943d,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.other.normalScale = normScale;
      this.materials.other.envMap = textureCube;

      this.materials.future = new THREE.MeshStandardMaterial({
        color: 0xd35100,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.future.normalScale = normScale;
      this.materials.future.envMap = textureCube;

      // TransparentBox Material
      // Component Material
      this.materials.existingTransparent = new THREE.MeshPhongMaterial({
        transparent: true,
        opacity: 0.1,
        // color: 0xadb3b8,
        color: 0xffffff,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.existing.normalScale = normScale;
      this.materials.existing.envMap = textureCube;

      this.materials.coperionTransparent = new THREE.MeshPhongMaterial({
        transparent: true,
        opacity: 0.1,
        //color: 0xc7c7c7,
        color: 0x7ab4e4,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.coperion.normalScale = normScale;
      this.materials.coperion.envMap = textureCube;

      this.materials.otherTransparent = new THREE.MeshPhongMaterial({
        transparent: true,
        opacity: 0.1,
        //color: 0x02943d,
        color: 0x00ff00,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.other.normalScale = normScale;
      this.materials.other.envMap = textureCube;

      this.materials.futureTransparent = new THREE.MeshPhongMaterial({
        transparent: true,
        opacity: 0.1,
        //color: 0xd35100,
        color: 0xff0000,
        roughness: 0.36,
        metalness: 0.75,
      });
      this.materials.future.normalScale = normScale;
      this.materials.future.envMap = textureCube;

      // Piping Material
      this.materials.existingPipe = new THREE.MeshStandardMaterial({
        color: 0xadb3b8,
        emissive: 0x727272,
        roughness: 0.99,
        metalness: 0.0,
      });
      this.materials.existingPipe.normalScale = normScale;
      this.materials.existingPipe.envMap = textureCube;

      this.materials.coperionPipe = new THREE.MeshStandardMaterial({
        //color: 0xc7c7c7,
        color: 0x7ab4e4,
        //emissive: 0x727272,
        roughness: 0.99,
        metalness: 0.0,
      });
      this.materials.coperionPipe.normalScale = normScale;
      this.materials.coperionPipe.envMap = textureCube;

      this.materials.otherPipe = new THREE.MeshStandardMaterial({
        color: 0x02943d,
        emissive: 0x727272,
        roughness: 0.99,
        metalness: 0.0,
      });
      this.materials.otherPipe.normalScale = normScale;
      this.materials.otherPipe.envMap = textureCube;

      this.materials.futurePipe = new THREE.MeshStandardMaterial({
        color: 0xd35100,
        emissive: 0x727272,
        roughness: 0.99,
        metalness: 0.0,
      });
      this.materials.futurePipe.normalScale = normScale;
      this.materials.futurePipe.envMap = textureCube;

      // Wire Material
      this.materials.existingWire = new THREE.LineBasicMaterial({
        color: 0xadb3b8,
        linewidth: 1,
        linecap: 'round', //ignored by WebGLRenderer
        linejoin:  'round'
      });
      this.materials.existingWire.wireframe = true;

      this.materials.coperionWire = new THREE.LineBasicMaterial({
        color: 0x7ab4e4,
        linewidth: 1,
        linecap: 'round', //ignored by WebGLRenderer
        linejoin:  'round'
      });
      this.materials.coperionWire.wireframe = true;

      this.materials.otherWire = new THREE.LineBasicMaterial({
        color: 0x02943d,
        linewidth: 1,
        linecap: 'round', //ignored by WebGLRenderer
        linejoin:  'round'
      });
      this.materials.otherWire.wireframe = true;

      this.materials.futureWire = new THREE.LineBasicMaterial({
        color: 0xd35100,
        linewidth: 1,
        linecap: 'round', //ignored by WebGLRenderer
        linejoin:  'round'
      });
      this.materials.futureWire.wireframe = true;
      

      // // LIGHTS

      var ambientLight = new THREE.HemisphereLight(0x0078d5, 0x884f1c, 0.8);
      ambientLight.position.set(0, 40, 0);
      this.scene.add(ambientLight);

      var dirLight = new THREE.DirectionalLight(0xffffff, 0.6);
      dirLight.position.set(-40, 100, 40);
      dirLight.castShadow = true;
      dirLight.shadow.camera.top = 200;
      dirLight.shadow.camera.bottom = -200;
      dirLight.shadow.camera.left = -200;
      dirLight.shadow.camera.right = 200;
      dirLight.shadow.camera.near = 0.5; // default
      dirLight.shadow.camera.far = 500; // default
      dirLight.shadow.mapSize.width = 2048; // default
      dirLight.shadow.mapSize.height = 2048; // default
      this.scene.add(dirLight);

      var dirLight2 = new THREE.DirectionalLight(0xfff1ba, 0.2);
      dirLight2.position.set(-0, 100, 0);
      dirLight2.castShadow = true;
      dirLight2.shadow.camera.top = 200;
      dirLight2.shadow.camera.bottom = -200;
      dirLight2.shadow.camera.left = -600;
      dirLight2.shadow.camera.right = 600;
      dirLight2.shadow.camera.near = 0.5; // default
      dirLight2.shadow.camera.far = 500; // default
      dirLight2.shadow.mapSize.width = 2048; // default
      dirLight2.shadow.mapSize.height = 2048; // default
      this.scene.add(dirLight2);

      var pLight = new THREE.PointLight(0xeefbff, 0.6, 800);
      pLight.position.set(-20, 50, -20);
      this.scene.add(pLight);

      if (!this.enabledebugmode) {
        this.scene.fog = new THREE.Fog(0xffffff, 1200, 2400);
      }
      // GROUND
      if (!this.enabledebugmode) {
        let material = new THREE.MeshPhongMaterial({
          color: 0xffffff,
        });

        var floor = new THREE.Mesh(
          new THREE.PlaneBufferGeometry(5000, 5000),
          new THREE.MeshPhongMaterial({
            color: 0xb4b4b4,
          })
        );
        floor.name = "floor";
        floor.position.set(0, 0, 0.0);
        floor.rotation.x = -Math.PI / 2;
        floor.receiveShadow = true;
        floor.material = material;
        this.scene.add(floor);

        let loader = new OBJLoader();
        loader.load("models/landscape.obj", (landscape) => {
          landscape.scale.set(4, 4, 4);
          landscape.position.set(0, -5, 0);

          landscape.material = material;
          landscape.receiveShadow = false;
          landscape.castShadow = false;

          landscape.traverse(function(child) {
            if (child instanceof THREE.Mesh) {
              child.castShadow = false;
              child.receiveShadow = false;
            }
          });
          this.scene.add(landscape);
        });
      }

      this.loadModels();
    },

    getModelSize(component, strangComp){
      let compType = this.allComponentTypes[component.name];

      console.log("compType: " + component.name + " " + strangComp.axis );
      //console.log(compType);
      if(compType.model){
        let version = 0;
        if(component.version){
          for(let i = 0; i < compType.versions.length; i++){
            //console.log(component.version + " == " + compType.versions[i] + "?");
            if(component.version == compType.versions[i]){
              version = i;
            }
          }
        }
        // falls kein extra modell für version vorhanden ist:
        if(version >= this.models[compType.model.link].length){
          version = 0;
        }

        //console.log(version);
        //console.log(this.models[compType.model.link]);
        let bbox = cloneDeep(this.models[compType.model.link][version].bbox);
        let size = bbox.max.sub(bbox.min);


        // verdrehnung z-axis
        if(strangComp.axis && strangComp.axis == "z"){
          let _x = size.x;
          size.x = size.z;
          size.z = _x;
        }


      // TODO rotations checken!!!!!!

        // rotation 
        if(component.position.rotate && component.position.rotate % 2 != 0){

          if(strangComp.axis && strangComp.axis == "z"){
            let _z = size.z;
            size.z = size.y;
            size.y = _z;
            
          } else {
            let _x = size.x;
            size.x = size.y;
            size.y = _x;
            
          }
        }

        

        

        //console.log(component.name + " size");
        //console.log(size);
        return size;
      } else {
        
        let size = new Vector3(this.dummyModel.x, this.dummyModel.y, this.dummyModel.z);
        
        
        // verdrehnung axis
        if(strangComp.axis && strangComp.axis == "z"){
          let _x = size.x;
          size.x = size.z;
          size.z = _x;
        }
        // rotation 
        if(component.position.rotate && component.position.rotate % 2 != 0){
          if(strangComp.axis && strangComp.axis == "z"){
            let _z = size.z;
            size.z = size.y;
            size.y = _z;
            
          } else {
            let _x = size.x;
            size.x = size.y;
            size.y = _x;
            
          }
        }

        return size;
      }
    },

    getWidthOfAllChildren(children){
      let width = 0;
      for (let i = 0; i < children.length; i++) {
            // breite dieses elements
            let component = this.getPlantComponents[this.getPlantComponents.findIndex((comp) => comp.id == children[i].id)];
            let size = this.getModelSize(component, children[i]);
            //console.log(size);
            //let myW = size.z;
            
            let childW = this.getWidthOfAllChildren(children[i].children);
            
            if(children[i].children.length > 0){
              console.log("childW, size.z: " + component.name + ": " + childW + " - " + size.z);
            }
            // füge die Breite zu width hinzu
            let maxW = Math.max(childW, size.z);

            // set width of component
            children[i].width = maxW;

            width += maxW;
      }
      //let offset = 10;
      //width += (children.length -1) * offset;

      return width;
    },

    calculateStrangPositions(){
      if (this.getPlantComponents) {

        let gesamtWidth = 0;

        if (this.aufbauStraenge.length > 0) {

          for (let i = 0; i < this.aufbauStraenge.length; i++) {
            // breite dieses elements
            let component = this.getPlantComponents[this.getPlantComponents.findIndex((comp) => comp.id == this.aufbauStraenge[i].id)];
            let size = this.getModelSize(component, this.aufbauStraenge[i]);
            //let myW = size.z;
            console.log("size " + component.name);
            console.log(size);


            //let childrenWidth = this.getWidthOfAllDirectChildren(this.aufbauStraenge[i].children);
            let childrenWidth = this.getWidthOfAllChildren(this.aufbauStraenge[i].children);

            let strangWidth = Math.max(size.z, childrenWidth);
            console.log("Strang " + i + " width= " + strangWidth);
            
            this.aufbauStraenge[i].width = strangWidth;
            //console.log(component);
            if(this.aufbauStraenge[i].axis && this.aufbauStraenge[i].axis == 'z'){
              // zähle nicht zu gesamtwidth
            }else {
              // hier auch noch strangWidth verdoppeln?
              gesamtWidth += strangWidth; // *2?
            }
          }
        }
        console.log("gesamtWidth: " + gesamtWidth);
      }
      console.log("aufbauStraenge mit breite:");
      console.log(JSON.stringify(this.aufbauStraenge));
    },

    createStrangAufbau(){
      // sortiert die Reihenfolge und den Aufbau der Anlage Anhand der Connections
        let aufbaustraenge = [];

        //let positionRaster = []; // x Ebene
        // sortiere Componenten nach Connection und Position:

        // nur bulk pairs:
        let topDownPairs = [];
        let downTopPairs = [];

        let treatedIds = [];

        // in-objekt ist nicht ground
        let airStrangPairs = [];

        // in-Objekt ist ground
        let airNewStrangPairs = [];

        if (this.getPlantConnections) {
          this.getPlantConnections.forEach((con) => {
            let outComponent = this.getPlantComponents[
              this.getPlantComponents.findIndex((comp) => comp.id == con.out.id)
            ];

            let inComponent = this.getPlantComponents[
              this.getPlantComponents.findIndex((comp) => comp.id == con.in.id)
            ];

            let inPos = inComponent.position;

            if (con.type == "bulk") {
              let outPos = outComponent.position;

              // !Ausnahme ground Komponenten!
              let ground = false;
              // if outComponent ist GROUND - neuen "Stapel" anfangen
              if (
                this.allComponentTypes[outComponent.name].model &&
                this.allComponentTypes[outComponent.name].model.ground
              ) {
                ground = true;
              }

              // console.log("COMPARISON:");

              // oben nach unten: -> gleiche Spalte  // !Ausnahme ground Komponenten!
              if (outPos.top < inPos.top && ground == false) {
                let newGroup = [con.out.id, con.in.id];
                topDownPairs.push(newGroup);
                //console.log("topDownPair");
                // console.log(con.out.id + " ist über " + con.in.id );
              } else {
                // neue Spalte
                let newGroup = [con.out.id, con.in.id];
                downTopPairs.push(newGroup);
                //console.log("downTopPair");
              }

              // bulk connections -> add IDs to treatedIds
              if (treatedIds.indexOf(con.out.id) === -1) {
                treatedIds.push(con.out.id);
              }
              if (treatedIds.indexOf(con.in.id) === -1) {
                treatedIds.push(con.in.id);
              }
            } else {
              // con.type == "air"
              let newGroup = [con.out.id, con.in.id];

              // !Ausnahme ground Komponenten!
              let ground = false;
              // if "IN"-Component ist GROUND - neuen "Stapel" anfangen
              if (
                this.allComponentTypes[inComponent.name].model &&
                this.allComponentTypes[inComponent.name].model.ground
              ) {
                ground = true;
              }

              if (ground == false) {
                airStrangPairs.push(newGroup);
              } else {
                //console.log("airNewStrangPair: ");
                //console.log(newGroup);
                airNewStrangPairs.push(newGroup);
              }
            }
          });
          //console.log("topDownPairs");
          //console.log(topDownPairs);
          // console.log("downTopPairs");
          // console.log(downTopPairs);

          // console.log("airStrangPairs");
          // console.log(airStrangPairs);

          // console.log("airNewStrangPairs");
          // console.log(airNewStrangPairs);


          let sortTree = [];
          let change = false;

          // erstelle bulk sortTree
          do {
            // wiederhole so lange bis change am ende immmer noch false ist:
            change = false;
            for (let i = 0; i < topDownPairs.length; i++) {
              // check if out is in sortTree (which layer?)
              let outLayer = -1;
              let inLayer = -1;
              //let outIndex = -1;
              let inIndex = -1;

              // find current outLayer, inLayer & inIndex
              for (let layer = 0; layer < sortTree.length; layer++) {
                for (let index = 0; index < sortTree[layer].length; index++) {
                  if (sortTree[layer][index] == topDownPairs[i][0]) {
                    outLayer = layer;
                    //outIndex = i;
                  }

                  if (sortTree[layer][index] == topDownPairs[i][1]) {
                    inLayer = layer;
                    inIndex = index;
                  }
                }
              }

              // wenn out layer noch nicht definiert ist:
              if (outLayer < 0) {
                outLayer = 0;

                // wenn Layer noch nicht vorhanden ist anlegen:
                if (!sortTree[outLayer]) {
                  sortTree[outLayer] = [];
                }

                // lege outId in Layer ab
                sortTree[outLayer].push(topDownPairs[i][0]);
                change = true;
              }

              if (inLayer <= outLayer) {
                // in nächsten layer verschieben:
                if (inLayer >= 0) {
                  // aus dem Layer löschen
                  // console.log("delete: " + this.sortTree[inLayer][inIndex] + " from layer: " + inLayer + " from position: " + inIndex);
                  sortTree[inLayer].splice(inIndex, 1);
                }

                // id in layer unterhalb outlayer verschieben
                inLayer = outLayer + 1;

                if (!sortTree[inLayer]) {
                  sortTree[inLayer] = [];
                }

                // in in layer ablegen
                sortTree[inLayer].push(topDownPairs[i][1]);
                change = true;
              }
            }
          } while (change);

          console.log("sortTree: ");
          console.log(sortTree);

          // aus sortTree aufbaustraenge erstellen

          // starte mit unterster sortTree Ebene:
          for (let i = sortTree.length - 1; i >= 0; i--) {
            // jedes Element aus der Ebene:
            for (let j = 0; j < sortTree[i].length; j++) {
              let startId = sortTree[i][j];
              //console.log(i + " " + j + " " + startId);
              this.findAndAddChildren(
                aufbaustraenge,
                aufbaustraenge,
                startId,
                topDownPairs
              );
            }
          }

          // downTopPairs - componenten die noch nicht enthalten sind als neuen Strang anfuegen:

          for (let i = 0; i < downTopPairs.length; i++) {
            if (
              this.isInAufbauStrang(aufbaustraenge, downTopPairs[i][0]) == false
            ) {
              let strang = {
                id: downTopPairs[i][0],
                name: this.getComponentName(downTopPairs[i][0]),
                children: [],
              };
              aufbaustraenge.push(strang);
            }

            if (
              this.isInAufbauStrang(aufbaustraenge, downTopPairs[i][1]) == false
            ) {
              let strang = {
                id: downTopPairs[i][1],
                name: this.getComponentName(downTopPairs[i][1]),
                children: [],
              };
              aufbaustraenge.push(strang);
            }
          }
          // hier noch unsortiert:
          //console.log(JSON.stringify(aufbaustraenge));

          // Suche Komponenten ohne Connection und hänge sie an:
          this.getPlantComponents.forEach((comp) => {
            // noch nicht verwendent:
            //console.log(comp);
            //console.log(treatedIds.indexOf(comp.id));

            // ignoriere hideIn3d = false compoennten (zb. textfields)
            if(!this.allComponentTypes[this.getComponentName(comp.id)].hideIn3d){
              if (treatedIds.indexOf(comp.id) <= 0) {
                let hasConnection = false;
                for (let c = 0; c < this.getPlantConnections.length; c++) {
                  let con = this.getPlantConnections[c];
                  if (con.in.id == comp.id || con.out.id == comp.id) {
                    // break:
                    hasConnection = true;
                    c = this.getPlantConnections.length;
                  }
                }

                if (hasConnection == false) {
                  let strang = {
                    id: comp.id,
                    name: this.getComponentName(comp.id),
                    children: [],
                  };
                  aufbaustraenge.push(strang);
                }
              }
            }
          });

          // Sortiere die Reihenfolge der aufbaustraenge von links nach rechts abhaengig von der Zeichnung:
          aufbaustraenge.sort((a, b) => {
            let componentA = this.getPlantComponents[
              this.getPlantComponents.findIndex((comp) => comp.id == a.id)
            ];
            let componentB = this.getPlantComponents[
              this.getPlantComponents.findIndex((comp) => comp.id == b.id)
            ];

            let xVonA = componentA.position.left;
            let xVonB = componentB.position.left;
            return xVonA - xVonB;
          });

          //console.log("aufbaustraenge vor air");
          // console.log([aufbaustraenge]);
          //console.log(JSON.stringify(aufbaustraenge));


          // filtere Pairs bei denen beide Elemente schon in treatedIds sind aus:
          airStrangPairs = airStrangPairs.filter(function(value) {
            if (
              treatedIds.indexOf(value[0]) >= 0 &&
              treatedIds.indexOf(value[1]) >= 0
            ) {
              return false;
            } else {
              return true;
            }
          });

          let airSortTree = [];
          do {
            // wiederhole so lange bis change am ende immmer noch false ist:
            change = false;
            for (let i = 0; i < airStrangPairs.length; i++) {
              // check if out is in sortTree (which layer?)
              let outLayer = -1;
              let inLayer = -1;
              //let outIndex = -1;
              let inIndex = -1;

              // airverbindugen mit ende an treated id aussortieren:
              //if(treatedIds.indexOf(airStrangPairs[i][1]) < 0){

              // find current outLayer, inLayer & inIndex
              for (let layer = 0; layer < airSortTree.length; layer++) {
                for (
                  let index = 0;
                  index < airSortTree[layer].length;
                  index++
                ) {
                  if (airSortTree[layer][index] == airStrangPairs[i][0]) {
                    outLayer = layer;
                    //outIndex = i;
                  }

                  if (airSortTree[layer][index] == airStrangPairs[i][1]) {
                    inLayer = layer;
                    inIndex = index;
                  }
                }
              }

              // wenn out layer noch nicht definiert ist:
              if (outLayer < 0) {
                outLayer = 0;

                // wenn Layer noch nicht vorhanden ist anlegen:
                if (!airSortTree[outLayer]) {
                  airSortTree[outLayer] = [];
                }

                // lege outId in Layer ab
                airSortTree[outLayer].push(airStrangPairs[i][0]);
                change = true;
              }

              if (inLayer <= outLayer) {
                // in nächsten layer verschieben:
                if (inLayer >= 0) {
                  // aus dem Layer löschen
                  // console.log("delete: " + this.sortTree[inLayer][inIndex] + " from layer: " + inLayer + " from position: " + inIndex);
                  airSortTree[inLayer].splice(inIndex, 1);
                }

                // id in layer unterhalb outlayer verschieben
                inLayer = outLayer + 1;

                if (!airSortTree[inLayer]) {
                  airSortTree[inLayer] = [];
                }

                // in in layer ablegen
                airSortTree[inLayer].push(airStrangPairs[i][1]);
                change = true;
              }
              //}
            }
          } while (change);

          //console.log("airSortTree vor airNewStrangPairs:");
          //console.log(airSortTree);

          // füge Elemente aus airNewStrangPairs zu airSortTree hinzu:
          for (let i = 0; i < airNewStrangPairs.length; i++) {
            // falls airSortTree noch leer ist:
            if (!airSortTree[0]) {
              airSortTree[0] = [];
            }

            // wenn IN (start neuer Strang) noch nicht in erster ebenen ist -> hinzufügen
            if (airSortTree[0].indexOf(airNewStrangPairs[i][1]) < 0) {
              airSortTree[0].push(airNewStrangPairs[i][1]);
            }
          }

          //console.log("airSortTree mit airNewStrangPairs 'in':");
          //console.log(airSortTree);

          // wenn airNewStrangPairs[i][0] (out) noch nicht in sortTree oder treatedIds ist, hinzufügen
          for (let i = 0; i < airNewStrangPairs.length; i++) {            
            if(treatedIds.indexOf(airNewStrangPairs[i][0]) < 0){

              // nicht in airSortTree
              let inAirSortTree = false;
              
              for(let j = 0; j < airSortTree.length; j++){    
                  for(let k = 0; k < airSortTree[j].length; k++){
                    //console.log(airSortTree[j][k]);
                    if(airNewStrangPairs[i][0] == airSortTree[j][k]){
                      inAirSortTree = true;
                      // break for loops:
                      j = airSortTree.length-1;
                      k = airSortTree[j].length;
                    }
                  }
              }
              if(inAirSortTree == false){
                // füge auf ebene 0 hinzu:
                airSortTree[0].push(airNewStrangPairs[i][0]);
              }
            }
          }


          //console.log("airSortTree mit airNewStrangPairs: ");
          //console.log(JSON.stringify(airSortTree));

          let airaufbaustraenge = [];

          // sortiere aus airStrangPairs elemente mit ende in treated ids aus
          let reducedAirStrangPairs = airStrangPairs.filter(function(value) {
            if (treatedIds.indexOf(value[1]) >= 0) {
              return false;
            } else {
              return true;
            }
          });

          //console.log("reducedAirStrangPairs");
          //console.log(reducedAirStrangPairs);

          // starte mit erster airSortTree Ebene:
          for (let i = 0; i < airSortTree.length; i++) {
            // jedes Element aus der Ebene:
            for (let j = 0; j < airSortTree[i].length; j++) {
              let startId = airSortTree[i][j];

              let child = this.checkIfInAndAppend(
                airaufbaustraenge,
                startId,
                reducedAirStrangPairs,
                true // z-axis
              );
              if (child != null) {
                child.axis = 'z';
                airaufbaustraenge.push(child);
              }
            }
          }

          //console.log("airaufbaustraenge");
          //console.log(JSON.stringify(airaufbaustraenge));

          /// airaufbaustraenge jetzt noch in aufbaustraenge einsortieren

          // straenge die auf aktuellen Aufbau aufgesetzt werden:
          for (let i = airaufbaustraenge.length - 1; i >= 0; i--) {
            //console.log("airaufbaustraenge[i].id: " + airaufbaustraenge[i].id);

            let ref = this.getRefAufbauStrang(
              aufbaustraenge,
              airaufbaustraenge[i].id
            );
            // auf straenge aufsetzen:
            if (ref != null) {
              //console.log("ref: ");
              //console.log(ref);
              /*console.log("add: ");
              console.log(airaufbaustraenge[i].children)
              console.log("children.length: " + airaufbaustraenge[i].children.length)*/
              for (let j = 0; j < airaufbaustraenge[i].children.length; j++) {
                // nur die children (id ist ja schon letztes objekt
                ref.children.push(airaufbaustraenge[i].children[j]);

                // set ids as treatedIds:
                treatedIds = this.setTreatedIds(
                  airaufbaustraenge[i].children[j],
                  treatedIds
                );
              }

              // remove treated from airaufbaustraenge
              airaufbaustraenge.splice(i, 1);
            }
          }

          // console.log("aufbaustraenge vor airNewStrangPairs");
          // console.log(JSON.stringify(aufbaustraenge));

          // noch nicht mit bulk componenten verbunden
          // console.log("übrige airaufbaustraenge:");
          // console.log(JSON.stringify(airaufbaustraenge));

          // komponenten, die ein verbindung zum Strang haben:
          let halfTreatedConnections = this.getHalfTreatedConnections(
            airNewStrangPairs,
            airStrangPairs,
            treatedIds
          );

          //console.log("halfTreatedConnections + added: ");
          //console.log(halfTreatedConnections);

          console.log("Straenge z-Axis");
          // straenge, die parallel gesetzt werden:

          //console.log(halfTreatedConnections);

          
          if (halfTreatedConnections.length > 0) {
            let change = false;
            do {
              change = false;
              for (let i = airaufbaustraenge.length - 1; i >= 0; i--) {
                let connectedID = this.findConnectionToAufbau(
                  airaufbaustraenge[i],
                  halfTreatedConnections
                );
                //console.log("connectedID from airaufbaustraenge: " + connectedID);

                if(connectedID >= 0){
                  // finde verbundenen Aufbaustrang:
                  let strangIndex = -1;
                  for (let j = 0; j < aufbaustraenge.length; j++) {
                    if (
                      aufbaustraenge[j].id == connectedID ||
                      this.isInAufbauStrang(
                        aufbaustraenge[j].children,
                        connectedID
                      )
                    ) {
                      strangIndex = j;
                      //console.log("strang index von aufbaustraenge: " + strangIndex);
                      // end for
                      j = aufbaustraenge.length;
                    }
                  }

                  if (strangIndex >= 0) {
                    // in aufbaustrang einreihen:
                    let neuerStrang = airaufbaustraenge[i];
                    //neuerStrang.axis = "z";
                    aufbaustraenge.splice(strangIndex + 1, 0, neuerStrang);

                    // add neuerStrang to treatedIds:
                    // set ids as treatedIds:
                    treatedIds = this.setTreatedIds(
                      airaufbaustraenge[i],
                      treatedIds
                    );

                    // entferne aus airaufbaustraenge:
                    airaufbaustraenge.splice(i, 1);


                    console.log("find new halfTreatedConnections");
                    // aktualisiere halfTreatedConnections:
                    halfTreatedConnections = this.getHalfTreatedConnections(
                      airNewStrangPairs,
                      airStrangPairs,
                      treatedIds
                    );
                    
                    console.log("deadly do-while");
                    console.log(airaufbaustraenge);
                    change = true;
                    //console.log(halfTreatedConnections);
                  }
                }
              }
            } while (airaufbaustraenge.length > 0 && change == true);
          }
          
          console.log("füge übrige straenge ans ende an: übrige airaufbaustraenge:");
          console.log(airaufbaustraenge);
          // wenn übrig: hinten anfügen

          


          for (let i = 0; i < airaufbaustraenge.length; i++) {
            let neuerStrang = airaufbaustraenge[i];
            //neuerStrang.axis = "z";
            aufbaustraenge.push(neuerStrang);
          }
          // delete them ...
          airaufbaustraenge = [];
          /*for (let i = airaufbaustraenge.length - 1; i >= 0; i--) {
            console.log("füge übrige straenge ans ende an");
            let neuerStrang = airaufbaustraenge[i];
            neuerStrang.axis = "z";
            aufbaustraenge.push(neuerStrang);
          }*/


          console.log("aufbaustraenge mit Air final:");
          console.log([aufbaustraenge]);
          console.log(JSON.stringify(aufbaustraenge));        
        } // end if(getPlantConnections());
        this.aufbauStraenge = aufbaustraenge;
    },

    placeChildren(strang, leftX, yBottom, zPosIndent, strangWidth){
      // collect all children der ebene:
      console.log("placeChildren: ");
      //console.log(strang);
      //let children = []; 
      
      let w = [];
      let sumW = 0;
      for(let i = 0; i<strang.length; i++){
        let component = this.getPlantComponents[this.getPlantComponents.findIndex((comp) => comp.id == strang[i].id)];
        let size = this.getModelSize(component, strang[i]);
        w[i] =  size.z;
        //sumW += size.z;

        sumW += strang[i].width;
      }
      console.log("(strangWidth - sumW) / (strang.length * 2.0): (" + strangWidth + " - " +  sumW + ") / (" + strang.length + " * 2.0)");
      let padding = (strangWidth - sumW) / (strang.length * 2.0);
      console.log("padding: " + padding);

      let _x = 0;
      for(let i = 0; i<strang.length; i++){
        //let maxY = 0;
        _x += padding;

        // calculate padding of component:
        let compPadding = (strang[i].width - w[i]) / 2;

        let threedPos = new THREE.Vector3(
              //leftX +  ((w[i]/2 + _x) * this.modelscale),
              leftX + w[i]/2 + _x + compPadding,
              yBottom,
              zPosIndent,
        );
        console.log("zweite Ebene x (leftX + w[i]/2 + _x + compPadding): " + leftX + " + " + (w[i]/2) +  " + " + _x + " + " + compPadding + " = " +  threedPos.x);
        let component = this.getPlantComponents[this.getPlantComponents.findIndex((comp) => comp.id == strang[i].id)];
        let isZ = (strang[i].axis && strang[i].axis == 'z')? true : false;
        let yTop = this.placeComponentV2(threedPos, component, isZ);
        //maxY = Math.max(maxY, yTop);

        // add all children of children:
        //children = children.concat(strang[i].children);
        //console.log(strang[i].children);

        console.log("children.length: " + strang[i].children.length);
        if(strang[i].children.length > 0){
          this.placeChildren(strang[i].children, leftX + _x, yTop, zPosIndent, strang[i].width);
        }

        //console.log("yTop Max: " + yTop + " - " + maxY);
        _x += strang[i].width + padding;
      }


    },

    buildPlantV2: function(){
      if (this.getPlantComponents && this.aufbauStraenge.length > 0) {

        let xPos = 0;
        let zPosIndent = 0;
        for(let i = 0; i<this.aufbauStraenge.length; i++){
          
          let component = this.getPlantComponents[
            this.getPlantComponents.findIndex((comp) => comp.id == this.aufbauStraenge[i].id)
          ];

          
          let isZ = (this.aufbauStraenge[i].axis && this.aufbauStraenge[i].axis == 'z')? true : false;
          console.log(component.name);
          console.log("isZ: " + isZ);

          // zAxis: in erster ebene immer weiter nach z verschieben:
          if(isZ){
            zPosIndent -= this.zIndent;
          } else {
            zPosIndent = 0;
          }

          // setze xPos um 1 element zurück, wenn zAxis gesetzt ist:
          if(isZ && xPos > 0){
            // find last objekt with no z-axis:
            let j = i-1;
            while(j >= 0 &&  (this.aufbauStraenge[j].axis && this.aufbauStraenge[j].axis == 'z')? true : false){
              j--;
            }
            if(j>= 0){
              xPos -= this.aufbauStraenge[j].width - this.columnXOffset;
            }
          }


          //let size = this.getModelSize(component);
          //console.log("breite: "+ (this.aufbauStraenge[i].width*this.modelscale));
          let threedPos = new THREE.Vector3(
            //xPos + (this.aufbauStraenge[i].width*this.modelscale ) / 2,
            xPos + (this.aufbauStraenge[i].width) / 2,
            0,
            zPosIndent,
          );

          console.log("ground threedPos: " + threedPos.x + " " + threedPos.y + " " + threedPos.z);

          let yTop = this.placeComponentV2(threedPos, component, isZ);
          console.log(yTop);
          
          // recursiv Etagen darüber aufbauen:
          let strang = this.aufbauStraenge[i].children;
          // mitgeben xPos von center:
          // mitgeben höchster Punkt y
          // mitgeben strang-width:
          this.placeChildren(strang, xPos, yTop, zPosIndent, this.aufbauStraenge[i].width);
          
          // xPos += this.aufbauStraenge[i].width*this.modelscale;

          if(!isZ){
            xPos += this.aufbauStraenge[i].width + this.columnXOffset;//*this.modelscale;
          } else {
              // find last objekt with no z-axis und füge offset wieder zu xPos dazu
              let j = i-1;
              while(j >= 0 &&  (this.aufbauStraenge[j].axis && this.aufbauStraenge[j].axis == 'z')? true : false){
                j--;
              }
              if(j >= 0){
                xPos += this.aufbauStraenge[j].width + this.columnXOffset;
              }
          }
          console.log("xPos: " + xPos);
          //console.log("modelscale: ");
          //console.log(this.modelscale);

          /*if(this.aufbauStraenge[i].axis && this.aufbauStraenge[i].axis == 'z'){

          } else {
            
          }*/

        }
      }
    },

    // checke ob die id schon im aufbau ist
    getRefAufbauStrang: function(aufbau, id) {
      //isInAufbauStrang: function(aufbau, id) {
      //console.log("start isInAufbauStrang " + id + " , Aufbau:");
      //console.log(aufbau);
      //console.log(JSON.stringify(aufbau));

      let ref = null;

      //console.log("find id: " + id);

      for (let i = 0; i < aufbau.length; i++) {
        let obj = aufbau[i];
        //console.log("compare curr obj:" + obj.id);
        //console.log(obj.children);
        if (obj.id == id) {
          //console.log("id enthalten: " + id);
          ref = obj;
          // break:
          i = aufbau.length;
        } else if (
          ref == null &&
          this.getRefAufbauStrang(obj.children, id) != null
        ) {
          //console.log("loop");
          ref = this.getRefAufbauStrang(obj.children, id);
          // break:
          i = aufbau.length;
        }
      }
      //console.log("return id ref: " + ref);
      return ref;
    },

    checkIfInAndAppend(airaufbaustraenge, startId, airStrangPairs, zAxis = false) {
      let obj = null;

      // id ist noch nicht eingebaut
      if (this.isInAufbauStrang(airaufbaustraenge, startId) == false) {
        obj = {
          id: startId,
          name: this.getComponentName(startId),
          children: [],
        };

        for (let i = 0; i < airStrangPairs.length; i++) {
          if (airStrangPairs[i][0] == startId) {
            let child = this.checkIfInAndAppend(
              airaufbaustraenge,
              airStrangPairs[i][1],
              airStrangPairs,
              zAxis,
            );
            if (child != null) {

              // MM: with zAxis for elements on top
              // Kinder können hier als nicht z-axis geändert werden ...
              if(zAxis){
                child.axis = "z";
              }
              obj.children.push(child);
            }
            //obj.children.push({id: airStrangPairs[k][i]})
          }
        }
      }
      return obj;
    },

  

    // delete all plant components from scene:
    resetScene: function() {
      console.log("resetting scene");
      // wenn model in this.models verfügbar ist:
      if (this.pComponents != null) {
        for (let i = this.pComponents.length - 1; i >= 0; i--) {
          let obj = this.scene.getObjectByName(this.pComponents[i].name);
          this.scene.remove(obj);
          this.pComponents.pop();
        }
      }

      this.createStrangAufbau();
      this.calculateStrangPositions();
      this.buildPlantV2();
      this.createPipes();
    },
    placeComponentV2: function(threeDCoords, component, isZ){
      let mesh;
      let componentType = this.allComponentTypes[component.name];

      if (componentType.model && componentType.model.link){
        let version = 0;
        if(component.version && componentType.versions){
          // find version no ...
          for(let i = 0; i<componentType.versions.length; i++){

            // finde die passende Versionsnummer:
            if(componentType.versions[i] == component.version){
              
              // wenn es auch wirklich ein spezifische Modell gibt:
              if(this.models[componentType.model.link].length > i){
                version = i;
              }
            }
          }
        }

        console.log(this.models[componentType.model.link][version]);
        mesh = this.models[componentType.model.link][version].clone();
        //mesh = new THREE.Mesh(this.models[componentType.model.link][version].geometry, this.models[componentType.model.link][version].material);
        mesh.bbox = cloneDeep(this.models[componentType.model.link][version].bbox);
        console.log(mesh);
        // bounding box wird jetzt in maikeinstance berechnet
        // mesh.bbox = this.models[component.name].bbox;
        mesh.castShadow = true;
        mesh.receiveShadow = false;
        //mesh.children[0].material = this.materials.metal;

        if(mesh.children.length > 0){
          /*if(!component.assignment || (component.assignment && component.assignment == "coperion")){
                //mesh.children[0].material.color = cloneDeep(this.materials.coperion.color);
                mesh.children[0].material = this.materials.coperion;
          } else if(component.assignment == "existing"){
                //mesh.children[0].material.color = cloneDeep(this.materials.existing.color);
                mesh.children[0].material = this.materials.existing;
          } else if(component.assignment == "other"){
                //mesh.children[0].material.color = cloneDeep(this.materials.other.color);
                mesh.children[0].material = this.materials.other;
          } else {
                //mesh.children[0].material.color = cloneDeep(this.materials.future.color);
                mesh.children[0].material = this.materials.future;
          }*/

       
          mesh.children[0].material.color = this.materials.metal.color;
          mesh.children[0].material.normalScale = this.materials.metal.normalScale;
          mesh.children[0].material.envMap = this.materials.metal.envMap;

          mesh.children[0].material.roughness = this.materials.metal.roughness;
          mesh.children[0].material.metalness = this.materials.metalness;
          //gibt bounds zurück
        } else {
          console.log(mesh);
          console.log("no mesh: " + component.name);
        }

        console.log("component.assignment: " + component.assignment);
        console.log("mesh.children.length: " + mesh.children.length);
        console.log("mesh.children[0].color:");
        //console.log(mesh.children[0].material.color);

        console.log("in placeComponent V2: component:");
        console.log(component);


        
        // WIREBOX
        if(!component.assignment || (component.assignment && component.assignment == "coperion")){
          // keine WIREBOX
        } else{

          let material;
          let materialWire
          if(!component.assignment || (component.assignment && component.assignment == "coperion")){
            //material = this.materials.coperionTransparent;
                  //material = this.materials.coperionWire;
          } else if(component.assignment == "existing"){
            material = this.materials.existingTransparent;
            materialWire = this.materials.existingWire;
          } else if(component.assignment == "other"){
            material = this.materials.otherTransparent;
            materialWire = this.materials.otherWire;
          } else {
            material = this.materials.futureTransparent;
            materialWire = this.materials.futureWire;
          }
          let _bbox = cloneDeep(mesh.bbox);
          let size = _bbox.min.sub(_bbox.max);
          
          let geometry = new THREE.BoxGeometry(size.x * this.modelscale, size.y * this.modelscale, size.z * this.modelscale, 1);
          let boxmesh = new THREE.Mesh(geometry, material);
         
          boxmesh.castShadow = true;
          boxmesh.receiveShadow = false;

          let boxmeshWire = new THREE.Mesh(geometry, materialWire);
          boxmeshWire.castShadow = false;
          boxmeshWire.receiveShadow = false;

          geometry.computeBoundingBox(); 
          boxmesh.bbox = geometry.boundingBox;
          boxmeshWire.bbox = cloneDeep(geometry.boundingBox);

          //zeichnet transparenten block
          this.makeInstanceV2(
            boxmesh,
            threeDCoords.x,
            threeDCoords.y,
            threeDCoords.z,
            component.id,
            isZ,
            component.position.rotate
          );

          // zeichnet wireframe
          this.makeInstanceV2(
            boxmeshWire,
            threeDCoords.x,
            threeDCoords.y,
            threeDCoords.z,
            component.id,
            isZ,
            component.position.rotate
          );
          
          ////////////////////////////////////
        }
        






        return this.makeInstanceV2(
          mesh,
          threeDCoords.x,
          threeDCoords.y,
          threeDCoords.z,
          component.id,
          isZ,
          component.position.rotate
        );
      } else {
        // zeichne (sonst) box:
        let color;
        let material;

        //let width = 6;
        //let height = 3;
        
        if(!component.assignment || (component.assignment && component.assignment == "coperion")){
                //color = cloneDeep(this.materials.coperion.color);
                color = this.materials.coperion.color;
          } else if(component.assignment == "existing"){
                //color = cloneDeep(this.materials.existing.color);
                color = (this.materials.existing.color);
          } else if(component.assignment == "other"){
                //color = cloneDeep(this.materials.other.color);
                color = (this.materials.other.color);
          } else {
                // color = cloneDeep(this.materials.future.color);
                color = (this.materials.future.color);
        }
        //color = new THREE.Color(0xeeeeee);
        material = new THREE.MeshPhongMaterial({
            color: color,
            opacity: 0.8,
            transparent: true,
        });
        
        let geometry = new THREE.BoxGeometry(this.dummyModel.x * this.modelscale, this.dummyModel.y * this.modelscale, this.dummyModel.z * this.modelscale, 1);
        let boxmesh = new THREE.Mesh(geometry, material);
        boxmesh.castShadow = true;
        boxmesh.receiveShadow = false;

        geometry.computeBoundingBox(); 
        boxmesh.bbox = geometry.boundingBox;
        //gibt bounds zurück
        return this.makeInstanceV2(
          boxmesh,
          threeDCoords.x,
          threeDCoords.y,
          threeDCoords.z,
          component.id,
          isZ,
          component.position.rotate
        );

      }
    },
    makeInstanceV2: function(mesh, x, y, z, id, isZ, rotation){
      let offset = mesh.bbox.min.sub(mesh.bbox.max);

      // bbox x - z vertauscht!
      var tempZ = Math.abs(offset.x);
      offset.x = Math.abs(offset.z);
      offset.y = Math.abs(offset.y);
      offset.z = tempZ;
      /*offset.x = Math.abs(offset.x);
      offset.y = Math.abs(offset.y);
      offset.z = Math.abs(offset.z);*/

      offset.x *= mesh.scale.x;
      offset.y *= mesh.scale.y;
      offset.z *= mesh.scale.z;
      //console.log(offset);

      mesh.position.x = x * this.modelscale;
      //if (isGrounded) {
        // mesh.position.x += offset.x; // füge die h#älfte der breite hinzu (v.a. für lange komponenten wie extruder)
      //}

      //mesh.position.y = y + offset.y/2;

      mesh.position.z = z * this.modelscale;
      console.log("mesh.position.z = z * this.modelscale: " + mesh.position.z);
      console.log("isz: " + isZ);

      // if(isZ){
      //   //mesh.position.z = -20;
      // }

      // if (!isGrounded) {
      //   mesh.position.y += offset.y / 2;
      // }

      mesh.name = id;

      

      let yTopOffset = offset.y;
      
      if (isZ) {
        //mesh.rotateY(0);
        //mesh.rotation.y = 0;
      } else {
        // standartmaessig alle um 90° gedreht!!!
        mesh.rotateY(Math.PI /2);
        //mesh.rotation.y = Math.PI / 2;
        
      }
      console.log("mesh");
      console.log(mesh);



      console.log(rotation);
      if(rotation){
        console.log(rotation);
        mesh.rotateX(rotation * Math.PI / 2);

        mesh.position.y = y + offset.x/2;

        if (isZ){ 
          yTopOffset = offset.x;
        } else {
          yTopOffset = offset.z;
        }

      } else {
        // ohne rotation:
        mesh.position.y = y + offset.y/2;
      }
      //mesh.position.y = y + offset.y/2;

      


      // (for animation later?)
      mesh._scale = new THREE.Vector3(mesh.scale.x, mesh.scale.y, mesh.scale.z);
      if (!this.enabledebugmode) {
        mesh.scale.set(0, 0, 0);
      }

      this.pComponents.push(mesh);
      this.scene.add(mesh);

      return (y + yTopOffset + this.columnYOffset*this.modelscale);
    },
    
    loadModels: function() {
      console.log("loading models");
      //console.log(this.allComponentTypes);


      const keys = Object.keys(this.allModels);
      for (const key of keys) {
        for(let v = 0; v < this.allModels[key].versions.length; v++){
          this.modelstoload++;
          this.totalmodels++;
          console.log("url: " + this.allModels[key].versions[v].url);
          this.load3DModel(this.allModels[key].versions[v].url, key, v, this.allModels[key].versions[v].scale);
        }
      }

      /*const keys = Object.keys(this.allComponentTypes);
      for (const key of keys) {
        //console.log(key);
        //console.log(this.allComponentTypes[key]);

        let c = this.allComponentTypes[key];
        if (c.model) {
          this.modelstoload++;
          this.totalmodels++;
          //console.log("loading model");
          this.load3DModel(c.model.url, key, c.model.scale * this.scenescale);
        } else {
          console.log("no model found for " + key);
        }
      }*/
    },

    // asynchrone funktion zum Model laden -> loaderPromise wird erst aufgerufen, wenn das Modell geladen is
    load3DModel: async function(url, name, version, scale) {
      scale *= this.modelscale;
      console.log("loading model " + url + " " + name + " " + version);
      let loaderPromise = new Promise((resolve, reject) => {
        //setTimeout(() => {
          let loader = new OBJLoader();
          //loader.load(url, (collada) => {

          loader.load(url, (obj) => {
            let c_model = obj;
            c_model.scale.set(scale, scale, scale);

            c_model.traverse(function(child) {
              if (child instanceof THREE.Mesh) {
                child.castShadow = true;
              }
            });

            if (c_model !== null) {
              resolve(c_model);
            } else {
              reject(Error("Couldn't load " + name));
            }
          });
        //}, 3000);
      });

      // tausche Box mit Model aus:
      // wenn eine neue Komponente geladen ist, wird die Scene resettet und neu aufgebaut
      loaderPromise.then((fromResolve) => {
        // rebuild scene, wenn neue models geladen sind
        //console.log("loaded 3d Model: " + name);
        this.modelstoload--;

        if(!this.models[name]){
          this.models[name] = [];
        }
        this.models[name][version] = fromResolve;

        if(this.models[name][version].children.length <= 0){
          console.log(this.models[name][version]);
          console.log("no mesh: " + name);
        } 

        let geometry;
        if (this.models[name][version].children[0]) {
          geometry = this.models[name][version].children[0].geometry;
        } else {
          geometry = this.models[name][version].geometry;
        }
        geometry.computeBoundingBox();
        let bbox = geometry.boundingBox;

        this.models[name][version].bbox = bbox; // vielleicht später brauchbar…

        // wenn letztes model gelade ist:
        if(this.modelstoload <= 0){
          this.resetScene();
        }
      });
      // .catch((fromReject) => {
      // console.log(fromReject);
      // });
    },


    getConnectorPosition: function(obj, in_out, type){
            let comp = document.getElementById("component_group_" + obj.id);
            if(comp){
                let outNo = obj.no ? obj.no : 0;

                let connector = comp.getElementsByClassName(in_out +' ' + type)[outNo];

                if(connector.classList.contains('top')){
                  return 'top';
                } else if(connector.classList.contains('right')){
                  return 'right';
                } else if(connector.classList.contains('bottom')){
                  return 'bottom';
                }else {
                  return 'left';
                }
            }else {
                return false;
            }
    },
    createPipe: function(pos1, pos2, thickness, material){
      let path = [];
      path.push(pos1);
      path.push(pos2);
      let curve = new THREE.CatmullRomCurve3(path);
      let geometry = new THREE.TubeGeometry(
        curve,
        3,
        thickness,
        8,
        false
      );
      let pipe = new THREE.Mesh(geometry, material);
      pipe.name = "pipe";
      pipe.castShadow = true;
      this.pipes.push(pipe);

      this.scene.add(this.pipes[this.pipes.length - 1]);
    },

    createPipes: function() {
      let thicknessBulk = 0.065;
      let thicknessAir = 0.035;

      if (this.getPlantConnections) {
        //console.log("PIPES");

        // wipe pipes out before creating new ones
        for (let i = this.pipes.length - 1; i >= 0; i--) {
          this.scene.remove(this.pipes[i]);
          this.pipes[i] = {};
          this.pipes[i] = null;
          this.pipes.pop();
        }
        this.pipes = [];

        this.getPlantConnections.forEach((con) => {

            // Calculate in and out position as well as middle node for edge break
            let compOut = this.pComponents[
              this.pComponents.findIndex((comp) => comp.name == con.out.id)
            ];

            let conPosOut = this.getConnectorPosition(con.out, "output", con.type);
            let connectorOffsetOut = new THREE.Vector3(0, 0, 0);
            let connectorOffsetOutOffset = new THREE.Vector3(0, 0, 0);

            if(conPosOut){
              console.log("conPosOut: " + conPosOut);
              let component = this.getPlantComponents[this.getPlantComponents.findIndex((comp) => comp.id == con.out.id)];
              let ref = this.getRefAufbauStrang(this.aufbauStraenge, con.out.id);
              let size = this.getModelSize(component, ref);

              if(conPosOut == "top"){
                connectorOffsetOut.y = this.modelscale * size.y/2.0;
                connectorOffsetOutOffset.y = this.modelscale * (size.y/2.0 + this.columnYOffset/2);
              } else if(conPosOut == "right"){
                
                if(con.type == "bulk"){
                  connectorOffsetOut.x = this.modelscale * size.z/2.0;
                  connectorOffsetOutOffset.x = this.modelscale * (size.z/2.0 + this.columnXOffset/2);
                } else {
                  connectorOffsetOut.z = this.modelscale * (-size.x/2.0);
                  connectorOffsetOutOffset.z = this.modelscale * (-size.x/2.0 - this.columnZOffset/2);
                }
              } else if(conPosOut == "bottom"){
                connectorOffsetOut.y = this.modelscale * (-size.y/2.0);
                connectorOffsetOutOffset.y = this.modelscale * (-size.y/2.0 - this.columnYOffset/2);
              } else {
                if(con.type == "bulk"){
                  connectorOffsetOut.x = this.modelscale * (-size.z/2.0);
                  connectorOffsetOutOffset.x = this.modelscale * (-size.z/2.0 - this.columnXOffset/2);
                }else{
                  if(compIn.position.z != 0){
                    connectorOffsetOut.z = this.modelscale * size.x/2.0;
                    connectorOffsetOutOffset.z = this.modelscale * (size.x/2.0 + this.columnXOffset/2); // nach vorne nur X columnXOffset
                  } else {
                    // nur nach hinten:
                    connectorOffsetOut.z = this.modelscale * (-size.x/2.0);
                    connectorOffsetOutOffset.z = this.modelscale * (-size.x/2.0 - this.columnZOffset/2); 
                  }
                }
              }
            }
            let pCompPosOutEdge = cloneDeep(compOut.position);
            pCompPosOutEdge.add(connectorOffsetOut);

            let pCompPosOutEdgeOffset = cloneDeep(compOut.position);
            pCompPosOutEdgeOffset.add(connectorOffsetOutOffset);



            let compIn = this.pComponents[
              this.pComponents.findIndex((comp) => comp.name == con.in.id)
            ];
            
            let conPosIn = this.getConnectorPosition(con.in, "input", con.type);
            
            let connectorOffsetIn = new THREE.Vector3(0, 0, 0);
            let connectorOffsetInOffset = new THREE.Vector3(0, 0, 0);
            if(conPosIn){
              let component = this.getPlantComponents[this.getPlantComponents.findIndex((comp) => comp.id == con.in.id)];
              let ref = this.getRefAufbauStrang(this.aufbauStraenge, con.in.id);
              let size = this.getModelSize(component, ref);
              console.log("conPosIn: " + conPosIn);
              if(conPosIn == "top"){
                connectorOffsetIn.y = this.modelscale * size.y/2.0;
                connectorOffsetInOffset.y = this.modelscale * (size.y/2.0 + this.columnYOffset/2);
              } else if(conPosIn == "right"){
                if(con.type == "bulk"){
                  connectorOffsetIn.x = this.modelscale * size.z/2.0;
                  connectorOffsetInOffset.x = this.modelscale * (size.z/2.0  + this.columnXOffset/2);
                } else{
                  connectorOffsetIn.z = this.modelscale * (-size.x/2.0);
                  connectorOffsetInOffset.z = this.modelscale * (-size.x/2.0 - this.columnZOffset/2);
                }
              } else if(conPosIn == "bottom"){
                connectorOffsetIn.y = this.modelscale * (-size.y/2.0);
                connectorOffsetInOffset.y = this.modelscale * (-size.y/2.0 - this.columnYOffset/2);
              } else {
                if(con.type == "bulk"){
                  connectorOffsetIn.x = this.modelscale * (-size.z/2.0);
                  connectorOffsetInOffset.x = this.modelscale * (-size.z/2.0 - this.columnXOffset/2);
                }else {
                  if(compIn.position.z != 0){
                    connectorOffsetIn.z = this.modelscale * size.x/2.0;
                    connectorOffsetInOffset.z = this.modelscale * (size.x/2.0  + this.columnXOffset/2); // nach vorne nur X columnXOffset
                  } else {
                    // bei z == 0 nur nach hinten:
                    connectorOffsetIn.z = this.modelscale * (-size.x/2.0);
                    connectorOffsetInOffset.z = this.modelscale * (-size.x/2.0 - this.columnZOffset/2);
                  }
                }
              }
            }
            let pCompPosInEdge = cloneDeep(compIn.position);
            pCompPosInEdge.add(connectorOffsetIn);

            let pCompPosInEdgeOffset = cloneDeep(compIn.position);
            pCompPosInEdgeOffset.add(connectorOffsetInOffset);

            


            let pipePositons = [];

            pipePositons.push(pCompPosOutEdge);
            pipePositons.push(pCompPosOutEdgeOffset);

            // Middle Points:
            if(conPosOut == "left" || conPosOut == "right"){
              pipePositons.push(new THREE.Vector3(pCompPosOutEdgeOffset.x, pCompPosInEdgeOffset.y, pCompPosOutEdgeOffset.z));
            }

            if(conPosOut == "top" || conPosOut == "bottom"){
              if(con.type == "bulk"){
                pipePositons.push(new THREE.Vector3((pCompPosOutEdgeOffset.x + pCompPosInEdgeOffset.x)/2, pCompPosOutEdgeOffset.y, pCompPosOutEdgeOffset.z));
                pipePositons.push(new THREE.Vector3((pCompPosOutEdgeOffset.x + pCompPosInEdgeOffset.x)/2, pCompPosInEdgeOffset.y, pCompPosInEdgeOffset.z));
              } else{
                // pipePositons.push(new THREE.Vector3(pCompPosOutEdgeOffset.x, pCompPosOutEdgeOffset.y, (pCompPosOutEdgeOffset.z + pCompPosInEdgeOffset.z)/2));
                // pipePositons.push(new THREE.Vector3(pCompPosInEdgeOffset.x, pCompPosInEdgeOffset.y, (pCompPosOutEdgeOffset.z + pCompPosInEdgeOffset.z)/2));
                pipePositons.push(new THREE.Vector3(pCompPosOutEdgeOffset.x,  pCompPosOutEdgeOffset.y,  pCompPosInEdgeOffset.z));
                pipePositons.push(new THREE.Vector3(pCompPosOutEdgeOffset.x,  pCompPosInEdgeOffset.y,  pCompPosInEdgeOffset.z));
                pipePositons.push(new THREE.Vector3(pCompPosInEdgeOffset.x,   pCompPosInEdgeOffset.y,   pCompPosInEdgeOffset.z));
              }
            }

            pipePositons.push(pCompPosInEdgeOffset);
            pipePositons.push(pCompPosInEdge);
            


            //let posMiddle1 = new THREE.Vector3(pCompPosOutEdgeOffset.x, pCompPosInEdgeOffset.y, pCompPosOutEdgeOffset.z);
            //let posMiddle2 = new THREE.Vector3(pCompPosOutEdgeOffset.x, pCompPosInEdgeOffset.y, pCompPosOutEdgeOffset.z);

            let thickness;
            if(con.type == "bulk"){
               thickness = thicknessBulk;
            } else {
               thickness = thicknessAir;
            }

            let material;
            if(!con.assignment || (con.assignment && con.assignment == "coperion")){
              material = this.materials.coperionPipe;
            } else if(con.assignment == "existing"){
              material = this.materials.existingPipe;
            } else if(con.assignment == "other"){
              material = this.materials.otherPipe;
            } else {
              material = this.materials.futurePipe;
            }

            for(let i = 1; i<pipePositons.length; i++){
              this.createPipe(pipePositons[i-1], pipePositons[i], thickness, material);
            }
        });


/*


        // console.log("BUILD AIR CONNECTIONS. COUNT: " + this.zPipes.length);
        for (let i = 0; i < this.zPipes.length - 1; i += 2) {
          //console.log(this.zPipes[i]);
          let posIn = this.zPipes[i];

          let posOut = this.zPipes[i + 1];

          // 1ST PIPE SEGMENT
          let path = [];
          path.push(posIn);
          path.push(posOut);
          let curve = new THREE.CatmullRomCurve3(path);
          let geometry = new THREE.TubeGeometry(curve, 3, thicknessAir, 8, false);
          let pipe = new THREE.Mesh(geometry, this.materials.basic);
          pipe.name = "pipe";
          pipe.castShadow = true;
          this.pipes.push(pipe);

          // console.log("adding pipe");

          this.scene.add(this.pipes[this.pipes.length - 1]);
        } */
      }
    },

    render: function() {
      this.renderer.render(this.scene, this.camera);
    },
    update: function() {
      //TEST COMPONENT ANIMATION
      let time = Date.now();
      if (
        this.pComponents &&
        this.models &&
        this.initialized &&
        !this.tweenedIn
      ) {
        if (this.initialized && !this.enabledebugmode) {
          if (time % 10 < 5) {
            for (const [key, element] of Object.entries(this.pComponents)) {
              // console.log(key);
              // console.log("element");
              let _scale = new THREE.Vector3(
                element.scale.x,
                element.scale.y,
                element.scale.z
              );
              // _scale.y = element._scale.y * Math.cos((time + index * 0.35) * 0.001);
              _scale.x = this.lerp(_scale.x, element._scale.x, 0.075);
              _scale.y = this.lerp(_scale.y, element._scale.y, 0.075);
              _scale.z = this.lerp(_scale.z, element._scale.z, 0.075);

              this.pComponents[key].scale.set(_scale.x, _scale.y, _scale.z);
            }
          }
        }
      }
    },
    GameLoop: function() {
      requestAnimationFrame(this.GameLoop);
      this.update();
      this.render();
    },

    rectractPositions() {
      //console.log(this.pComponents);

      //console.log("shifting positions");

      this.pComponents.sort(function(a, b) {
        return a.position.y - b.position.y;
      });

      for (let i = 1; i < this.pComponents.length; i++) {
        if (!this.pComponents[i].isGrounded) {
          // check if component is up any other component
          let lowest = 1;
          for (let k = i; k < this.pComponents.length; k++) {
            if (k != i) {
              if (
                Math.abs(
                  this.pComponents[i].position.x -
                    this.pComponents[k].position.x
                ) <
                  this.gridSpacing * 0.5 &&
                this.pComponents[k].position.y > this.pComponents[i].position.y
              ) {
                lowest = k;
                break;
              }
            }
          }

          if (lowest > 0) {
            for (let j = i; j < this.pComponents.length; j++) {
              if (
                Math.abs(
                  this.pComponents[i].position.x -
                    this.pComponents[j].position.x
                ) <
                this.gridSpacing * 0.5
              ) {
                this.pComponents[lowest].position.y -= this.gridSpacing;
                this.pComponents[j].position.y = Math.abs(
                  this.pComponents[j].position.y
                );
              }
            }
          }
        }
      }
    },
    // ueberpruefe, dass Ids nur einmal im Aufbau sind und gliedere sie ein
    findAndAddChildren: function(
      aufbaustraenge,
      curr_strang,
      curr_Id,
      topDownPairs
    ) {
      // wenn id schon im aufbau vorkommt überspringen

      //console.log(curr_Id + " " + this.isInAufbauStrang(aufbaustraenge, curr_Id));

      if (this.isInAufbauStrang(aufbaustraenge, curr_Id) == false) {
        // neuer Strang:

        // suche alle elemente die mit der startId direkt top-down verbunden sind.
        let pairsList = [];
        topDownPairs.forEach((pair) => {
          if (pair[1] == curr_Id) {
            pairsList.push(pair);
          }
        });

        //console.log("pairsList:");
        //console.log(pairsList);

        let strang = {
          id: curr_Id,
          name: this.getComponentName(curr_Id),
          children: [],
        };

        curr_strang.push(strang);

        pairsList.forEach((pair) => {
          // Aufbau nach oben darf es nur einmal geben - alles andere gibt neuen Strang
          if (this.isInAufbauStrang(aufbaustraenge, pair[0]) == false) {
            let childId = pair[0];
            /// -> recursion ... obj
            // für childId
            this.findAndAddChildren(
              aufbaustraenge,
              strang.children,
              childId,
              topDownPairs
            );
          }
        });

        
      }
    },

    // checke ob die id schon im aufbau ist

    isInAufbauStrang: function(aufbau, id) {
      //console.log("start isInAufbauStrang " + id + " , Aufbau:");
      //console.log(aufbau);
      //console.log(JSON.stringify(aufbau));

      let enthalten = false;

      for (let i = 0; i < aufbau.length; i++) {
        let obj = aufbau[i];
        //console.log("obj in aufbau:");
        //console.log(obj);
        //console.log(obj.children);
        if (obj.id == id) {
          //console.log("id enthalten: " + id);
          enthalten = true;
          // break:
          i = aufbau.length;
        } else if (
          enthalten == false &&
          this.isInAufbauStrang(obj.children, id) == true
        ) {
          //console.log("loop");
          enthalten = true;
          i = aufbau.length;
        }
      }
      //console.log("id enthalten? " + enthalten);
      return enthalten;
    },
    findConnectionToAufbau: function(aufbau, connectionPairs) {
      let connectedID = -1;
      // suche connection zu aufbaustraenge:
      for (let i = 0; i < connectionPairs.length; i++) {
        if (
          aufbau.id == connectionPairs[i][0] ||
          aufbau.id == connectionPairs[i][1]
        ) {
          // get index of connectedElement
          if (aufbau.id == connectionPairs[i][0]) {
            connectedID = connectionPairs[i][1];
          } else {
            connectedID = connectionPairs[i][0];
          }

          // console.log("found connected Id: " + connectedID);
          // end for loop
          i = connectionPairs.length;
        }
      }

      // falls nichts gefunden: suche recursiv in children nach connection:
      if (connectedID <= 0) {
        for (let i = 0; i < aufbau.children.length; i++) {
          connectedID = this.findConnectionToAufbau(
            aufbau.children[i],
            connectionPairs
          );
        }
      }
      return connectedID;
    },
    lerp: function(a, b, c) {
      return a + c * (b - a);
    },
    setTreatedIds: function(obj, arr) {
      arr.push(obj.id);
      for (let i = 0; i < obj.children.length; i++) {
        arr = this.setTreatedIds(obj.children[i], arr);
      }
      return arr;
    },
    getHalfTreatedConnections: function(
      airNewStrangPairs,
      airStrangPairs,
      treatedIds
    ) {
      let halfTreatedConnections = airNewStrangPairs.filter(function(value) {
        if (
          (treatedIds.indexOf(value[0]) >= 0 &&
            treatedIds.indexOf(value[1]) < 0) ||
          (treatedIds.indexOf(value[0]) < 0 &&
            treatedIds.indexOf(value[1]) >= 0)
        ) {
          return true;
        } else {
          return false;
        }
      });

      // console.log("airNewStrangPairs:");
      // console.log(airNewStrangPairs);

      // console.log("halfTreatedConnections: ");
      // console.log(halfTreatedConnections);

      // add remaining connections from airStrangPairs
      let adding = airStrangPairs.filter(function(value) {
        if (
          (treatedIds.indexOf(value[0]) >= 0 &&
            treatedIds.indexOf(value[1]) < 0) ||
          (treatedIds.indexOf(value[0]) < 0 &&
            treatedIds.indexOf(value[1]) >= 0)
        ) {
          return true;
        } else {
          return false;
        }
      });
      for (let i = 0; i < adding.length; i++) {
        halfTreatedConnections.push(adding[i]);
      }
      return halfTreatedConnections;
    },
    getComponentName(id) {
      const index = this.getPlantComponents.findIndex((comp) => comp.id == id);

      if (index !== -1) {
        return this.getPlantComponents[index].name;
      } else {
        return "no_name";
      }
    },
  },
  watch: {
    // bei Veränderung der Plant - reset und baue die Anlage neu:
    getPlantComponents: {
      deep: true,
      handler() {
        this.resetScene();
        //this.createStrangAufbau();
        //this.buildPlant();
      },
    },
  },
};
</script>

<style scoped>
#canvas {
  outline: none;
  border: none;
}

.hide {
  display: none;
}
</style>
