<template>
    <b-list-group>
      <!-- <b-list-group-item v-b-toggle="accordionID + '-' + topic.title"><i class="fas fa-angle-down rotate-icon"></i> {{topic.title}}</b-list-group-item>
      <b-collapse :id="accordionID + '-' + topic.title" :accordion="accordionID + '_' +  topic.title" role="tabpanel"> -->
      <b-list-group-item v-b-toggle="accordionID+ '_' + getTopicTitle"><i class="fas fa-angle-down rotate-icon"></i> {{topic.title}}</b-list-group-item>
      
      <!-- just one accordion open: -->
      <!-- <b-collapse :id="accordionID+ '_' + getTopicTitle" :accordion="accordionID+ '_' + getTopicTitle" role="tabpanel"> -->
      <b-collapse :id="accordionID+ '_' + getTopicTitle" :accordion="accordionID" role="tabpanel">
        <b-list-group>
          <b-list-group-item v-for="value in topic.items" :key="value.link"  @click="$emit('create-new-element', value.link)" size="sm" class="sub-item" >{{getName(value)}}</b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-list-group>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: "MenuAccordionBS",
        props: {
          topic: {
            type: Object,
            required: true,
            default: function () {
             return [];
            }
          },
          accordionID:{
            type: String,
            required: true,
          },
          type: {
            type: String,
            required: true,
          }
        },
        computed: {
          ...mapGetters(["allComponentTypes", "allModuleTypes"]),
          getTopicTitle: function(){
            return this.topic.title.replace(/ /g,'');
          }
        },
        methods: {
            getName: function(val){
              // console.log("getName:");
              // console.log(this.type);
              // console.log(val.link);
              if(this.type == "component"){
                return this.allComponentTypes[val.link].name;
              } else {
                return this.allModuleTypes[val.link].name;
              }
            }
        }
    }
</script>

<style scoped>
  div.list-group-flush .list-group:first-child .list-group-item {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    
  }
  .list-group-item:not(.collapsed){
        transition: background-color 0.5s;
        background-color: #006dee;
        color: #fff;
    }
    .list-group-item i {
      padding-right: 12px;
      padding-left: 0px;
    }

    #menu .sub-item{
      padding-left: 47px;
    }


    .list-group-item:not(.collapsed) .rotate-icon{
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      padding-right: 0px;
      padding-left: 12px;
        
    }
   .list-group-item {
     padding: 0.4rem 1.25rem;
     background-color: #fff;
     cursor: pointer;
   }
   #menu .collapse .show .list-group-item{
     border-top-width: 0px;
     background-color: #f3f3f3;
     /* background-color: rgba(0, 109, 238, 0.125); */
     /* background-color: #cde7fe; */
     color: #000;
   }


</style>