<template>
<div class="overlayBackground">
    <div class="overlay">
        
        <p>Settings for component: <br />
        <b>ID: {{componentId}}</b> ({{componentName}})</p>
        <div v-if="options.length > 0">
            <label for="options">Options: </label>
            <select id="options" v-model="option" @change="getVersions">
                <option v-for="item in options" :key="item[Object.keys(item)[0]].name" :value="Object.keys(item)[0]">{{item[Object.keys(item)[0]].name}}</option>
                
            </select>
        </div>
        <div v-if="versions.length>0">
            <label for="versions">Versions: </label>
            <select id="versions" v-model="version">
                <option v-for="version in versions" :key="version" :value="version">{{version}}</option>      
            </select>
        </div>
        <br />
        <b-button @click="saveChanges" style="margin-right: 1em">apply</b-button> <delete-window label="cancel" v-on:close-window="removeSelf"></delete-window>
    </div>
</div>
</template>

<script>
// import Vue from 'vue'
import DeleteWindow from './DeleteWindow';
import { EventBus } from './event-bus';
// import { mapGetters } from 'vuex'
//import Vue from 'vue'
//import { mapGetters } from 'vuex'

export default {
    name: "SettingsWindow",
    components:{
        'delete-window': DeleteWindow,
    },
    data(){
        return{
            option: '',
            version: '',
            versions: [],
        }
    },
    methods:{
        getVersions(){
                if(this.allComponentTypes[this.option].versions){
                    this.versions = this.allComponentTypes[this.option].versions;
                    /*console.log("getVersions()");
                    console.log(this.versions);
                    console.log(this.version);*/
                    // reset Version if not available:
                    if(this.versions.indexOf(this.version) < 0){
                        //console.log("not inside");
                        this.version = "";
                    }


                } else {
                    this.versions = [];
                    this.version = "";
                }
       },
        saveChanges(){
            console.log("save changes: " );
            console.log(this.option);
            console.log(this.version);

            let data = {
                id: this.componentId,
            };
            
            if(this.option){
                data.name = this.option;
            }
            if(this.version){
                data.version = this.version;
            }
            // in App.js
            console.log(data)
            EventBus.$emit('changeComponent', data);
            this.removeSelf();
        },
        removeSelf(){
            console.log("remove Window");
            this.$el.remove();
        }
    },
    props: {
        // plantComponentID
        componentName: {
            type: String,
            required: true,
        },
        componentId: {
            type: Number,
            required: true,
        },
        componentVersion: {
            type: String,
        },
        allComponentTypes: {
            type: Object,
            required: true,
        }
        /*options: {
            type: Array,
        }*/
        /*versions: {
            type: Array,
        }*/
        
    },
    computed: {
        // ...mapGetters(["allComponentTypes"]),
        
       options: function(){
           let options = [];
           if(this.allComponentTypes[this.componentName].class){
                const all = this.allComponentTypes;
                const className = this.allComponentTypes[this.componentName].class;

                Object.keys(all).forEach(function(name){
                        if(all[name].class == className){
                            //let _name = name
                            let obj = {};
                            obj[name] = all[name];
                            options.push(obj);
                        }
                });
           }
           return options
          /* // finde alternativen:
                let options = [];
                // this class:
                if(this.allComponentTypes[this.compValues.name].class){
                    
                    const all = this.allComponentTypes;
                    const className = this.allComponentTypes[this.compValues.name].class;

                    Object.keys(all).forEach(function(name){
                        if(all[name].class == className){
                            //let _name = name
                            let obj = {};
                            obj[name] = all[name];
                            options.push(obj);
                        }
                    });
                }*/
       }
    },
    mounted() {
        this.option = this.componentName;
        this.version = this.componentVersion;
        this.getVersions();

            // const current = this.allComponentTypes[this.componentName];
            // let selection = [];

            // Object.keys(this.allComponentTypes).forEach(function(name){
            //     if(this.allComponentTypes[name].class == current.class){
            //         selection.push(name);
            //     }
            // });

            // console.log(selection);
            // //return "test";
        
    },

}
</script>

<style scoped>

    .overlay {
        position: relative;
        min-width: 300px;
        /* min-height: 150px; */
        background-color: #fff;
        /*border: #000 solid 1px;*/
        padding: 20px;
        
    }
    .overlayBackground {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;

    }
    label {
        padding-right: 1em;
    }
</style>