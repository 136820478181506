<template>
    <g :transform="transform">
        <rect x=0 y=0 :width='size' :height='size' class='background'><!-- <i class="fas fa-trash-alt" /> --></rect>
        <g :transform="transformIcon">
            <path class="noStroke" :fill="fill" d="M-4-3.5c-0.6,0-1.2,0.5-1.2,1.3l1.1,7.9c0,0.7,0.6,1.2,1.2,1.2h0.1V-3.5H-4z"/>
            <polygon class="noStroke" :fill="fill" points="0.4,6.9 1.8,6.9 1.8,-3.5 0.4,-3.5 0.4,1.1 "/>
            <polygon class="noStroke" :fill="fill" points="-1.8,6.9 -0.4,6.9 -0.4,-3.5 -1.8,-3.5 -1.8,1.1 "/>
            <path class="noStroke" :fill="fill" d="M4-3.5H2.7V6.9h0.1c0,0,0,0,0.1,0c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.4-0.5,0.4-0.9l0,0l1.1-7.9C5.2-2.9,4.7-3.5,4-3.5z"/>
            <path class="noStroke" :fill="fill" d="M5.2-5.5v-0.3c0-0.6-0.5-1.1-1.1-1.1h-8.2c-0.6,0-1.1,0.5-1.1,1.1v0.3c0,0.6,0.5,1.1,1.1,1.1h8.2C4.7-4.4,5.2-4.9,5.2-5.5z"
	/>
            <!-- <path class="noStroke" :fill="fill" d="M7,7.5C6.4,7.5,5.8,8,5.8,8.8l1.1,7.9c0,0.7,0.6,1.2,1.2,1.2h0.1V7.5H7z"/> 
            <polygon class="noStroke" :fill="fill" points="11.4,17.9 12.8,17.9 12.8,7.5 11.4,7.5 11.4,12.1 "/>
            <polygon class="noStroke" :fill="fill" points="9.2,17.9 10.6,17.9 10.6,7.5 9.2,7.5 9.2,12.1 "/>
            <path class="noStroke" :fill="fill" d="M15,7.5h-1.3v10.4l0.1,0c0,0,0,0,0.1,0c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.4-0.5,0.4-0.9c0,0,0,0,0,0l1.1-7.9
	C16.2,8.1,15.7,7.5,15,7.5z"/>
            <path class="noStroke" :fill="fill" d="M16.2,5.5V5.2c0-0.6-0.5-1.1-1.1-1.1H6.9c-0.6,0-1.1,0.5-1.1,1.1v0.3c0,0.6,0.5,1.1,1.1,1.1h8.2
	C15.7,6.6,16.2,6.1,16.2,5.5z"/> -->
        </g>
        <!-- :class='[{deleteHover: hover}]' class="button" -->
        <rect @click="$emit('delete-component')" :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'DeleteComponentButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;
            //console.log("hover - delete button");

            if(val){
                this.fill = "#006eee"
            } else {
                this.fill = "#999"
            }
        },
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true,        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(1.5, 1.5)`;
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999",
        }
    }
}
</script>

<style scoped>
rect {
    fill: rgba(255, 255,255, 0);
    cursor: pointer;
}
.background{
    fill: #fff;
}
.noStroke {
    stroke-width:0;
}
</style>