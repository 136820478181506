<template>
  <g v-if='workspace || inOut=="input"' v-on:mousedown="click" @mouseenter="setHover(true)" @mouseleave="setHover(false)" :transform="centerOffset">
    <!--  -4 -6 -4 6 5 0 -->
    <!--  0 -6 0 6 9 0 -->
    <polyline :transform="scalePolygon"
      points=" -5 6, 5 0, -5 -6"
      :class="[type, inOut, position, {hoverClass: selected}]"
      :x="x"
      :y="y"
      fill=none
    />
    <!-- <polygon :transform="scalePolygon"
      points="-5 -6 -5 6 5 0"
      :class="[type, inOut, position, {hoverClass: selected}]"
      
      :x="x"
      :y="y"
      
    /> -->
    <rect v-if="workspace" class="noStroke" :x="-size/2" :y="-size/2" :width='size' :height='size' ></rect>
  </g>
  <!-- @click="$emit('backward-component')" -->
  <!-- <rect :class='[type, inOut, position, {hoverClass: selected}]' :transform='centerOffset' :width='size' :height='size' :x='x' :y='y' :rx="radius" :ry="radius" v-on:mousedown='click'></rect> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { globalStore } from '../main.js';
import { EventBus } from "./event-bus.js";

export default {
  name: "IOConnectorSVG",
  props: {
    type: {
      type: String,
      required: true
    },
    inOut: {
      type: String,
      required: true
    },
    translate: {
      type: Object,
      default: function() {
        return { x: 0, y: 0 };
      }
    },
    position: {
      type: String,
      required: true
    },
    offset: {
      type: Number,
      required: true
    },
    componentId: {
      type: Number,
      required: true
    },
    index: {
        type: Number,
        required: true
    },
    workspace: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapGetters(["getConnectionPoint"]),
    y: function() {
      if (this.position == "top" || this.position == "bottom") {
        return 0;
      } else {
        return this.offset;
      }
    },
    x: function() {
      if (this.position == "top" || this.position == "bottom") {
        return this.offset;
      } else {
        return 0;
      }
    },
    centerOffset: function() {
      let rotation = 0;
      if (this.inOut == "output") {
        if (this.position == "top") {
          rotation = -90;
        } else if (this.position == "bottom") {
          rotation = 90;
        } else if (this.position == "left") {
          rotation = 180;
        }
      } else {
        if (this.position == "top") {
          rotation = 90;
        } else if (this.position == "bottom") {
          rotation = -90;
        } else if (this.position == "left") {
          rotation = 0;
        } else {
          rotation = 180;
        }
      }
      //console.log(`translate(${this.x}, ${this.y}) rotate(${rotation})`);
      return `translate(${this.x}, ${this.y}) rotate(${rotation})`;
    },
    radius: function() {
      if (this.inOut == "input") {
        return 6;
      } else {
        return 1;
      }
    }
  },
  data() {
    return {
      size: 28,
      selected: false,
      scalePolygon: "scale(1, 1)",
    };
  },
  methods: {
    ...mapActions(["setConnectionPoint", "addNewConnection"]),
    setHover(val){
            

            if(val){
                this.scalePolygon = "scale(1.5, 1.5)";
            } else {
              if(this.selected == false){
                this.scalePolygon = "scale(1, 1)";
              }
            }
    },
    click() {
      if(globalStore.fillMode == false){
        this.selected = !this.selected;

        // get connector no:
        var group = document.getElementById( "component_group_" + this.componentId);
        var connectorsArr = group.getElementsByClassName( this.inOut + " " + this.type);
        let no = 0;
        for (let i = 0; i < connectorsArr.length; i++) {
          //console.log(connectorsArr[i].id + " " + "connection_" + this.componentId + "_" + this.type + "_" + this.position + "_" + this.index);
          if ( connectorsArr[i].parentElement.id == "connection_" + this.componentId + "_" + this.type + "_" + this.position + "_" + this.index) {
            no = i;
            console.log("IOConnector NO: " + no);
          }
        }

        if (this.selected) {
          var conP = this.getConnectionPoint;
          if (
            this.inOut == "input" &&
            conP.out &&
            this.type == conP.type &&
            this.componentId != conP.out.id
          ) {
            //if(conP.out){
            // create new connection:
            let data = {
              type: this.type,
              out: {
                id: conP.out.id
              },
              in: {
                id: this.componentId,
                no: no
              }
            };

            if (conP.out.no) {
              data.out.no = conP.out.no;
            }
            // create Connection and reset Connection Point:
            //console.log("create-connection:");
            //console.log(data);
            this.$emit("create-connection", data);
            //}
          }
          // output:
          else if (
            this.inOut == "output" &&
            conP.in &&
            this.type == conP.type &&
            this.componentId != conP.in.id
          ) {
            if (conP.in) {
              // create new connection:
              let data = {
                type: this.type,
                out: {
                  id: this.componentId,
                  no: no
                },
                in: {
                  id: conP.in.id
                }
              };

              if (conP.in.no) {
                data.in.no = conP.in.no;
              }
              //console.log("create-connection:");
              //console.log(data);
              // create Connection and reset Connection Point:
              this.$emit("create-connection", data);
            }
          } else {
            let data = {
              type: this.type
            };

            

            if (this.inOut == "input") {
              data.in = {
                id: this.componentId,
                no: no
                // no: this.no     get this no ...
              };
            } else {
              data.out = {
                id: this.componentId,
                no: no
                // no: this.no
              };
            }

            EventBus.$emit("reset-connectors", this.componentId);
            //console.log("setConnectionPoint: ");
            //console.log(data);
            this.setConnectionPoint(data);
          }
        }
      }
    }
  },
  created() {
    EventBus.$on("reset-connectors", id => {
      if (this.componentId != id) {
        this.selected = false;
        this.setHover(false);
      }
    });
  }
};
</script>

<style scoped>
rect {
    fill: rgba(255, 255,255, 0);
    cursor: pointer;
}

.noStroke {
    stroke-width:0;
}

.bulk {
  /* fill: #226cee; */
  stroke-width: 1.5;
}
.air {
  /* fill: #c1e0ff; */
  stroke-width: 0.75;
  /* margin-left: 10px; */
}
.hoverClass {
  stroke: coral;
}
</style>