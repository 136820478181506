module.exports = {
    getTranslate: function(s){
        // var reg = new RegExp("-?\\d+")
         var reg = /-?[\d.]+/g
        return s.match(reg);
    }, 
    getGlobalConnectorPosition: function(connector){
        //console.log("connector");
        //console.log(connector);

        //console.log("getGlobalConnectorPosition: ");
        //console.log(connector);
        var pos = {
            x: connector.getAttribute('x'),
            y: connector.getAttribute('y')
        }
        //var transform = this.getTranslate(connector.getAttribute('transform'));
        var transformParentGroup = this.getTranslate(connector.parentElement.parentElement.getAttribute('transform'));
        var transformFromComponent = this.getTranslate(connector.parentElement.parentElement.parentElement.parentElement.getAttribute('transform'));

        return {
            //x: parseFloat(transform[0]) + parseFloat(transformParentGroup[0]) + parseFloat(transformFromComponent[0]),
            //y: parseFloat(transform[1]) + parseFloat(transformParentGroup[1]) + parseFloat(transformFromComponent[1]),
            x: parseFloat(pos.x) + parseFloat(transformParentGroup[0]) + parseFloat(transformFromComponent[0]),
            y: parseFloat(pos.y) + parseFloat(transformParentGroup[1]) + parseFloat(transformFromComponent[1]),
        }
    },
    getComponentBoundingBox: function(c_id){
        // console.log("component_group_" + c_id);
        var c = document.getElementById("component_group_" + c_id);
        let rect = document.getElementById("component_rect_" + c_id);

        if(c && rect){
            // console.log("found component group");
            
            let transformFromComponent = this.getTranslate(c.getAttribute('transform'));

            let width = parseFloat(rect.getAttribute('width'));
            let height = parseFloat(rect.getAttribute('height'));
            
            // console.log(transformFromComponent);
            // console.log((parseFloat(transformFromComponent[0]) - width/2) + " "  + (parseFloat(transformFromComponent[1]) - height/2)); 

            return {
                minX: parseFloat(transformFromComponent[0]) - width/2,
                minY: parseFloat(transformFromComponent[1]) - height/2,
                maxX: parseFloat(transformFromComponent[0]) + width/2,
                maxY: parseFloat(transformFromComponent[1]) + height/2
            }


        } else {
            return null;
        }
    },

    roundToRaster(val){
        let raster = 20;
        return Math.round(parseInt(val) / raster) * raster;
    },
}