<template>
    <g :transform="transform">
        <rect x=0 y=0 :width='size' :height='size' class='background'><!-- <i class="fas fa-trash-alt" /> --></rect>
        <g :transform="transformIcon">
            <rect class="noStroke" :fill="fill" x="-0.4" y="-6.8" width="0.8" height="0.5"/>
            <path class="noStroke" :fill="fill" d="M0.4,5.3h-0.8v-1h0.8V5.3z M0.4,3.3h-0.8v-1h0.8V3.3z M0.4,1.4h-0.8v-1h0.8V1.4z M0.4-0.5h-0.8v-1h0.8V-0.5z M0.4-2.4h-0.8
                v-1h0.8V-2.4z M0.4-4.4h-0.8v-1h0.8V-4.4z"/>
            <rect class="noStroke" :fill="fill" x="-0.4" y="6.3" width="0.8" height="0.5"/>
            <path class="noStroke" :fill="fill" d="M-5.6-4.3L-2.8,0l-2.8,4.3L-5.6-4.3 M-6.3-6.8V6.8L-1.9,0L-6.3-6.8z"/>
            <polygon class="noStroke" :fill="fill" points="1.9,0 6.3,-6.8 6.3,6.8"/>
        </g>
        <rect @click="$emit('mirror-component')" :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'MirrorButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;

            if(val){
                this.fill = "#006eee"
            } else {
                this.fill = "#999"
            }
        },
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true,        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(1.5, 1.5)`;
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999",
        }
    }
}
</script>

<style scoped>
rect {
    fill: rgba(255, 255,255, 0);
    cursor: pointer;
}
.background{
    fill: #fff;
}
.deleteHover {
    fill: red;
}
.noStroke {
    stroke-width:0;
}
</style>