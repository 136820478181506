<template>
    <div id="loginWindow">
        <div class="overlay">
            <h4 style="padding-bottom: 10px">Please login to use sketchbook</h4>
            <b-form-group  class="pb-2"><!-- label="user:" -->
                <!-- <label for="performanceRange">Performance</label> -->
                <label for="user">user: </label>
                <b-form-input 
                    style="display: inline"
                    type="text"
                    v-model="usr"
                    id="user" />
                    <br />
            <!-- </b-form-group>
            <b-form-group> -->
                <label for="pw">password: </label>
                <b-form-input 
                    style="display: inline"
                    type="password"
                    v-model="pw"
                    id="pw" />
                <p class="left25" style="color: #ff0000">{{msg}}</p>
                <b-button class="left25"
                  style="margin-top: 10px;"
                  href="#"
                  variant="primary"
                  @click="checkPW"
                  >login</b-button>
                
            </b-form-group>
        </div>
    </div>
</template>

<script>
    import passwordhash from "password-hash";

    export default {
        name: "Login",
        data() {
            return {
                usr: "testuser",
                pw: "",
                msg: "",
            }
        },
        methods: {
            checkPW: function(){
                //console.log('your hash: ' + passwordhash.generate('password'));                
                if(passwordhash.verify(this.pw, 'sha1$8460131a$1$96ee7e8e23fb6c84eea7d8c05d9f47810c97444a')){
                    this.$emit('login', {
                        usr: this.usr,
                        login: true
                    });
                } else{
                    this.msg = "Wrong username or password";
                }
            }
        }
    }

</script>

<style scoped>
    #loginWindow {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100vw;
        background-color: #006dee;
        z-index: 1000;
    }
    .overlay {
        position: relative;
        min-width: 300px;
        /* min-height: 150px; */
        background-color: #fff;
        /*border: #000 solid 1px;*/
        padding: 20px;
    }
    .left25 {
        margin-left: 25%;
    }
    .bv-no-focus-ring label {
        width: 25%;
    }
</style>