<template>
  <div id="window" style="position:relative;">
    <login v-if="login == false" v-on:login="setLogin">
    </login>
    <div id="startScreen" v-if="login" class="container-fluid">
      <div id="header" class="container-fluid">

        <b-navbar toggleable="lg" type="dark" variant="info">
          <b-navbar-brand href="#"><div class="align-text-top">
              <span class=" hl d-inline ">coperion</span><span class="hl-thin d-inline">Sketchbook</span>
            </div>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item href="#" @click="slideoutFilterSelection(!filterSelectionExpanded);">sketch settings 
                  <svg :class="[{ hide: !filterSelectionExpanded }, 'bi bi-caret-up-fill']" width="0.7em" height="0.7em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                  </svg>
                  <svg :class="[{ hide: filterSelectionExpanded }, 'bi bi-caret-down-fill']" width="0.7em" height="0.7em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
              </b-nav-item>
              <b-nav-item href="#" @click="slideoutFilterSelection(false); createFileImport()">import plant-file</b-nav-item>
              <b-nav-item href="#" @click="openReleaseNotesWindow()">show release notes</b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown v-if="filterSelectionExpanded == false" text="save" class="btn-light btn" style="padding: 0px; border: 0px; color: #212529;" right @click="slideoutFilterSelection(false);">
                <!-- <svg width="1em" height="1em" viewBox="0 0 20 20" style="position: relative; top: -2px" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M11.1,5.7V0.8H4c-0.5,0-0.9,0.4-0.9,0.9v16.6c0,0.5,0.4,0.9,0.9,0.9H16c0.5,0,0.9-0.4,0.9-0.9V6.6H12
	C11.5,6.6,11.1,6.2,11.1,5.7z M13.9,13.3l-3.5,3.4c-0.2,0.2-0.6,0.2-0.9,0l-3.5-3.4c-0.4-0.4-0.1-1,0.4-1h2.3V9.4
	c0-0.3,0.3-0.6,0.6-0.6h1.1c0.3,0,0.6,0.3,0.6,0.6v2.9h2.3C14,12.3,14.3,12.9,13.9,13.3L13.9,13.3z M16.6,4.6l-3.5-3.5
	c-0.2-0.2-0.4-0.3-0.6-0.3h-0.2v4.6h4.6V5.2C16.9,5,16.8,4.7,16.6,4.6z"/></svg> -->
                <dropdown-save-plant :user="this.user" :plantData="this.plantData" href="#">plant-file</dropdown-save-plant>
                <dropdown-save-png :plantName="this.plantData.plantTitle" v-on:activate-svg-canvas="setSvgCanvas" href="#">image (png)</dropdown-save-png>
                <dropdown-save-svg :plantName="this.plantData.plantTitle" v-on:activate-svg-canvas="setSvgCanvas" href="#">vector (svg)</dropdown-save-svg>
                <dropdown-save-pdf :plantName="this.plantData.plantTitle" v-on:activate-svg-canvas="setSvgCanvas" href="#">vector (pdf) (alpha)</dropdown-save-pdf>
                <dropdown-save-partlist href="#" :plantName="this.plantData.plantTitle">partlist</dropdown-save-partlist>
                <dropdown-save-module href="#">as module</dropdown-save-module>
              </b-nav-item-dropdown>
              
            
              <b-button v-if="filterSelectionExpanded == false" variant="light" href="#" @click="undoLastChange()">undo <svg width="1em" height="1em" viewBox="0 0 20 20" style="position: relative; top: -2px" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.8,17.7c0.1,0.9,0.8,1.6,1.8,1.6h9c0.9,0,1.7-0.7,1.8-1.6L17,5.3H3L3.8,17.7z M5.5,10.8l3.1-3c0.3-0.3,0.9-0.1,0.9,0.4
	v1.5c3,0,5.3,0.7,5.3,3.8c0,1.3-0.7,2.5-1.6,3.1c-0.3,0.2-0.6-0.1-0.5-0.4c0.8-3-0.2-3.7-3.2-3.8v1.6c0,0.5-0.5,0.7-0.9,0.4l-3.1-3
	C5.3,11.3,5.3,11,5.5,10.8C5.5,10.8,5.5,10.8,5.5,10.8L5.5,10.8z M17.6,1.8h-4.4l-0.3-0.7c-0.1-0.3-0.5-0.5-0.8-0.5H7.9
	c-0.3,0-0.6,0.2-0.8,0.5L6.8,1.8H2.4c-0.3,0-0.6,0.3-0.6,0.6v1.2c0,0.3,0.3,0.6,0.6,0.6h15.2c0.3,0,0.6-0.3,0.6-0.6V2.4
	C18.2,2.1,17.9,1.8,17.6,1.8z"/>
                </svg>
              </b-button>
              <b-nav-item-dropdown :text='"user: " + user' right class="userInputNoPadding">
                  <b-form-input 
                      style="display: inline;"
                      type="text"
                      v-model="user"
                      id="userName" />
              </b-nav-item-dropdown>
              <!-- <b-nav-item href="#">user: <span style="color: #fff">{{user}}</span></b-nav-item> -->
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <div id="filterSelection" class="row w-100" style="margin: 0 auto; height: 100vh">
          <div style="margin: 0 auto; padding-top: 10px; padding-bottom: 20px" class="col">
            <b-form-group label="choose your plant type" class="pb-2">
              <b-form-radio-group name="radio-sub-plantType" v-model="plantData.plantType">
                <b-form-radio
                  v-for="menuTitel in getMenu"
                  :key="menuTitel.title"
                  :value="menuTitel.link"
                  >{{ menuTitel.title }}</b-form-radio
                >
                <!-- <b-form-radio v-model="plantType" value="manufacturingPlant">Manufacturing Plant</b-form-radio>
                <b-form-radio v-model="plantType" value="compoundingPlant">Compounding Plant</b-form-radio>
                <b-form-radio v-model="plantType" value="recyclingPlant">Recycling Plant</b-form-radio> -->
              </b-form-radio-group>
            </b-form-group>

            <b-form-group label="product" class="pb-2">
              <b-form-radio-group name="radio-sub-mainProduct" v-model="plantData.mainProduct">
                <b-form-radio value="PP">PP</b-form-radio>
                <b-form-radio value="PE">PE</b-form-radio>
                <b-form-radio value="PVC">PVC</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group label="performance" class="pb-2">
              <!-- <label for="performanceRange">Performance</label> -->
              <input
                type="range"
                class="performanceRange slider"
                v-model="plantData.performance"
                min="0"
                max="5000"
                step="100"
                id="performanceRange"
              />
              <label for="materialsCountRange">{{ plantData.performance }} kg / h</label>
            </b-form-group>

            <b-form-group label="materials in recipe" class="pb-2">
              <!-- <label for="materialsCountRange">Example range</label> -->
              <input
                type="range"
                class="materialsPolymerCountRange slider"
                v-model="plantData.polymers"
                min="0"
                max="5"
                step="1"
                value="1"
                id="materialsPolymerCountRange"
              />
              <label for="materialsPolymerCountRange"
                >{{ plantData.polymers }} polymers</label
              ><br />
              <input
                type="range"
                class="materialsFillerCountRange slider"
                v-model="plantData.fillers"
                min="0"
                max="5"
                step="1"
                value="1"
                id="materialsFillerCountRange"
              />
              <label for="materialsFillerCountRange"
                >{{ plantData.fillers }} fillers</label
              ><br />
              <input
                type="range"
                class="materialsAdditivCountRange slider"
                v-model="plantData.additives"
                min="0"
                max="5"
                step="1"
                value="0"
                id="materialsAdditivCountRange"
              />
              <label for="materialsAdditivCountRange"
                >{{ plantData.additives }} additives</label
              ><br />
              <input
                type="range"
                class="materialsLiquidAdditivCountRange slider"
                v-model="plantData.liquidAdditives"
                min="0"
                max="5"
                step="1"
                value="0"
                id="materialsLiquidAdditivCountRange"
              />
              <label for="materialsLiquidAdditivCountRange"
                >{{ plantData.liquidAdditives }} liquid additives</label
              ><br />
              <input
                type="range"
                class="materialsOthersCountRange slider"
                v-model="plantData.others"
                min="0"
                max="5"
                step="1"
                value="0"
                id="materialsOthersCountRange"
              />
              <label for="materialsOthersCountRange">{{ plantData.others }} others</label>
            </b-form-group>
            <b-form-group label="Plant Infos" class="pb-2">
              <b-form-textarea 
                style="width: 50%; display: inline; margin-right: 20px"
                rows="2"
                v-model="plantData.plantDescription"
                id="plantDescription" />
              <label for="plantDescription">plant description</label>
              <b-form-input 
                style="display: inline"
                type="text"
                v-model="plantData.plantTitle"
                id="plantTitle" />
              <label for="plantTitle">plant reference</label>
              <!-- <b-form-input 
                style="display: inline"
                type="text"
                v-model="tagsCsv"
                id="plantTags" />
              <label for="plantTags">tags (commar separated)</label> -->
            </b-form-group>

            <b-button variant="light" href="#" @click="setAsTemplate('empty'); slideoutFilterSelection(!filterSelectionExpanded);">create empty sketch</b-button>
          </div>
          <div
            v-if="plantData.plantType == 'compoundingPlant'"
            id="results"
            class="col overflow-auto"
          >
            <!-- <div> -->
                              <!-- :title="plant.infos.plantTitle" -->
              <b-card
                v-for="plant in getPlantTemplates"
                :key="plant.name"
                title=""
                :img-src="plant.image"
                img-alt="Plant"
                img-top
                tag="article"
                style="max-width: 20rem; display:inline-block; margin: 1rem"
                class="mb-2"
              >
                <b-card-text>
                    <div class="createdText ml-0 mr-0 mt-1 mb-3">created: <span v-if="plant.infos.created" style="font-weight: bold">{{ createdText(plant.infos.created) }}</span><br />by: <span v-if="plant.infos.editor" style="font-weight: bold">{{ plant.infos.editor }}</span></div>{{ plant.infos.plantDescription }}
                </b-card-text>
                <b-card-text v-if="plant.infos.mainProduct" class="mt-0 mb-0 pt-0 pb-0">
                    main product: <b>{{ plant.infos.mainProduct }}</b>
                </b-card-text>
                <b-card-text v-if="plant.infos.fillers > 0" class="mt-0 mb-0 pt-0 pb-0">
                    fillers: <b>{{ plant.infos.fillers }}</b>
                </b-card-text>
                <b-card-text v-if="plant.infos.additives > 0" class="mt-0 mb-0 pt-0 pb-0">
                    additives: <b>{{ plant.infos.additives }}</b>
                </b-card-text>
                <b-card-text v-if="plant.infos.liquidAdditives > 0" class="mt-0 mb-0 pt-0 pb-0">
                    liquidAdditives: <b>{{ plant.infos.liquidAdditives }}</b>
                </b-card-text>
                <b-card-text v-if="plant.infos.others > 0" class="mt-0 mb-0 pt-0 pb-0">
                    others: <b>{{ plant.infos.others }}</b>
                </b-card-text>
                <b-card-text v-if="plant.infos.performance > 0" class="mt-0 mb-3 pt-0 pb-0">
                    performance: <b>{{ plant.infos.performance }} kg/h</b>
                </b-card-text>
                <b-card-text>
                    <span v-for="tag in plant.infos.tags" :key="tag" class="tag">{{
                      tag
                    }}</span>
                </b-card-text>

                <b-button
                  style="color: 'red'  !important"
                  href="#"
                  variant="primary"
                  @click="
                    setAsTemplate(plant.infos.plantTitle);
                    waitForTemplateLoaded(false);
                  "
                  >Select as template</b-button
                >
              </b-card>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="threeInitialized && login" v-bind:class="{ hide: !threeEnabled }">
      <div class="col-1 d-flex flex-column justify-content-center float-right"><!-- style="position: relative, width: 100px; max-width: 100px; flex: 0 0 100px; -webkit-box-flex: 0; -ms-flex: 0 0 100px;" -->
          <b-button @click="threeEnabled = false;">close</b-button>
      </div>
      <keep-alive>
        <three-scene></three-scene>
      </keep-alive>
    </div>
    <!-- <keep-alive> -->
    <div id="workspace" style="height: 100%" v-bind:class="[{ hide: threeEnabled }]">
      <div id="svg_container">
        <svg 
          id="svg_canvas"
          :width="canvasWidth"
          :height="canvasHeight"
          :style="
            'position: absolute;transform-origin: top left; left: 0px; top: 0px; transform: scale(' +
              getScalefactor() +
              ');'
          "
        >
          <g id="modules"></g>
          <g id="connections"></g>
          <plant-component-svg
            v-on:delete-component-id="deleteThisComponent"
            v-on:duplicate-component-id="copyComponent"
            v-on:mirror-component-id="mirrorComponent"
            v-on:rotate-component-id="rotateComponent"
            v-on:backward-component-id="backwardComponent"
            v-for="i in getPlantComponents"
            :key="i.id"
            :compValues="i"
          ></plant-component-svg>
          <!-- :input="i.input" :output="i.output" -->
        </svg>
      </div>

      <!-- left side menu -->
      <menu-overlay-bs
          v-if="login"
          style="float:left; "
          :plantTypeMenu="plantData.plantType"
          v-on:create-new-component="createComponent"
          v-on:create-new-module="createModule"
        ></menu-overlay-bs>
      <!-- end left side menu -->
      <b-container fluid>
        <!-- start center menu -->
        <b-row style="margin-top:1rem">
          <b-col offset="0" offset-xl="3" cols="9" xl="6" class="justify-content-center" style="pointer-events: none;" ><!-- col-2 col-lg-1 d-flex flex-column  -->
              <b-form-group label="" v-slot="{ ariaDescribedby }" style="pointer-events: auto;">
                <b-button id="newModuleButton" :pressed.sync="newModule" @click="setNewModule()" style="margin-right: 1rem">
                  <span>+ module</span>
                  <!-- <svg width="24" height="18">
                    <g transform="scale(0.9,0.9)">
                      <path fill="#fff" d="M30.7,20h-20V0h20V20z M11.7,19h18V1h-18V19z"/>
<path fill="#fff" d="M22,17.7h-9V9h9C22,9,22,17.7,22,17.7z M14,16.7h7V10h-7V16.7z"/>
<path fill="#fff" d="M19.7,6.5H13V2h6.7V6.5z M14,5.5h4.7V3H14V5.5z"/>
<path fill="#fff" d="M28.2,13.7h-5V4.5h4.9v9.2H28.2z M24.2,12.7h2.9V5.5h-2.9V12.7z"/>
<polygon fill="#fff" points="9.1,9.5 5.1,9.5 5.1,5.5 4.1,5.5 4.1,9.5 0.2,9.5 0.2,10.5 4.1,10.5 4.1,14.5 5.1,14.5 5.1,10.5 
	9.1,10.5 "/>
                    </g>
                  </svg>-->
                </b-button>
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="quotationAssignment"
                  :options="assignmentOptions"
                  :aria-describedby="ariaDescribedby"
                  name="radios-btn-default"
                  buttons
                  @change.native="setAssignment()"
                  style="display: inline"
                ></b-form-radio-group>
                <b-button id="fillModeButton" :pressed.sync="fillMode" @click="setFillMode()" style="margin-left: 0.3rem">
                  <svg width="20" height="18">
                    <g transform="scale(0.033, 0.033)">
                    <path fill="currentColor" d="M512 320s-64 92.65-64 128c0 35.35 28.66 64 64 64s64-28.65 64-64-64-128-64-128zm-9.37-102.94L294.94 9.37C288.69 3.12 280.5 0 272.31 0s-16.38 3.12-22.62 9.37l-81.58 81.58L81.93 4.76c-6.25-6.25-16.38-6.25-22.62 0L36.69 27.38c-6.24 6.25-6.24 16.38 0 22.62l86.19 86.18-94.76 94.76c-37.49 37.48-37.49 98.26 0 135.75l117.19 117.19c18.74 18.74 43.31 28.12 67.87 28.12 24.57 0 49.13-9.37 67.87-28.12l221.57-221.57c12.5-12.5 12.5-32.75.01-45.25zm-116.22 70.97H65.93c1.36-3.84 3.57-7.98 7.43-11.83l13.15-13.15 81.61-81.61 58.6 58.6c12.49 12.49 32.75 12.49 45.24 0s12.49-32.75 0-45.24l-58.6-58.6 58.95-58.95 162.44 162.44-48.34 48.34z"></path>
                    </g>
                  </svg>
                </b-button>
              </b-form-group>
              
          </b-col>
          <!-- end center menu -->
          <!-- start right side menu -->
          <b-col offset="1" offset-xl="2"  cols="2" xl="1" class="text-center d-flex flex-column" style="height: calc(100vh - 120px); pointer-events: none;" > <!-- col-2 col-lg-1 d-flex flex-column -->
              <b-row>
                <b-button
                style="pointer-events: auto;"
                @click="threeEnabled = true;
                    threeInitialized = true;">3d view
                </b-button>
              </b-row>
              <b-row  style="margin: auto auto 0px auto; background-color: rgb(255 255 255 / 60%); border: solid 1px #ccc; width: 50px; padding: 5px; border-radius: 5px; pointer-events: auto;">
                <b-form-group class="mb-0"  style="margin: auto;">
                  <b-button
                      variant="secondary"
                      style="width: 32px; margin: 2px auto"
                      @click="addScalefactor(0.04)"
                    >+</b-button>
                </b-form-group>
                <b-form-group class="mb-0" style="margin: auto;">
                    <input
                          type="range"
                          class="slider"
                          style="margin: 10px auto"
                          id="scalefactor_range" 
                          v-model="scalefactor"
                          orient="vertical" step="0.02"  min="0.26" max="1.2"
                          @input="setScalefactor(scalefactor)"
                    />
                </b-form-group>
                <b-form-group  style="margin: auto;">
                    <b-button
                      variant="secondary"
                      style="width: 32px; margin: 2px auto"
                      @click="addScalefactor(-0.04)"
                    >-</b-button>
                </b-form-group>
                <b-form-group class="mb-0" style="margin: auto;">
                    <!-- make plant centerd fullscreen -->
                    <button 
                      class="btn-secondary"
                      style="width: 32px; height: 32px; margin: 2px auto"
                      @click="zoomAndScaleFullscreen()"
                    ><svg width="20px" height="32px">
                      <g transform="translate(10, 14)">
                        <path fill="#fff" d="M0.7-0.7h3.8v-2.5c0-0.4,0.4-0.5,0.7-0.3l3.2,3.2c0.2,0.2,0.2,0.4,0,0.6L5.1,3.4C4.9,3.7,4.5,3.5,4.5,3.2V0.7H0.7v3.8h2.5
        c0.4,0,0.5,0.4,0.3,0.7L0.3,8.3c-0.2,0.2-0.4,0.2-0.6,0l-3.2-3.2c-0.2-0.2-0.1-0.7,0.3-0.7h2.5V0.7h-3.8v2.5c0,0.4-0.4,0.5-0.7,0.3
        l-3.2-3.2c-0.2-0.2-0.2-0.4,0-0.6l3.2-3.2c0.2-0.2,0.7-0.1,0.7,0.3v2.5h3.8v-3.8h-2.5c-0.4,0-0.5-0.4-0.3-0.7l3.2-3.2
        c0.2-0.2,0.4-0.2,0.6,0l3.2,3.2c0.2,0.2,0.1,0.7-0.3,0.7H0.7V-0.7L0.7-0.7z"/>
                      </g>
                    </svg></button>
                </b-form-group>
              </b-row>
          </b-col>
        </b-row>
        
        <!-- end right side menu -->
      </b-container>
    </div>
    <!-- </keep-alive> -->
    <svg id="svgForExport">
      <g id="water-mark" v-if="saveSvgCanvas" :transform="exportCenter()">
            <water-mark :svgFile="this.watermark" svgName="logo" :x='0' :y='0' transform='' :scale="waterMarkScale()" :width="400" :height="80"></water-mark>
      </g>
      <g id="offsetGroup" v-if="saveSvgCanvas">
        <g id="svg_connections">
          <connection-svg v-for="c in getPlantConnections" :key="'con' + c.id" :workspace="false" :in="c.in" :out="c.out" :type="c.type" :id="c.id" :assignment="c.assignment ? c.assignment : null" :handles="c.handles ? c.handles : null" :infos="c.infos ? c.infos : null"></connection-svg>
        </g>


        <plant-component-svg
              v-for="i in getPlantComponents"
              :key="i.id"
              :compValues="i"
              :workspace="false"
            ></plant-component-svg>
      </g>
    </svg>
    <!-- <iframe id="PDFIframe"></iframe> -->
  </div>
</template>

<script>
import Vue from "vue";
import moment from 'moment';
import PlantComponentSVG from "./components/PlantComponentSVG";
import ConnectionSVG from "./components/ConnectionSVG";
import ThreeScene from "./components/ThreeScene";
import ModuleContainerSVG from "./components/ModuleContainerSVG";
import MenuOverlayBS from "./components/MenuOverlayBS";
import posHelper from './components/PositionHelperFunctions';
import DropdownSavePlant from './components/Dropdown_SavePlant';
import DropdownSaveSVG from './components/Dropdown_SaveSvg';
import DropdownSavePDF from './components/Dropdown_SavePDF';
import DropdownSavePNG from './components/Dropdown_SavePng';
import DropdownSaveModule from './components/Dropdown_SaveModule';
import DropdownSavePartlist from './components/Dropdown_SavePartlist';
import WaterMark from './components/WaterMark';
import ReleaseNotesWindow from './components/ReleaseNotesWindow';
import Login from './components/Login';
// import MenuOverlay from './components/MenuOverlay'
// import ToggleSwitch from './components/ToggleSwitch'
// import RangeSlider from "./components/RangeSlider";
import { gsap } from "gsap";
import cloneDeep from "lodash.clonedeep";

import { EventBus } from "./components/event-bus";
import { globalStore } from "./main.js";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
//import blobStream from "blob-stream";
//const PDFDocument = require( "pdfkit");

//import posHelper from './components/PositionHelperFunctions'

export default {
  name: "App",
  components: {
    //'plant-component': PlantComponent,data
    "plant-component-svg": PlantComponentSVG,
    "connection-svg": ConnectionSVG,
    "three-scene": ThreeScene,
    // 'save-svg': SaveSVG,
    // 'menu-overlay': MenuOverlay,
    "menu-overlay-bs": MenuOverlayBS,
    // 'toggle-switch': ToggleSwitch,
    // "range-slider": RangeSlider,
    // 'connection': Connection,
    "dropdown-save-plant": DropdownSavePlant,
    "dropdown-save-svg": DropdownSaveSVG,
    "dropdown-save-pdf": DropdownSavePDF,
    "dropdown-save-png": DropdownSavePNG,
    "dropdown-save-module": DropdownSaveModule,
    "dropdown-save-partlist": DropdownSavePartlist,    
    "water-mark": WaterMark,
    "login": Login,
  },
  computed: {
    ...mapGetters([
      "allComponentTypes",
      "getPlantComponents",
      "getPlantConnections",
      "getPlantInfos",
      "getPlantModules",
      "getComponentId",
      "getConnectionId",
      "getModuleId",
      "allModuleTypes",
      "getMenu",
      "getPlantTemplates",
      "getLastBackup",
      "getReleaseNotes",
    ]),
  },
  data() {
    return {
      login: false,
      live: true,
      scalefactor: 1.0,
      canvasWidth: 8000,
      canvasHeight: 5000,
      user: "coperion",
      plantData: {
        plantTitle: "",
        plantDescription: "",
        plantType: "compoundingPlant",
        mainProduct: "PP",
        performance: 0,
        polymers: 1,
        fillers: 0,
        additives: 0,
        liquidAdditives: 0,
        others: 0,
        tags: [],
      },
      quotationAssignment: 'coperion',
      assignmentOptions: [
          { text: 'existing', value: 'existing' },
          { text: 'coperion', value: 'coperion' },
          { text: 'other', value: 'other' },
          { text: 'future', value: 'future' }
      ],
      fillMode: false,
      newModule: false,
      tagsCsv: "",
      threeEnabled: false,
      threeInitialized: false,
      filterSelectionExpanded: true,
      saveSvgCanvas: false,
      watermark: require("./assets/coperion_logo.svg"),
      drag: {x:0, y:0},
    };
  },
  mounted() {
    
    document.addEventListener("keydown", (event) => {
      //console.log("keydonw");
        // If Control or Command key is pressed and the S key is pressed
        // run save function. 83 is the key code for S.
      if((event.ctrlKey || event.metaKey) && (event.key == 'z' || event.key == 'Z')) {
            // Save Function
            event.preventDefault();
            //console.log("undo!!!");
            this.undoLastChange();
            return false;
        }
    });


    const svg_container = document.getElementById("svg_container");
    svg_container.addEventListener("mousedown", (e) => {
      if (e && (e.which == 2 || e.button == 4 )) {
        //console.log('middledown');

        this.drag.x = e.clientX;
        this.drag.y = e.clientY;

        document.onmouseup = this.closeDragElement;
        document.onmousemove = this.dragElement;
      }
    });

    // createModulesGroup delayed:
    this.createModuleGroups();

    // create Connections delayed:
    this.createConnections();

    //const svg_container = document.getElementById("svg_container");
    //console.log(svg_container);
    //console.log(svg_container.childNodes[0]);
    let svg_containerStyle = window.getComputedStyle(svg_container);
    let eleStyle = window.getComputedStyle(svg_container.childNodes[0]);
    //console.log(eleStyle);
    //console.log("eleStyle: " + eleStyle.width + ", " + eleStyle.height);

    //console.log("eleStyle: " + eleStyle.getPropertyValue('width') +  ", " + eleStyle.getPropertyValue('height'));
    //console.log(eleStyle);
    svg_container.scroll(
      parseFloat(eleStyle.width) / 2 - parseFloat(svg_containerStyle.width) / 2,
      parseFloat(eleStyle.height) / 2 -
        parseFloat(svg_containerStyle.height) / 2
    );

    this.setIDCounter();
  },
  methods: {
    ...mapActions([
      "addNewComponent",
      "setAsTemplate",
      "setInputAsPlant",
      "updateComponentOffset",
      "updateComponentAttributes",
      "addNewConnection",
      "updateConnection",
      "updateConnectionValues",
      "updateConnectionAssignment",
      "deleteComponent",
      "mirrorComponent",
      "rotateComponent",
      "backwardComponent",
      "removeConnectionToComponent",
      "removeConnectionFromState",
      "updateComponentId",
      "updateConnectionId",
      "updateModuleId",
      "addNewModule",
      "removeFromModules",
      "setIDCounter",
      "removeLastBackup",
    ]),

    setLogin: function(val){
      if(val.login){
        this.user = val.usr;
        this.login = true;

        if(val.usr != 'mm'){
          this.live = true;
        }

        if(this.live){
            axios.get('https://sketchbookserver.manuelmichel.de/tracking').then(response => {
                console.log("tracked user: " + response); 
            }).catch(error => console.log("tracking not availible " + error));
          }
      }
    },

    closeDragElement: function(){
      //console.log("closeDrag");

      document.onmouseup = null;
      document.onmousemove = null;
    },
    dragElement: function(e){
      //console.log("drag");
      const svg_container = document.getElementById("svg_container");

      //this.drag.x = svg_container.scrollLeft;
      //this.drag.y = 

      svg_container.scroll(
          svg_container.scrollLeft - (e.clientX - this.drag.x),
          svg_container.scrollTop - (e.clientY - this.drag.y)
          //this.canvasWidth * this.scalefactor * xRelation - window.innerWidth/2, 
          //this.canvasHeight * this.scalefactor * yRelation - window.innerHeight/2
      );

      this.drag.x = e.clientX;
      this.drag.y = e.clientY;



    },

    /*createPDF: function(){
      let iframe = document.getElementById("PDFIframe");
      console.log(iframe);
      // create a document and pipe to a blob
      var doc = new PDFDocument();
      console.log(doc);

      var stream = doc.pipe(blobStream());

      // draw some text
      doc.fontSize(25).text('Here is some vector graphics...', 100, 80);

      // some vector graphics
      doc
        .save()
        .moveTo(100, 150)
        .lineTo(100, 250)
        .lineTo(200, 250)
        .fill('#FF3300');

      doc.circle(280, 200, 50).fill('#6600FF');

      // an SVG path
      doc
        .scale(0.6)
        .translate(470, 130)
        .path('M 250,75 L 323,301 131,161 369,161 177,301 z')
        .fill('red', 'even-odd')
        .restore();

      // and some justified text wrapped into columns
      doc
        .text('And here is some wrapped text...', 100, 300)
        .font('Times-Roman', 13)
        .moveDown()
        .text("lorem impsum ......", {
          width: 412,
          align: 'justify',
          indent: 30,
          columns: 2,
          height: 300,
          ellipsis: true
        });

      // end and display the document in the iframe to the right
      doc.end();
      stream.on('finish', function() {
        iframe.src = stream.toBlobURL('application/pdf');
      });
    },*/

    setSvgCanvas: function(val){
      this.saveSvgCanvas = val;
    },
    getScalefactor: function() {
      return globalStore.zoomfactor;
    },
    updateScalefactor: function(){

      //globalStore.zoomfactor = this.scalefactor;
    },
    setScalefactor: function(val) {
      const svg_container = document.getElementById("svg_container");
      
      
      let centerX = svg_container.scrollLeft + window.innerWidth/2;
      let centerY = svg_container.scrollTop + window.innerHeight/2;
      //let centerX = svg_container.scrollLeft;
      //let centerY = svg_container.scrollTop;

      let xRelation = centerX/ svg_container.scrollWidth;
      let yRelation = centerY/ svg_container.scrollHeight;

      console.log(xRelation + " " + yRelation);
      console.log("svg_container.scrollWidth: " + svg_container.scrollWidth);


      this.scalefactor = val;
      globalStore.zoomfactor = this.scalefactor;

      svg_container.scroll(
          this.canvasWidth * this.scalefactor * xRelation - window.innerWidth/2, 
          this.canvasHeight * this.scalefactor * yRelation - window.innerHeight/2
      );
    },
    addScalefactor: function(change) {
      //console.log("scalefactor: " + this.scalefactor);
      //this.scalefactor = parseFloat(this.scalefactor) + parseFloat(change);
      this.setScalefactor(parseFloat(this.scalefactor) + parseFloat(change));
    },
    setAssignment: function(){
      console.log("set global store assignment: " + this.quotationAssignment);
      globalStore.assignment = this.quotationAssignment;
    },
    setFillMode: function(){
      console.log("this.fillMode:" + this.fillMode);
      // stop ModuleEditMode:
      if(this.fillMode && globalStore.editModuleID != -1){
        globalStore.editModuleID = -1;
        EventBus.$emit("reset-edit-module", this.moduleId);          
      }

      console.log("set global store fillMode: " + this.fillMode);
      globalStore.fillMode = this.fillMode;
    },

    setNewModule: function(){
      console.log("newModule: " + this.newModule);
      if(this.newModule == true){
        this.createModule('new');
      } else{
        // stop edit mode:
        EventBus.$emit("reset-edit-module", -1);
        globalStore.editModuleID = -1;
      }
      
    },
    zoomAndScaleFullscreen: function(){
      console.log("zoomAndScaleFullscreen");

      const plantGroup = document.getElementById("plantGroup");
      if(plantGroup){
        const rectSize = plantGroup.getElementsByTagName("rect")[0];
        //console.log("width: " + rectSize.getAttribute("width"));
        //console.log("height: " + rectSize.getAttribute("height"));
        //console.log("x: " + plantGroup.transform.animVal[0].matrix.e);
        //console.log("y: " + plantGroup.transform.animVal[0].matrix.f);
        //console.log("windows: " + window.innerWidth + " " + window.innerHeight);

        const svg_container = document.getElementById("svg_container");
        //let svg_containerStyle = window.getComputedStyle(svg_container);

        let zoomW = window.innerWidth / rectSize.getAttribute("width");
        let zoomH = (window.innerHeight - 65) / rectSize.getAttribute("height");

        console.log("zoom: " + zoomW + " " + zoomH);
        let scale = Math.min(zoomW, zoomH, 1.2); // max 1.2
        scale = Math.max(scale, 0.22); // minium 0.26
        this.setScalefactor(scale);

        // scroll to center top corner
        setTimeout(()=> {
          //let scrollX = (plantGroup.transform.animVal[0].matrix.e) + this.scalefactor;
          // oben mittig:
          let scrollX = (plantGroup.transform.animVal[0].matrix.e + rectSize.getAttribute("width")/2) * this.scalefactor - window.innerWidth/2;
          //console.log(scrollX);
          let scrollY = plantGroup.transform.animVal[0].matrix.f * this.scalefactor;
          //console.log(scrollX + " " + scrollY);
          svg_container.scroll(
            scrollX,
            scrollY
          );
        }, 50);
      }
    },
    copyComponent: function(compId) {
      let xOffset = 50;
      let yOffset = -50
      let newId = -1;

      const index = this.getPlantComponents.findIndex(
        (comp) => comp.id == compId
      );

      if (index !== -1) {
        let nextId = this.getComponentId + 1; // ++this.currentComponentId;
        let thisId = nextId;
        
        // create copy and set new ID and offset:
        let copy = cloneDeep(this.getPlantComponents[index]);
        copy.id = nextId;
        copy.assignment = globalStore.assignment;
        copy.position.left = copy.position.left + xOffset;
        copy.position.top = copy.position.top + yOffset;

        this.addNewComponent(copy);
        this.updateComponentId(nextId);

        return thisId;
      }

      return newId;
    },
    createComponent: function(
      type,
      left = 0,
      top = 0,
      mirror = false,
      rotate = 0,
      backward = false, 
      noBackup = false,
    ) {
      let nextId = this.getComponentId + 1; // ++this.currentComponentId;
      let thisId = nextId;

      // offset to center of scrollable SVG Panel
      const svg_container = document.getElementById("svg_container");
      let svg_containerStyle = window.getComputedStyle(svg_container);

      left +=
        svg_container.scrollLeft + parseFloat(svg_containerStyle.width) / 2;
      top +=
        svg_container.scrollTop + parseFloat(svg_containerStyle.height) / 3;

      //console.log( "new componente - mirror: " + mirror + " rotate: " + rotate);

      
              


      this.addNewComponent({
        name: type,
        id: nextId,
        assignment: this.quotationAssignment,
        position: {
          top: posHelper.roundToRaster(top / this.scalefactor),
          left: posHelper.roundToRaster(left / this.scalefactor),
          mirror: mirror,
          rotate: rotate,
          backward: backward,
        },
        noBackup: noBackup,
      });
      this.updateComponentId(nextId);

      return thisId;
    },
    createModule: function(moduleName) {
      if (this.allModuleTypes[moduleName]) {
        console.log("create new module: " + moduleName);
        console.log(this.allModuleTypes[moduleName]);
        let module = this.allModuleTypes[moduleName];

        // key ist module.komponenten.index -> Komponenten hat neue Id.
        let idParing = {};

        if (module.components) {
          // create module components:
          for (let i = 0; i < module.components.length; i++) {
            // console.log(module.components[i].name);
            // console.log(module.components[i].position.left);
            // console.log(module.components[i].position.top);
            //console.log("create Modul - module.components[i].position.mirror: " + module.components[i].position.mirror + " module.components[i].position.rotate: " + module.components[i].position.rotate);


            idParing[i] = this.createComponent(
              module.components[i].name,
              module.components[i].position.left * this.scalefactor,
              module.components[i].position.top * this.scalefactor,
              module.components[i].position.mirror,
              module.components[i].position.rotate,
              module.components[i].backward,
              true,
            );
          }
        }

        if (module.connections) {
          // console.log("connections:");
          for (let i = 0; i < module.connections.length; i++) {
            // infos in connections:
            // {
            //   in: {
            //     id: 9
            //   },
            //   out: {
            //     id: 4,
            //     no: 1
            //   },
            //   type: "bulk"
            // }

            console.log("module.connections[i].in and out:");
            console.log(module.connections[i].in.id);
            console.log(module.connections[i].out.id);
            // console.log(module.connections[i].type);
            //this.createNewConnection(idParing[module.connections[i]], module.connections[i].type);
            this.createNewConnection(
              idParing[module.connections[i].out.id],
              idParing[module.connections[i].in.id],
              module.connections[i].type,
              module.connections[i].out.no,
              module.connections[i].in.no,
              module.connections[i].handles,
              true,
            );
          }
        }

        let idArray = [];

        for (let val in idParing) {
          idArray.push(idParing[val]);
        }

        if (idArray.length > 0) {
          let modID = this.getModuleId + 1;

          this.addNewModule({ id: modID, compIds: idArray });
          this.updateModuleId(modID);
        }
      } else {
        // create new module
        let modID = this.getModuleId + 1;

        this.addNewModule({ id: modID, compIds: [] });
        this.updateModuleId(modID);
        
        // set new module in edit mode:
        EventBus.$emit("reset-edit-module", modID);
        globalStore.editModuleID = modID;
      }
    },
    removeConnections: function() {
      // var allConnctions = document.getElementsByClassName('box');
      // while(allConnctions[0]){
      //   allConnctions[0].parentNode.removeChild(allConnctions[0]);
      // }

      var allConnctions = document.getElementsByClassName("connection_group");
      while (allConnctions[0]) {
        allConnctions[0].parentNode.removeChild(allConnctions[0]);
      }
    },
    removeModuleGroups: function() {
      var allModules = document.getElementsByClassName("module");
      while (allModules[0]) {
        allModules[0].parentNode.removeChild(allModules[0]);
      }
    },
    createPlantGroup: function() {
      let arrayWithAllIDs = [];

      for (let i = 0; i < this.getPlantComponents.length; i++) {
        arrayWithAllIDs.push(this.getPlantComponents[i].id);
      }

      if (arrayWithAllIDs.length > 0) {
        //console.log()
        let svg = document.getElementById("modules");
        let ModuleContainerSVGClass = Vue.extend(ModuleContainerSVG);
        // module:
        let mContainer = new ModuleContainerSVGClass({
          propsData: {
            moduleId: -1,
            compIDs: arrayWithAllIDs,
            id: "plantGroup",
          },
        });
        mContainer.$mount();
        svg.prepend(mContainer.$el);
      } else {
        // deleteModuleFromList();
      }
    },
    createModuleGroups: function() {
      if (this.getPlantModules) {
        //console.log("this.getPlantModules");
        //console.log(this.getPlantModules.length);
        //console.log(this.getPlantModules);

        let svg = document.getElementById("modules");
        let ModuleContainerSVGClass = Vue.extend(ModuleContainerSVG);

        for (let i = 0; i < this.getPlantModules.length; i++) {
          //console.log("this.getPlantModules[" + i + "]");
          //console.log(this.getPlantModules[i]);
          if (this.getPlantModules[i]["compIds"].length > 0) {
            // module:
            let mContainer = new ModuleContainerSVGClass({
              propsData: {
                moduleId: this.getPlantModules[i].id,
                compIDs: this.getPlantModules[i].compIds,
              },
            });
            mContainer.$mount();
            //console.log("mountedContainer: " + i);
            svg.prepend(mContainer.$el);
          } else {
            // deleteModuleFromList();
          }

          //console.log("BBOX: " + minX + " " + minY + " " + maxX + " "  + maxY);
        }
      }
    },
    createConnections: function() {
      //console.log("App.vue/createConnections");
      if (this.getPlantConnections) {
        let svg = document.getElementById("connections");
        let ConnectionSVGClass = Vue.extend(ConnectionSVG);
        this.getPlantConnections.forEach((c) => {
          let con = new ConnectionSVGClass({
            /*propsData: {

            }*/
            propsData: {
              in: c.in,
              out: c.out,
              key: "con" + c.id,
              type: c.type,
              id: c.id,
              handles: c.handles ? c.handles : null,
              infos: c.infos ? c.infos : null,
              assignment: c.assignment ? c.assignment : null,
            },
          });
          con.$mount();
          // lowest layer
          svg.prepend(con.$el);
        });
      }
    },
    createNewConnection: function(out_id, in_id, type, out_no = 0, in_no = 0, handles = undefined, noBackup = false) {
      let conID = this.getConnectionId + 1;
      console.log("new ConnectionId: " + conID);
      // create new connection:
      //console.log(connection);

      let data = {
        id: conID,
        type: type,
        assignment: this.quotationAssignment,
        out: {
          id: out_id,
          no: out_no,
        },
        in: {
          id: in_id,
          no: in_no,
        },
        handles: handles,
        noBackup: noBackup,
      };

      console.log("connections data:");
      console.log(data);

      /*if(connection.out.no){
          data.out.no = connection.out.no;
        }
        if(connection.in.no){
          data.in.no = connection.out.no;
        }*/

      console.log(data);

      this.addNewConnection(data);
      this.updateConnectionId(conID);
    },
    deleteThisComponent: function(id) {
      // remove all connection to this component:
      /*this.getPlantConnections.forEach(c => {
          if(c.out.id == id || c.in.id == id){
            // remove connection

          }
        });*/
      let conData = {id: id};
      this.removeConnectionToComponent(conData);
      this.removeFromModules(id);

      console.log("deleteComponent: " + id); // + " " + data.id);
      this.deleteComponent({id: id});
    },
    setNewPosition: function(val) {
      //console.log("received val form child: " + val);

      for (var i = 0; i < this.getPlantComponents.length; i++) {
        //console.log(this.components[i].id);
        //console.log(val.id);
        if (this.getPlantComponents[i].id == val.id) {
          //console.log("updated: " + this.components[i].name);
          this.getPlantComponents[i].position = val.position; //.position = val.position;
        }
      }
    },
    slideoutFilterSelection: function(expanded) {
      if (expanded) {
        gsap.to("#filterSelection", { height: "100vh", duration: 0.5 });
      } else {
        gsap.to("#filterSelection", { height: 0, duration: 0.2 });
      }
      this.filterSelectionExpanded = expanded;
    },
    undoLastChange: function(){
      const lastState = this.getLastBackup;
      //console.log(lastState);
      if(lastState){
        this.setInputAsPlant(lastState);
        // remove last backup
        this.removeLastBackup();
      }
      
    },
    createFileImport: function(){
      let input = document.createElement("input");
            input.type = 'file';
            input.accept = '.txt';
            input.click();

            input.addEventListener("change",  (e) => {
              //console.log(e);
              //console.log(e.target.files);

              let fr=new FileReader(); 
              fr.onload = () => { 
                  //document.getElementById('output').textContent=fr.result; 
                  //console.log("file output: ");
                  //console.log(fr.result);
                  try {
                    let data = JSON.parse(fr.result);
                    console.log({data});

                    if(data.components && data.connections){
                      // show plant
                      this.setInputAsPlant(data);

                      console.log("data.infos: ");
                      console.log(data.infos);

                      // set plant data:
                      this.plantData = data.infos;

                      /*
                      if(this.plantData.plantTitle == "new plant"){
                        this.plantData.plantTitle =  data.infos.plantTitle;
                      }
                      if(this.plantData.plantDescription.trim().length == 0){
                        this.plantData.plantDescription = data.infos.plantDescription;
                      } else if(this.plantData.plantDescription.includes(data.infos.plantDescription) == false){
                        this.plantData.plantDescription += " //-" + data.infos.plantDescription + "-//";
                      } else {
                        // don't change
                      }
                      
                      //this.plantType +=  "compoundingPlant";
                      //this.mainProduct += "PP";
                      if(this.plantData.performance == 0){
                        this.plantData.performance =  data.infos.performance;
                      }
                      this.plantData.polymers =  1;
                      if(this.plantData.fillers == 0){
                        this.plantData.fillers =  data.infos.fillers;
                      }
                      if(this.plantData.additives == 0){
                        this.plantData.additives =  data.infos.additives;
                      }
                      if(this.plantData.liquidAdditives == 0){
                        this.plantData.liquidAdditives =  data.infos.liquidAdditives;
                      }
                      if(this.plantData.others == 0){
                        this.plantData.others =  data.infos.others;
                      }
                      
                      //add tags:
                      this.plantData.tags = [...this.plantData.tags, ...data.infos.tags];
                      //this.tags.push();
                      */

                    } else {
                      alert("Input: No valid plant file");
                    }
                  } catch(error){
                    alert("Input: No valid plant file");
                  }

              } 
                
              fr.readAsText(e.target.files[0]); 

            });

          // <b-form-file id="file-selector" style="display:none" v-model="file2" class="mt-3 nav-link" plain @click="slideoutFilterSelection(false);"></b-form-file>

    },
    /*
    readTextFile: function(file, callback){

      var rawFile = new XMLHttpRequest();
      rawFile.overrideMimeType("application/json");
      rawFile.open("GET", file, true);
      rawFile.onreadystatechange = function() {
        console.log(rawFile);
          if (rawFile.readyState === 4 && rawFile.status == "200") {
              callback(rawFile.responseText);
          }
      }
      rawFile.send(null);
    },*/

    waitForTemplateLoaded: async function(expand) {
      await new Promise((resolve) => {

                      let infos = this.getPlantInfos;
                      
                      if(this.plantData.plantTitle == ""){
                        this.plantData.plantTitle = infos.plantTitle;
                      }
                      if(this.plantData.plantDescription.trim().length == 0){
                        this.plantData.plantDescription =infos.plantDescription;
                      } else if(this.plantData.plantDescription.includes(infos.plantDescription) == false){
                        this.plantData.plantDescription += " <- " +infos.plantDescription + " ->";
                      } /*else {
                        // don't change
                      }*/
                      
                      //this.plantType +=  "compoundingPlant";
                      //this.mainProduct += "PP";
                      if(this.plantData.performance == 0){
                        this.plantData.performance = infos.performance;
                      }
                      this.plantData.polymers =  1;
                      if(this.plantData.fillers == 0){
                        this.plantData.fillers = infos.fillers;
                      }
                      if(this.plantData.additives == 0){
                        this.plantData.additives = infos.additives;
                      }
                      if(this.plantData.liquidAdditives == 0){
                        this.plantData.liquidAdditives = infos.liquidAdditives;
                      }
                      if(this.plantData.others == 0){
                        this.plantData.others = infos.others;
                      }
                      
                      //add tags:
                      if(infos.tags){
                        this.plantData.tags = [...this.plantData.tags, ...infos.tags];
                      }
                      






        setTimeout(() => resolve(this.slideoutFilterSelection(expand)), 500);
      });
    },
    createdText: function(val){
      return moment(val).format("L");
    },
    exportCenter: function(){
            const offset = 100; // same as in Dropdown save svg / png!
            const plantGroup = document.getElementById("plantGroup");
            const rectSize = plantGroup.getElementsByTagName("rect")[0];
            
            // svg size from plantGroup + offset*2
            //svg.setAttribute("width", parseInt(rectSize.getAttribute("width")) + offset * 2);
            //svg.setAttribute("height", parseInt(rectSize.getAttribute("height")) + offset * 2);
            console.log("translate(" +  ((parseInt(rectSize.getAttribute("width")) + offset * 2)/2) + ", " + ((parseInt(rectSize.getAttribute("height")) + offset * 2) / 2 ) + ")");
            return "translate(" +  ((parseInt(rectSize.getAttribute("width")) + offset * 2)/2) + ", " + ((parseInt(rectSize.getAttribute("height")) + offset * 2) / 2 ) + ")";
    },
    waterMarkScale: function(){
            const plantGroup = document.getElementById("plantGroup");
            const rectSize = plantGroup.getElementsByTagName("rect")[0];

            return parseInt(rectSize.getAttribute("width"))/15;
    },
    openReleaseNotesWindow(){
                // create HTML Window
                var app = document.getElementById("svg_container").parentNode;

                var ReleaseNotesWindowClass = Vue.extend(ReleaseNotesWindow);

                    //console.log(options);
                    //console.log(versions);
                    //return "test";

                let win = new ReleaseNotesWindowClass({
                        propsData: {
                          releaseNotes: this.getReleaseNotes,
                            //componentName: this.compValues.name,
                            //componentId: this.compValues.id,
                            //lastText: this.compValues.text,
                            //componentVersion: this.compValues.version,
                            //allComponentTypes: this.allComponentTypes,
                            //options: options,
                            //versions: versions,
                        }
                });

                win.$mount();

                console.log(win.$el);
                app.appendChild(win.$el);
            },
  },

  watch: {
    getPlantConnections: {
      deep: true,
      handler() {
        setTimeout(() => {
          // unhover all connectors
          console.log("changed connections");
          this.removeConnections();
          this.createConnections();
        }, 180);
      },
    },
    getPlantComponents: {
      deep: true,
      handler() {
        setTimeout(() => {
          console.log("changed components");
          this.removeConnections();
          this.removeModuleGroups();

          this.createConnections();
          this.createModuleGroups();
          this.createPlantGroup();

          //this.setPlantValues();

        }, 100);
      },
    },
    getPlantModules: {
      deep: true,
      handler() {
        setTimeout(() => {
          console.log("changed components");
          this.removeModuleGroups();

          this.createModuleGroups();
          this.createPlantGroup();

          // wenn die neue Gruppe ein child hat:
          if(this.getPlantModules.length > 0 && this.getPlantModules[this.getPlantModules.length-1].compIds.length > 0){
            // deaktiviere "+ module" Button
            this.newModule = false;
          }
        }, 100);
      },
    },
  },
  created() {

    console.log("start component list:");
    console.log(this.allComponentTypes);
    for(const [key, obj] of Object.entries(this.allComponentTypes)){
      console.log(`${key}, ${obj.name}`);
    }
    console.log("end component list:");


    EventBus.$on("removeConnection", (id) => {
      this.removeConnectionFromState(id);
    });
    EventBus.$on("updateConnectionAssignment", (data) => {
      this.updateConnectionAssignment(data);
      setTimeout(() => {
          console.log("changed connections");
          this.removeConnections();
          this.createConnections();
      }, 50);
    }),
    EventBus.$on("updateConnection", (data) => {
      this.updateConnection(data);

      setTimeout(() => {
          console.log("changed connections");
          this.removeConnections();
          this.createConnections();
      }, 50);
    });
    EventBus.$on("updateConnectionValues", (data) => {
      this.updateConnectionValues(data);
      setTimeout(() => {
          console.log("changed connections");
          this.removeConnections();
          this.createConnections();
      }, 50);
    });
    EventBus.$on("changeComponent", (data) => {
      this.updateComponentAttributes(data);
    }),
    // from ModuleContainerSVG - closeDragElement()
    EventBus.$on("moveComponentSave", (data) => {
      this.updateComponentOffset(data);
    });
    EventBus.$on("deleteModule", (moduleId) => {
      // delete all componentes in module:

      for (let i = 0; i < this.getPlantModules.length; i++) {
        let mod = this.getPlantModules[i];

        if (mod.id == moduleId) {
          console.log("moduleID: " + moduleId);
          let first = true;
          for (let j = mod.compIds.length - 1; j >= 0; j--) {
            let compIdToDelete = mod.compIds[j];



            let conData = {
              id: compIdToDelete,
            }

            if(first){
              first = false;
            } else {
              conData.noBackup = true;
            }
            this.removeConnectionToComponent(conData);
            console.log("deleteComponent: " + compIdToDelete); // + " " + data.id);


            let data = {
              id: compIdToDelete,
              noBackup: true,
            }
            this.deleteComponent(data);
            //this.deleteComponent(compIdToDelete);
          }
        }
      }
    });
    EventBus.$on("duplicatModule", (moduleId) => {
      for (let i = 0; i < this.getPlantModules.length; i++) {
        let mod = this.getPlantModules[i];
        if (mod.id == moduleId) {
          // duplicate:
          let newCompIds = [];
          let idParing = {};

          for (let j = 0; j < mod.compIds.length; j++) {
            let compIdToCopy = mod.compIds[j];

            let newId = this.copyComponent(compIdToCopy);
            if (newId >= 0) {
              newCompIds.push(newId);

              // for connections:
              idParing[compIdToCopy] = newId;
            }
          }
          

          let conToCopy = [];
          // copy connections
          for (let j = 0; j < mod.compIds.length; j++) {
            // copy all connections:
            let _conToCopy = this.getPlantConnections.filter((con) => {
              if (con.out.id == mod.compIds[j] || con.in.id == mod.compIds[j]) {
                return con;
              }
            });

            

            for(let k = 0; k<_conToCopy.length; k++){
              // doppelte aussortieren:
              if(conToCopy.includes(_conToCopy[k]) == false){
                conToCopy.push(_conToCopy[k]);
              }
            }
          }

          console.log("conToCopy:");
          console.log(conToCopy);

          for (let k = 0; k < conToCopy.length; k++) {
            let index = this.getPlantConnections.findIndex((elem) => parseInt(elem.id) == parseInt(conToCopy[k].id));
            console.log(this.getPlantConnections[index]);
            if (index !== -1) {
              // check for intern link:
              if (mod.compIds.findIndex((elem) => parseInt(elem) == this.getPlantConnections[index].out.id) !== -1 && mod.compIds.findIndex((elem) => parseInt(elem) == this.getPlantConnections[index].in.id) !== -1) {
                let conID = this.getConnectionId + 1;

                // make connections of new components:
                let conData = {
                    id: conID,
                    type: this.getPlantConnections[index].type,
                    out: {
                      id: idParing[this.getPlantConnections[index].out.id],
                      no: this.getPlantConnections[index].out.no,
                    },
                    in: {
                      id: idParing[this.getPlantConnections[index].in.id],
                      no: this.getPlantConnections[index].in.no,
                    },
                };

                conData.assignment = globalStore.assignment;
                /*if(this.getPlantConnections[index].assignment){
                  conData.assignment = this.getPlantConnections[index].assignment;
                }*/

                // connection handles
                if(this.getPlantConnections[index].handles){
                    conData.handles = cloneDeep(this.getPlantConnections[index].handles);
                }

                conData.noBackup = true;

                this.addNewConnection(conData);
                this.updateConnectionId(conID);
              }

              // extern link:
              // Todo: Verbindungen zu anderen Bauteilen auch erzeugen?
            }
          }
          

          // create Module
          if (newCompIds.length > 0) {
            let modID = this.getModuleId + 1;
            this.addNewModule({ id: modID, compIds: newCompIds });
            this.updateModuleId(modID);
          }
        }
      }

      console.log("duplicate Module: " + moduleId);
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
@import "./scss/custom.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./node_modules/bootstrap-vue/src/index.scss";

#scalefactor_range {
  writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    width: 8px;
    height: 175px;
    // padding: 0 5px;
}

.bv-no-focus-ring input {
  margin-right: 20px;
  width: 50%;
}

legend {
  color: rgba(255, 255, 255, 0.5);
}

.card {
  color: #000;
}
#results .card-body {
  margin: 3px;
}

#fillModeButton.active, #newModuleButton.active {
  background-color: #006dee !important;
}
.btn-secondary:nth-child(1):not(.disabled).active, .show > .btn-secondary.dropdown-toggle
{
  background-color: rgb(173, 179, 184) !important;
}
.btn-secondary:nth-child(2):not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle
{
  //background-color: #006dee !important;
  background-color: rgb(0, 0, 0) !important;
}
.btn-secondary:nth-child(3):not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle
{
  //background-color: #006dee !important;
  background-color: rgb(2, 148, 61) !important;
}
.btn-secondary:nth-child(4):not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle
{
  //background-color: #006dee !important;
  background-color: rgb(211, 81, 0) !important;
}

/*.active > #btn-radios-1_BV_option_0{
  background-color: rgb(133, 133, 133);
}
.active > #btn-radios-1_BV_option_1 {
  background-color: rgb(0, 0, 0);
}
.active > #btn-radios-1_BV_option_2 {
  background-color: rgb(2, 148, 61);
}
.active > #btn-radios-1_BV_option_3 {
  background-color: rgb(211, 81, 0);
}*/
</style>
