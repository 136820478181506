<template>
    <b-dropdown-item href="#"  @click="savePartlist"><slot></slot></b-dropdown-item>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'SavePartlist',
    props: {
        plantName: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters(["getPlantComponents", "allComponentTypes"])
    },
    methods:{
        //...mapGetters([, "allComponentTypes"]),
        savePartlist(){
            console.log("components: ");
            console.log(JSON.stringify(this.getPlantComponents));

            let parts = {};
            let partsFuture = {};

            for(let i = 0; i<this.getPlantComponents.length; i++){
                let element = this.getPlantComponents[i];
                

                // exclude Textfields
                if(element.name.startsWith("textfield") == false){

                    let name = element.name;
                    if(element.version && element.version.length > 0){
                        name += "-v-" + element.version;
                    }
                    if(element.text && element.text.length > 0){
                        name += "-t-" + element.text;
                    }

                    if(!element.assignment || element.assignment == "coperion"){
                        if(parts[name]){
                            parts[name] = parts[name] + 1;
                        }else {
                            parts[name] = 1;
                        }
                    } else if(element.assignment == "future"){
                        if(partsFuture[name]){
                            partsFuture[name] = partsFuture[name] + 1;
                        }else {
                            partsFuture[name] = 1;
                        }
                    }
                }
            }

            

            //let partlist = JSON.stringify(parts);

            let partlist = "PARTLIST: \n";
            partlist += "count\tcomponent\tversion\tdescription\n";

            // coperion:
            for (const [key, value] of Object.entries(parts)) {
                //console.log(`${key}: ${value}`);
                
                let version = "";
                let text = "";
                let keyName = key;

                let _key = key.split("-v-");
                if(_key.length > 1){
                    
                    keyName = _key[0];

                    let _textKey = _key[1].split("-t-");
                    if(_textKey.length > 1){
                        text = _textKey[1];
                    } 
                    version = _textKey[0];
                }else {
                    let _textKey = key.split("-t-");
                    if(_textKey.length > 1){
                        keyName = _textKey[0];
                        text = _textKey[1];
                    } 
                }

                console.log(keyName);
                console.log(version);
                console.log(text);

                partlist += value + "\t" + this.allComponentTypes[keyName].name + "\t" + version + "\t" + text + "\n";
            }
            
            // future
            if(Object.entries(partsFuture).length > 0){
                partlist += "\n\nfuture parts:\n";
                for (const [key, value] of Object.entries(partsFuture)) {
                    //console.log(`${key}: ${value}`);
                    
                    let version = "";
                    let text = "";
                    let keyName = key;

                    let _key = key.split("-v-");
                    if(_key.length > 1){
                        
                        keyName = _key[0];

                        let _textKey = _key[1].split("-t-");
                        if(_textKey.length > 1){
                            text = _textKey[1];
                        } 
                        version = _textKey[0];
                    }else {
                        let _textKey = key.split("-t-");
                        if(_textKey.length > 1){
                            keyName = _textKey[0];
                            text = _textKey[1];
                        } 
                    }

                    console.log(keyName);
                    console.log(version);
                    console.log(text);

                    partlist += value + "\t" + this.allComponentTypes[keyName].name + "\t" + version + "\t" + text + "\n";
                }
            }



            console.log("Partlist:");
            console.log(partlist);
            
            this.download(partlist, this.plantName + '_partlist.txt', 'text/plain');
            
        },
        download(content, fileName, contentType) {
            let a = document.createElement("a");
            let file = new Blob([content], {type: contentType});
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
        }
    }
}
</script>