<template>
    <g :transform="transform">
        <rect x=0 y=0 :width='size' :height='size' class='background'><!-- <i class="fas fa-trash-alt" /> --></rect>
        <g :transform="transformIcon">
            <path class="noStroke" :fill="fill" d="M4.4-5.9C2.9-7.2,0.9-7.8-1.1-7.6c-1.8,0.2-3.6,1.1-4.8,2.5l-1.7-1l1,3.8l3.7-1l-1.7-1c1-1,2.3-1.6,3.7-1.8c1.6-0.1,3.1,0.3,4.4,1.4c1.2,1,2,2.5,2.1,4C5.6-0.3,6,0,6.3,0c0,0,0,0,0.1,0c0.4,0,0.7-0.4,0.7-0.8C6.9-2.8,6-4.6,4.4-5.9z"/>
            <path class="noStroke" :fill="fill" d="M7.7,5.2L6.3,1.6L2.7,3l1.8,0.8c-1.9,2.4-5.3,3.1-8,1.4c-1.6-1-2.6-2.7-2.7-4.6c0-0.4-0.4-0.7-0.8-0.7s-0.7,0.4-0.7,0.8
	C-7.5,3-6.3,5.2-4.3,6.5c1.3,0.8,2.7,1.2,4,1.2c2.4,0,4.7-1.1,6.1-3.2L7.7,5.2z"/>
         </g>
        <!-- :class='[{deleteHover: hover}]' class="button" -->
        <rect @click="$emit('rotate-component')" :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'RotateButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;

            if(val){
                this.fill = "#006eee"
            } else {
                this.fill = "#999"
            }
        },
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true,        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(1.5, 1.5)`;
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999",
        }
    }
}
</script>

<style scoped>
rect {
    fill: rgba(255, 255,255, 0);
    cursor: pointer;
}
.background{
    fill: #fff;
}
.noStroke {
    stroke-width:0;
}
</style>