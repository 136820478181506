<template>
    <b-dropdown-item href="#"  @click="savePlant()"><slot></slot></b-dropdown-item>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import cloneDeep from "lodash.clonedeep";

export default {
    name: 'SavePlant',
    props: {
        user: {
            type: String,
        },
        plantData: {
            type: Object,
            default: function () {
                return { title: "no title", plantType: "compoundingPlant" }
            }
        },
        
    },
    methods:{
        ...mapGetters(["getPlant"]),
        savePlant(){
            let plant = this.getPlant();
            
            /*let infos = {};
            infos.title = this.plantData.plantTitle,
            infos.text = this.plantData.plantDescription;
            infos.plantType = this.plantData.plantType,
            infos.mainProduct = this.plantData.mainProduct,
            infos.performance = this.plantData.performance,
            infos.polymers = this.plantData.polymers,
            infos.fillers = this.plantData.fillers,
            infos.additives = this.plantData.additives,
            infos.liquidAdditives = this.plantData.liquidAdditives,
            infos.others = this.plantData.others,
            */
            
            plant.infos = cloneDeep(this.plantData);
            plant.infos.editor = this.user,
            plant.infos.created = moment();
            console.log("save plant: ");
            console.log(JSON.stringify(this.getPlant()));
            
            let fileName = (plant.infos.plantTitle.length > 0 ? plant.infos.plantTitle : 'plant');

            this.download(JSON.stringify(this.getPlant()), fileName + '.txt', 'text/plain');
        },
        download(content, fileName, contentType) {
            let a = document.createElement("a");
            let file = new Blob([content], {type: contentType});
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
        }
        
    }
}
</script>