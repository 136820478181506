<template>
    <b-dropdown-item href="#"  @click="savePNG"><slot></slot></b-dropdown-item>
</template>

<script>
import { saveAs } from 'file-saver';
import { saveSvgAsPng } from 'save-svg-as-png'

export default {
    name: 'ExportSVG',
    props: {
        plantName: {
            type: String,
            required: true,
        }
    },
    methods:{
        /*saveSVG: function(){
            const offset = 100;
            const plantGroup = document.getElementById("plantGroup");
            //console.log("plantGroup.transform");
            //console.log(plantGroup.transform);

            const rectSize = plantGroup.getElementsByTagName("rect")[0];
            //console.log("rectSize");
            //console.log(rectSize);
            //const plantOffsetX = plantGroup.transform
            
            const svg = document.getElementById("svgForExport");
            // svg size from plantGroup + offset*2
            svg.setAttribute("width", parseInt(rectSize.getAttribute("width")) + offset * 2);
            svg.setAttribute("height", parseInt(rectSize.getAttribute("height")) + offset * 2);
            
            // activate svgCanvas:
            //this.saveSvgCanvas = true;

            this.$emit('activate-svg-canvas', true);

            //let promise = new Promise(function(resolve, reject) {



            const createSVGCanvas = () => {
                return new Promise((resolve, reject) => {

                setTimeout(function(){
                    let offsetGroup = svg.getElementById("offsetGroup");

                    if(offsetGroup){
                    //console.log("offsetGroup");
                    //console.log(offsetGroup);
                    //console.log(plantGroup.transform.animVal[0].matrix);
                    // set groupPosition from plantGroup + offset
                    let trans = "translate(" + (offset - plantGroup.transform.animVal[0].matrix.e) + ',' + (offset - plantGroup.transform.animVal[0].matrix.f) + ')';
                    //console.log(trans);
                    offsetGroup.setAttribute("transform", trans); 
                    resolve();
                    } else {
                    reject(new Error('no Offsetgroup available'));
                    }
                }, 700);
                });
            }

            
            const saveCanvas = () => {
                createSVGCanvas()
                .then(() => {
                    // saveFile
                    this.exportSVG(document.getElementById('svgForExport'));
                    console.log("save file");
                }, error => console.log(error.message))
                
                .then(() => {
                    console.log("disable svg canvas");
                    //this.saveSvgCanvas = false;
                    this.$emit('activate-svg-canvas', false);
                }); 
            }

            saveCanvas();
        },*/
        savePNG: function(){
            const offset = 100;
            const plantGroup = document.getElementById("plantGroup");
            //console.log("plantGroup.transform");
            //console.log(plantGroup.transform);

            const rectSize = plantGroup.getElementsByTagName("rect")[0];
            //console.log("rectSize");
            //console.log(rectSize);
            //const plantOffsetX = plantGroup.transform
            
            const svg = document.getElementById("svgForExport");
            // svg size from plantGroup + offset*2
            svg.setAttribute("width", parseInt(rectSize.getAttribute("width")) + offset * 2);
            svg.setAttribute("height", parseInt(rectSize.getAttribute("height")) + offset * 2);
            
            // activate svgCanvas:
            //this.saveSvgCanvas = true;

            this.$emit('activate-svg-canvas', true);

            //let promise = new Promise(function(resolve, reject) {



            const createSVGCanvas = () => {
                return new Promise((resolve, reject) => {

                setTimeout(function(){
                    let offsetGroup = svg.getElementById("offsetGroup");

                    if(offsetGroup){
                    //console.log("offsetGroup");
                    //console.log(offsetGroup);
                    //console.log(plantGroup.transform.animVal[0].matrix);
                    // set groupPosition from plantGroup + offset
                    let trans = "translate(" + (offset - plantGroup.transform.animVal[0].matrix.e) + ',' + (offset - plantGroup.transform.animVal[0].matrix.f) + ')';
                    //console.log(trans);
                    offsetGroup.setAttribute("transform", trans); 
                    resolve();
                    } else {
                    reject(new Error('no Offsetgroup available'));
                    }
                }, 700);
                });
            }

            
            const saveCanvas = () => {
                createSVGCanvas()
                .then(() => {
                    // saveFile
                    //saveSvgAsPng(document.getElementById("svg_canvas"), this.plantName + ".png", {scale: 1});
                    saveSvgAsPng(document.getElementById("svgForExport"), this.plantName + ".png", {scale: 1});
                    console.log("save file");
                }, error => console.log(error.message))
                
                .then(() => {
                    console.log("disable svg canvas");
                    //this.saveSvgCanvas = false;
                    this.$emit('activate-svg-canvas', false);
                }); 
            }

            saveCanvas();
        },
        exportSVG: function(svg) {
            // first create a clone of our svg node so we don't mess the original one
            var clone = svg.cloneNode(true);
            // parse the styles
            this.parseStyles(clone);

            // create a doctype
            var svgDocType = document.implementation.createDocumentType('svg', "-//W3C//DTD SVG 1.1//EN", "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd");
            // a fresh svg document
            var svgDoc = document.implementation.createDocument('http://www.w3.org/2000/svg', 'svg', svgDocType);
            // replace the documentElement with our clone 
            svgDoc.replaceChild(clone, svgDoc.documentElement);
            // get the data
            var svgData = (new XMLSerializer()).serializeToString(svgDoc);

            // now you've got your svg data, the following will depend on how you want to download it
            // here I'll use FileSaver.js (https://github.com/yrezgui/FileSaver.js)
            
            var blob = new Blob([svgData.replace(/></g, '>\n\r<')]);
            saveAs(blob, this.plantName + '.svg');
        },

        parseStyles: function(svg) {
            var styleSheets = [];
            var i;
            // get the stylesheets of the document (ownerDocument in case svg is in <iframe> or <object>)
            var docStyles = svg.ownerDocument.styleSheets;

            // transform the live StyleSheetList to an array to avoid endless loop
            for (i = 0; i < docStyles.length; i++) {
                styleSheets.push(docStyles[i]);
            }

            if (!styleSheets.length) {
                return;
            }

            var defs = svg.querySelector('defs') || document.createElementNS('http://www.w3.org/2000/svg', 'defs');
            if (!defs.parentNode) {
                svg.insertBefore(defs, svg.firstElementChild);
            }
            svg.matches = svg.matches || svg.webkitMatchesSelector || svg.mozMatchesSelector || svg.msMatchesSelector || svg.oMatchesSelector;


            // iterate through all document's stylesheets
            for (i = 0; i < styleSheets.length; i++) {
                var currentStyle = styleSheets[i]

                var rules;
                try {
                rules = currentStyle.cssRules;
                } catch (e) {
                continue;
                }
                // create a new style element
                var style = document.createElement('style');
                // some stylesheets can't be accessed and will throw a security error
                var l = rules && rules.length;
                // iterate through each cssRules of this stylesheet
                for (var j = 0; j < l; j++) {
                // get the selector of this cssRules
                var selector = rules[j].selectorText;
                // probably an external stylesheet we can't access
                if (!selector) {
                    continue;
                }

                // is it our svg node or one of its children ?
                if ((svg.matches && svg.matches(selector)) || svg.querySelector(selector)) {

                    var cssText = rules[j].cssText;
                    // append it to our <style> node
                    style.innerHTML += cssText + '\n';
                }
            }
                // if we got some rules
                if (style.innerHTML) {
                // append the style node to the clone's defs
                defs.appendChild(style);
                }
            }
        }
    }
}
</script>