<template>
    <g :transform="transform">
        <rect x=0 y=0 :width='size' :height='size' class='background'><!-- <i class="fas fa-trash-alt" /> --></rect>

        <g :transform="transformIcon">
            <path class="noStroke" :fill="fill" d="M7.9-2.6C7.8-2.5,7.7-2.4,7.6-2.3c-0.4,0.4-0.9,0.7-1.3,1C6-1.2,5.9-0.9,6-0.7c0,0.1,0.1,0.3,0,0.5C5.9,0.5,6.2,1.1,6.7,1.4
	C7,1.6,7.3,1.8,7.6,2.1s0.3,0.4,0.1,0.7c-0.5,0.9-1,1.7-1.5,2.6C6,5.7,5.9,5.7,5.6,5.6C5.1,5.4,4.5,5.2,4,5C3.8,4.9,3.5,4.9,3.3,5.1
	C3.1,5.3,2.9,5.4,2.7,5.5C2.2,5.7,2.1,6,2.1,6.5S2,7.4,1.9,7.9c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0-0.1,0h-3.1c-0.3,0-0.3,0-0.4-0.4
	c-0.1-0.5-0.2-1-0.2-1.6c0-0.3-0.1-0.5-0.4-0.6c-0.3-0.1-0.6-0.3-0.9-0.5C-3.7,5-3.9,5-4.1,5.1l-1.5,0.6c-0.5,0.2-0.5,0.2-0.8-0.2
	c-0.5-0.8-0.9-1.6-1.4-2.4C-8,2.7-8,2.6-7.6,2.3l1.2-1C-6.1,0.9-6,0.6-6,0.4c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.4-0.8
	C-6.8-1.7-7.2-2-7.5-2.3c-0.3-0.3-0.4-0.4-0.2-0.8c0.3-0.6,0.6-1.1,1-1.7C-6.6-5-6.4-5.2-6.3-5.5C-6-5.9-6-5.9-5.5-5.7L-4-5.1
	c0.2,0.1,0.4,0,0.5-0.1c0.3-0.2,0.6-0.4,0.9-0.5C-2.4-5.8-2.3-6-2.2-6.2c0-0.6,0.1-1.1,0.2-1.7c0-0.2,0.1-0.3,0.3-0.3h3
	c0.6,0,0.6,0,0.7,0.6c0.1,0.6,0.2,1,0.2,1.5c0,0.2,0.1,0.4,0.3,0.4C2.7-5.6,2.9-5.5,3-5.4C3.4-5,4-5,4.4-5.3
	c0.4-0.2,0.8-0.3,1.2-0.5C5.8-5.9,6-5.9,6.1-5.7l0,0L7.7-3C7.8-2.8,7.8-2.7,7.9-2.6z M-0.1-3.1C-1.7-3.1-3-1.8-3-0.1
	s1.2,2.9,2.8,2.9h0.1c1.6,0.1,2.9-1.2,2.9-2.7c0-0.1,0-0.1,0-0.2C2.9-1.8,1.6-3.1-0.1-3.1L-0.1-3.1L-0.1-3.1z"/>
        </g>
        <!-- :class="'[{settingsHover: hover}]'" -->
        <rect @click="$emit('open-settings')" x=0 y=0 :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
        <!-- <text x=2 y=8 font-family="Verdana" font-size="8" fill="grey">Set.</text> -->
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'SettingsButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;

            if(val){
                this.fill = "#006eee";
            } else {
                this.fill = "#999";
            }
        },
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(1.5, 1.5)`;
        }
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999"
        }
    }
}
</script>

<style scoped>

rect {
    fill: rgba(255,255,255,0);
    cursor: pointer;
    /* stroke: black; */
}
.background{
    fill: #fff;
}
.settingsHover {
    fill: #2196F3;
}
.noStroke {
    stroke-width:0;
}
</style>