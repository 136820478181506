import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";

// Import the styles directly. (Or you could add them via script tags.)
//import './scss/style.scss';
// import "./scss/style.scss";
// import "bootstrap/scss/bootstrap.scss";

Vue.config.productionTip = false;

// importe store for vuex!

export const globalStore = new Vue({
  data: {
    zoomfactor: 1,
    editModuleID: -1,
    assignment: "coperion",
    fillMode: false,
  },
});

Vue.use(BootstrapVue);

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
