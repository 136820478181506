<template>
    <g :transform='transform'> <!-- :visibility='visibility' -->
        <io-connector-svg v-for="(c, index) in connectors" v-on:create-connection="data => $emit('create-connection', data)" :id="'connection_' + componentId +'_'+ c.type + '_' + position + '_' + index" :index="index" :componentId='componentId' :offset='calcOffset(index)' :key='c.mykey + c.type + position + index' :inOut='c.inOut' :type='c.type' :position='position' :workspace='workspace'></io-connector-svg> <!--  :x='x' :y='y' -->
    </g>
</template>

<script>
import IOConnectorSVG from './IOConnectorSVG.vue'
export default {
    name: 'IOConnectorGroup',
    components: {
        'io-connector-svg': IOConnectorSVG
    },
    data(){
        return {
            offset: 30,
        }
    },
    props: {
        position: {
            type: String,
            required: true
        },
        connectors: {
            type: Array,
            default: function () {
                return []
            }
        },
        translateGroup: {
            type: Object,
            default: function () {
                return { x: 0, y: 0 }
            }
        },
        workspace: {
            typeof: Boolean,
            default: true,
        },
       /* item: {
            type: Object,
            default: function(){
                return {}
            }
        },*/
        componentId: {
            type: Number,
            required: true
        }, 
        // visibility: {
        //     type: String,
        //     required: true
        // }
    },
    computed: {
        /*x: function(){
            switch(this.position){
                case 'top':
                    return 0;
                case 'right':
                    return 0;
                case 'bottom':
                    return 0;
                case 'left':
                    return 0;
                default:
                    return 0;
            }
            
        },
        y: function(){
            switch(this.position){
                case 'top':
                    return 0;
                case 'right':
                    return 0;
                case 'bottom':
                    return 0;
                case 'left':
                    return 0;
                default:
                    return 0;
            }
        },*/
        transform: function(){
            //console.log("this.translateGroup");
            //console.log(this.translateGroup);
            return `translate( ${this.translateGroup.x},  ${this.translateGroup.y} )`;
        }
    },
    methods:{
        calcOffset: function (index) {
            var offsetEinheit = index - (this.connectors.length-1) / 2;

            return this.offset * offsetEinheit;
        },
    },
}
</script>