<template>
    <g :transform="transform">
        <rect x=0 y=0 :width='size' :height='size' class='background'><!-- <i class="fas fa-trash-alt" /> --></rect>

        <g :transform="transformIcon">
            <path class="noStroke" :fill="fill" d="M7.3-7.8c0.3,0,0.5,0.3,0.5,0.5v3.4c0,0.3-0.3,0.5-0.5,0.5H6.2c-0.3,0-0.5-0.3-0.5-0.5V-5H1.5V5.6h1.4
	c0.3,0,0.5,0.3,0.5,0.5v1.1c0,0.3-0.3,0.5-0.5,0.5h-5.6c-0.3,0-0.5-0.3-0.5-0.5V6.2c0-0.3,0.3-0.5,0.5-0.5h1.4V-5h-4.2v1.1
	c0,0.3-0.3,0.5-0.5,0.5h-1.1c-0.3,0-0.5-0.3-0.5-0.5v-3.4c0-0.3,0.3-0.5,0.5-0.5H7.3z"/>
        </g>
        <!-- :class="'[{settingsHover: hover}]'" -->
        <rect @click="$emit('open-text-input')" x=0 y=0 :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
        <!-- <text x=2 y=8 font-family="Verdana" font-size="8" fill="grey">Set.</text> -->
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'TextButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;

            if(val){
                this.fill = "#006eee";
            } else {
                this.fill = "#999";
            }
        },
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(1.5, 1.5)`;
        }
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999"
        }
    }
}
</script>

<style scoped>

rect {
    fill: rgba(255,255,255,0);
    cursor: pointer;
    /* stroke: black; */
}
.background{
    fill: #fff;
}
.settingsHover {
    fill: #2196F3;
}
.noStroke {
    stroke-width:0;
}
</style>