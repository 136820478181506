<template>
    <g v-if="connectorOut && connectorIn" :class="[, {fillClass: fillMode}, 'connection_group']" @mouseenter="setHover(true)" @mouseleave="setHover(false)" >
        <g v-if="infos" :transform="infosTransform">
            <text v-for="(info, index) in getInfos()" :key="'text_'+index" x=10 :y="index * 14 - 3" font-family="Verdana" font-size=12 fill="grey">{{info}}</text>
            
            <!-- <text v-if="infos.pipeLength && infos.pipeLength > 0" x=10 y=-25 font-family="Verdana" font-size=12 fill="grey">{{infos.pipeLength + " m length"}}</text>
            <text v-if="infos.pipeHeight && infos.pipeHeight > 0" x=10 y=-5 font-family="Verdana" font-size=12 fill="grey">{{infos.pipeHeight + " m height"}}</text>
            <text v-if="infos.bends && infos.bends > 0" x=10 y=15 font-family="Verdana" font-size=12 fill="grey">{{infos.bends + " bends"}}</text>
            <text v-if="infos.performance && infos.performance > 0" x=10 y=35 font-family="Verdana" font-size=12 fill="grey">{{infos.performance + " kg/h"}}</text>
            <text v-if="infos.description && infos.description.length > 0" x=10 y=55 font-family="Verdana" font-size=12 fill="grey">{{infos.description}}</text> -->
        </g>
        <polyline v-if="workspace" @click="showConnectionOptions" class="connection_white" :points='points' ></polyline>
        <polyline @click="showConnectionOptions" class="connection" :stroke-dasharray="dashed" :class='[{connectionHover: hover}, type, assignment]' :points='points' ></polyline>
        <!-- <polyline v-if="workspace" @click="showConnectionOptions" class="connection" :stroke-dasharray="dashed" :class='[{connectionHover: hover}, type, assignment]' :points='points' ></polyline> -->
        <!-- <polyline v-if="workspace == false" class="connection" :stroke-dasharray="dashed" :stroke="strokeColor" :class='[{connectionHover: hover}, type, assignment]' :points='points' ></polyline> -->
        <!-- <ellipse v-on:mousedown='startDragElement2' :cx="cx2 + moveX2" :cy="cy2" :rx="radEllipse" :ry="radEllipse" :class='[{grabVisible: hover}, "invisible"]'></ellipse> -->

        <g v-if="workspace" :transform="transformHandle1" :class='[{grabVisible: hover}, "invisible"]'>
            <handle-svg class="handle1" v-on:mouse-down="startDragElement1" :x="0" :y="0" :size="30"></handle-svg> <!-- v-on:mouse-down="startDragElement1" -->
        </g>
        <g v-if="workspace" :transform="transformHandle2" :class='[{grabVisible: hover}, "invisible"]'>
            <handle-svg class="handle2" v-on:mouse-down="startDragElement2" :x="0" :y="0" :size="30"></handle-svg> <!-- v-on:mouse-down="startDragElement2" -->
        </g>

        <g v-if="workspace" :transform="transformMenu" :class='[{grabVisible: hover}, "invisible"]'> <!-- @click="connectionsSettings" -->
            <rect fill="#fff" stroke="rgb(200, 200, 200)" stroke-width="1.3"  x="-34" y="-18" width="68" height="36" rx="4" ry="4"/>
            <g :transform="transformSettings" > <!-- @click="showSettings()" -->
                <settings-button-svg  :x=-15 :y=-15 :size=30 v-on:open-settings="showSettings"></settings-button-svg>
            </g>

            <g :transform="transformDelete" >      
                <delete-components-button-svg :x=-15 :y=-15 :size=30 v-on:delete-component="removeSelf"></delete-components-button-svg>  
            </g>
        </g>
        
    </g>
</template>
<script>
//import { mapActions } from 'vuex'
import Vue from 'vue'
import { EventBus } from './event-bus'
import posHelper from './PositionHelperFunctions'
import ConnectionsSettingsWindow from './ConnectionsSettingsWindow'
import DeleteComponentButtonSVG from './DeleteComponentButtonSVG'
import SettingsButtonSVG from './SettingsButtonSVG'
import { globalStore } from '../main.js'
import HandleSVG from './HandleSVG.vue'

export default {
    name: 'ConnectionSVG',
    components: {
        'delete-components-button-svg': DeleteComponentButtonSVG,
        'settings-button-svg': SettingsButtonSVG,
        'handle-svg': HandleSVG,
    },
    props: {
        out: {
            type: Object,
            required: true,
        },
        in: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
        assignment: {
            type: String,
        },
        handles: {
            type: Array,
        },
        infos: {
            type: Object,
            required: false,
        },
        workspace: {
            type: Boolean,
            default: true,
        }
    },
    data(){
        return {
            hover: false,
            blockUnhover: false,
            radEllipse: 15,
            radMenuItem: 12,
            radius: 20,
            pos1: '',
            pos2: '',
            pos3: '',
            pos4: '',
            moveX1: 0,
            moveX2: 0,
            moveY1: 0,
            moveY2: 0,
            mouseClickX: -1000,
            mouseClickY: -1000,
            buttonIconFill: "#999",
            fillMode: false,
        }
    },
    computed:{
        connectorOut: function(){
            let _out = document.getElementById("component_group_" + this.out.id);
            if(_out){
                let outNo = this.out.no ? this.out.no : 0;
                return _out.getElementsByClassName('output ' + this.type)[outNo];
            }else {
                return false;
            }
        },
        connectorIn: function(){
            let _in = document.getElementById("component_group_" + this.in.id);
            if(_in){
                let inNo = this.in.no ? this.in.no : 0;
                return _in.getElementsByClassName('input ' + this.type)[inNo];
            } else{
                return false;
            }
        },
        cx1: function(){
            if(this.connectorOut){
                let realPositionOut = posHelper.getGlobalConnectorPosition(this.connectorOut);
                //let realPositionIn = posHelper.getGlobalConnectorPosition(this.connectorIn);
                if(this.handles){
                    return realPositionOut.x + this.handles[0].x;
                } else {
                    let outX = realPositionOut.x;

                    if(this.connectorOut.classList.contains('right')){
                        outX += this.radius;
                    } else if(this.connectorOut.classList.contains('left')){
                        outX -= this.radius;
                    }
                    return outX;
                }
            } else {
                return null;
            }
        },

        cx2: function(){
            if(this.connectorIn){
                //let realPositionOut = posHelper.getGlobalConnectorPosition(this.connectorOut);
                let realPositionIn = posHelper.getGlobalConnectorPosition(this.connectorIn);
                if(this.handles){
                    return realPositionIn.x + this.handles[1].x;
                } else {
                    let inX = realPositionIn.x;
                    if(this.connectorIn.classList.contains('left')){
                        inX -= this.radius;
                    } else if(this.connectorIn.classList.contains('right')){
                        inX += this.radius;
                    }
                    return inX;
                }
            }else{
                return null;
            }
        },

        cy1: function(){
            if(this.connectorOut){
                let realPositionOut = posHelper.getGlobalConnectorPosition(this.connectorOut);
                //let realPositionIn = posHelper.getGlobalConnectorPosition(this.connectorIn);
                if(this.handles){
                    return realPositionOut.y + this.handles[0].y;
                }else {
                    let outY = realPositionOut.y;

                    if(this.connectorOut.classList.contains('top')){
                        outY -= this.radius;
                    } else if(this.connectorOut.classList.contains('bottom')){
                        outY += this.radius;
                    }

                    // wenn abstand noch oben kleiner als radius:
                    /*if(this.connectorOut.classList.contains('top') && realPositionOut.y - realPositionIn.y <= this.radius ){
                        outY -= this.radius;
                    }*/

                    return outY;
                }
            }else{
                return null;
            }
        },
         cy2: function(){
            if(this.connectorIn){
            //let realPositionOut = posHelper.getGlobalConnectorPosition(this.connectorOut);
                let realPositionIn = posHelper.getGlobalConnectorPosition(this.connectorIn);
                if(this.handles){
                    return realPositionIn.y + this.handles[1].y;
                }else {
                    let inY = realPositionIn.y;

                    if(this.connectorIn.classList.contains('top')){
                        inY -= this.radius;
                    } else if(this.connectorIn.classList.contains('bottom')){
                        inY += this.radius;
                    }
                    // wenn abstand noch oben kleiner als radius:
                    /*if(this.connectorIn.classList.contains('bottom') && realPositionOut.y - realPositionIn.y <= this.radius ){
                        outY -= this.radius;
                    }*/

                    return inY;
                }
            } else {
                return null;
            }
        },
        infosTransform(){
                let xPos;
                let yPos;

                if(this.cx2 - this.cx1 >= 0 && this.infos.xPos >= 0){
                    xPos = Math.min(this.infos.xPos, this.cx2 - this.cx1);   
                } else if((this.cx2 - this.cx1 >= 0 && this.infos.xPos < 0) || (this.cx2 - this.cx1 < 0 && this.infos.xPos >= 0)){
                    xPos = 0;
                } else {
                    xPos = Math.max(this.infos.xPos, this.cx2 - this.cx1);
                }
                
                if(this.cy2 - this.cy1 >= 0 && this.infos.yPos >= 0){
                    yPos = Math.min(this.infos.yPos, this.cy2 - this.cy1);     
                } else if((this.cy2 - this.cy1 >= 0 && this.infos.yPos < 0) || (this.cy2 - this.cy1 < 0 && this.infos.yPos >= 0)){
                    yPos = 0;
                } else {
                    yPos = Math.max(this.infos.yPos, this.cy2 - this.cy1);
                }

                // bei xOffset verschiebe auf y2, damit der Text nicht im leeren hängt.
                if(Math.abs(xPos) > 3 && Math.abs(this.cy2 - this.cy1) > Math.abs(yPos)){
                    yPos = this.cy2 - this.cy1;
                }


                return `translate(${this.cx1 + xPos}, ${this.cy1 + yPos})`;
        },
        transformHandle1(){
            if(Math.abs(this.moveX1) > Math.abs(this.moveY1)){
                return `translate(${this.cx1 + this.moveX1 }, ${this.cy1})`;
            } else {
                return `translate(${this.cx1}, ${this.cy1+ this.moveY1 })`;
            }
            // move handle in y ...
            // return `translate(${this.cx1 + this.moveX1 }, ${this.cy1  + this.moveY1})`;
        },

        transformHandle2(){
            if(Math.abs(this.moveX2) > Math.abs(this.moveY2)){
                return `translate(${this.cx2 + this.moveX2 }, ${this.cy2})`;
            } else {
                return `translate(${this.cx2}, ${this.cy2+ this.moveY2 })`;
            }
            // move handle in y ...
            // return `translate(${this.cx1 + this.moveX1 }, ${this.cy1  + this.moveY1})`;
        },

        transformMenu(){
            return `translate(${this.mouseClickX}, ${this.mouseClickY})`;
        },
        transformDelete(){
            return `translate(${(this.radMenuItem + 4)}, 0)`;
        },
        transformSettings(){
            return `translate(${-(this.radMenuItem +4)}, 0)`;
        },




        points: function(){
            if(this.connectorOut && this.connectorIn){
                //console.log("this.out.id: ");
                //console.log(this.out.id);
                //console.log("component_group_" + this.out.id);
                //let _out = document.getElementById("component_group_" + this.out.id);

                //console.log("_out:");
                //console.log(_out);

                //let outNo = this.out.no ? this.out.no : 0;
                //let c_out = _out.getElementsByClassName('output ' + this.type)[outNo];
                // console.log(this.type);
                // console.log(outNo + " c_out: ");
                // console.log(c_out);
                let realPositionOut = posHelper.getGlobalConnectorPosition(this.connectorOut);//this.getPosition(c_out);
                //console.log(realPositionOut);

                //let _input = document.getElementById("component_group_" + this.in.id);
                
                //console.log("component_group_" + this.in.id);
                //let inNo = this.in.no ? this.in.no : 0;
                //let c_in = _input.getElementsByClassName('input ' + this.type)[inNo];
                // console.log(inNo + " c_in: ");
                // console.log(c_in);
                let realPositionIn = posHelper.getGlobalConnectorPosition(this.connectorIn); //this.getPosition(c_in);

                //let width = realPositionIn.x - realPositionOut.x;
                //let ;

                let coords = [];
                coords.push(realPositionOut.x);
                coords.push(realPositionOut.y);

                //console.log("this.out: ");
                //console.log(c_out);


                if(this.connectorOut.classList.contains('top')){
                    coords.push(realPositionOut.x);
                    coords.push(realPositionOut.y - this.radius);

                    coords.push(realPositionOut.x);
                    coords.push(this.cy1);
                } else if(this.connectorOut.classList.contains('right')){
                    coords.push(realPositionOut.x + this.radius);
                    coords.push(realPositionOut.y);

                    coords.push(realPositionOut.x + this.radius);
                    coords.push(this.cy1);
                } else if(this.connectorOut.classList.contains('bottom')){
                    coords.push(realPositionOut.x);
                    coords.push(realPositionOut.y + this.radius);

                    coords.push(realPositionOut.x);
                    coords.push(this.cy1);
                } else if(this.connectorOut.classList.contains('left')){
                    coords.push(realPositionOut.x - this.radius);
                    coords.push(realPositionOut.y);

                    coords.push(realPositionOut.x - this.radius);
                    coords.push(this.cy1);
                }

                coords.push(this.cx1);
                coords.push(this.cy1);

                coords.push(this.cx1);
                coords.push(this.cy2);

                coords.push(this.cx2);
                coords.push(this.cy2);


                if(this.connectorIn.classList.contains('top')){
                    coords.push(this.cx2);
                    coords.push(realPositionIn.y - this.radius);

                    coords.push(realPositionIn.x);
                    coords.push(realPositionIn.y - this.radius);
                } else if(this.connectorIn.classList.contains('right')){
                    coords.push(this.cx2);
                    coords.push(realPositionIn.y);
                    
                    coords.push(realPositionIn.x + this.radius);
                    coords.push(realPositionIn.y);
                } else if(this.connectorIn.classList.contains('bottom')){
                    coords.push(this.cx2);
                    coords.push(realPositionIn.y + this.radius);
                    
                    coords.push(realPositionIn.x);
                    coords.push(realPositionIn.y + this.radius);
                } else if(this.connectorIn.classList.contains('left')){
                    coords.push(this.cx2);
                    coords.push(realPositionIn.y);
                    
                    coords.push(realPositionIn.x - this.radius);
                    coords.push(realPositionIn.y);
                }

                coords.push(realPositionIn.x);
                coords.push(realPositionIn.y);


                let string = '';
                coords.forEach( c => {
                    string += c + ' ';
                });


                return string;
            }else {
                console.log("Not possible to create ConnectionID: " + this.id + ", because Connector is missing");
                return null;
            }
            //console.log(offsetsOut);
            //return `${realPositionOut.x}, ${realPositionOut.y} ${realPositionIn.x}, ${realPositionIn.y}`;
            //return '20,20 40,25';
        },
        dashed: function (){
            return (this.type == 'air')? "5 2" : "";
        },
        strokeColor: function(){
            let color = "";
            switch(this.assignment){
                case 'existing':
                    color = "#adb3b8";
                    break;
                case 'other':
                    color =  "#02943d";
                    break;
                case 'future':
                    color =  "#d35100";
                    break;
                default:
                    color =  "#000";
            }
            return color;
        }
    },
    methods:{
        //...mapActions(['updateConnection']),
        getScalefactor: function(){
                return globalStore.zoomfactor;
        }, 
        getInfos: function(){
            let infosArr = [];
            if(this.infos.pipeLength && this.infos.pipeLength > 0){
                infosArr.push(this.infos.pipeLength + " m length");
            }
            if(this.infos.pipeHeight && this.infos.pipeHeight > 0){
                infosArr.push(this.infos.pipeHeight + " m height");
            }
            if(this.infos.bends && this.infos.bends > 0){
                infosArr.push(this.infos.bends + " bends");
            }
            if(this.infos.performance && this.infos.performance > 0){
                infosArr.push(this.infos.performance + " kg/h");
            }
            if(this.infos.description && this.infos.description.length > 0){
                // lange texte müssen noch gesplittet werden
                let lines = this.getFormattedText(this.infos.description);
                // add empty line:
                infosArr.push("");
                
                infosArr = infosArr.concat(lines);
                //console.log(infosArr);
            }
            return infosArr;
        },
        showSettings(){
                // create HTML Window
                var app = document.getElementById("svg_container").parentNode;
                var ConnectionsSettingsWindowClass = Vue.extend(ConnectionsSettingsWindow);

                let win = new ConnectionsSettingsWindowClass({
                    propsData: {
                        connectionId: this.id,
                        xPos: this.mouseClickX - this.cx1,
                        yPos: this.mouseClickY - this.cy1,
                        infos: this.infos,
                    }
                });

                win.$mount();

                console.log(win.$el);
                app.appendChild(win.$el);
        },
        setHover(b){
            
            if(globalStore.fillMode){
                this.fillMode = b;
                this.hover = false;
            } else {
                if(this.blockUnhover == false){
                    this.hover = b;
                }
            }
        },
        setHoverIcon(val){
            //this.hover = val;

            if(val){
                this.buttonIconFill = "#006eee"
            } else {
                this.buttonIconFill = "#999"
            }
        },
        removeSelf(){
            console.log("removeSelf: remove connection: " + this.id);

            // remove in App.vue in create:
            EventBus.$emit('removeConnection', this.id);
        }, 
        showConnectionOptions(e){

            if(globalStore.fillMode == false){
                console.log(e);
                console.log("parent:");
                console.log(e.target.parentElement);

                console.log(e.target.parentElement.childNodes.length);

                console.log(e.target.parentElement.getElementsByClassName("handle1"));

                let handle = e.target.parentElement.getElementsByClassName("handle1")[0];

                var domRect = handle.getBoundingClientRect();
                console.log(domRect);


                let xOffset = e.clientX - (domRect.x + domRect.width/2);
                let yOffset = e.clientY - (domRect.y + domRect.height/2);

                // zoom ausgleich:
                xOffset /= globalStore.zoomfactor;
                yOffset /= globalStore.zoomfactor;

                this.mouseClickX = this.cx1 + xOffset;
                this.mouseClickY = this.cy1 + yOffset;
            } else {
                // set assignment:
                let data = {
                    id: this.id,
                    assignment: globalStore.assignment,
                }
                EventBus.$emit('updateConnectionAssignment', data);
            }
        },
        startDragElement1(e){	
            console.log("startDragElement1");
            console.log(e);					
            this.pos3 = e.clientX;
            this.pos4 = e.clientY;

            this.blockUnhover = true;
                
            if(e.touches){
                    console.log(e.touches)
                    var touch = e.touches[0];
                    this.touchElement =  document.elementFromPoint(touch.pageX,touch.pageY);
                    console.log(this.touchElement);

                    document.ontouchmove = this.dragElementTouch;
                    // todo: safari kann kein ontouchend!
                    document.ontouchend = this.closeDragElement1;
            } else {
                    document.onmouseup = this.closeDragElement1;
                    document.onmousemove = this.dragElement1;
            }
        },
        startDragElement2(e){						
            this.pos3 = e.clientX;
            this.pos4 = e.clientY;

            this.blockUnhover = true;
                
            if(e.touches){
                    console.log(e.touches)
                    var touch = e.touches[0];
                    this.touchElement =  document.elementFromPoint(touch.pageX,touch.pageY);
                    console.log(this.touchElement);
                    document.ontouchmove = this.dragElementTouch;

                    // todo: safari kann kein ontouchend!
                    document.ontouchend = this.closeDragElement2;
            } else {
                    document.onmouseup = this.closeDragElement2;
                    document.onmousemove = this.dragElement2;
            }
        },
        dragElement1(e){
                //console.log(e);
                //this.position.top = e.
                    e = e || window.event;
                    e.preventDefault();
                    // calculate the new cursor position:
                    this.pos1 = this.pos3 - e.clientX;
                    this.pos2 = this.pos4 - e.clientY;
                    this.pos3 = e.clientX;
                    this.pos4 = e.clientY;
                    // set Temp Position:
                    console.log("dragElement");

                    // temp transform:
                    //this.moveX1 -= this.pos1;
                    this.moveX1 -= this.pos1 / this.getScalefactor();
                    this.moveY1 -= this.pos2 / this.getScalefactor();

                    //this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX / this.getScalefactor()},  ${this.moveY / this.getScalefactor()} )`);



                    //this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX / this.getScalefactor()},  ${this.moveY / this.getScalefactor()} )`);

                    if(e.touches){
                        var touch = e.touches[0];
                        if (this.touchElement !== document.elementFromPoint(touch.pageX,touch.pageY)) {
                            this.closeDragElement1();
                        }
                    }
        },
        dragElement2(e){
                //console.log(e);
                //this.position.top = e.
                    e = e || window.event;
                    e.preventDefault();
                    // calculate the new cursor position:
                    this.pos1 = this.pos3 - e.clientX;
                    this.pos2 = this.pos4 - e.clientY;
                    this.pos3 = e.clientX;
                    this.pos4 = e.clientY;
                    // set Temp Position:
                    console.log("dragElement");

                    // temp transform:
                    //this.moveX2 -= this.pos1;
                    //this.moveY2 -= this.pos2;
                    this.moveX2 -= this.pos1 / this.getScalefactor();
                    this.moveY2 -= this.pos2 / this.getScalefactor();

                    console.log(this.moveX2 + " " + this.moveY2);
                    //this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX / this.getScalefactor()},  ${this.moveY / this.getScalefactor()} )`);

                    if(e.touches){
                        var touch = e.touches[0];
                        if (this.touchElement !== document.elementFromPoint(touch.pageX,touch.pageY)) {
                            this.closeDragElement2();
                        }
                    }
        },


        closeDragElement1(){
                console.log();
                console.log("called closeDrag Element");
                document.ontouchend = null;
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchmove = null;

                this.touchElement = null;

                //let pos = { top: this.roundTo(parseInt(this.compValues.position.top) + (this.moveY/ this.getScalefactor()), this.raster), left: this.roundTo(parseInt(this.compValues.position.left) + (this.moveX / this.getScalefactor()), this.raster) };
                
                let realPositionOut = posHelper.getGlobalConnectorPosition(this.connectorOut);//this.getPosition(c_out);
                let realPositionIn = posHelper.getGlobalConnectorPosition(this.connectorIn); //this.getPosition(c_in);

                // save Values:
                let data = {};
                if(Math.abs(this.moveX1) > Math.abs(this.moveY1)){
                    data = { id: this.id, handles: [{x: this.cx1-realPositionOut.x + this.moveX1, y: this.cy1 -realPositionOut.y},{x: this.cx2-realPositionIn.x, y: this.cy2-realPositionIn.y}]};
                } else {
                    data = { id: this.id, handles: [{x: this.cx1-realPositionOut.x, y: this.cy1 -realPositionOut.y + this.moveY1},{x: this.cx2-realPositionIn.x, y: this.cy2-realPositionIn.y}]};
                }
                console.log("new connection data: ");
                console.log(data);

                EventBus.$emit('updateConnection', data);
                //this.updateConnection(data);   

                //this.$el.setAttribute('transform', `translate( ${pos.left},  ${pos.top} )`);
                this.blockUnhover = true;

                this.moveX1 = 0;
                this.moveY1 = 0;
                //this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX},  ${this.moveY} )`);
                
                //this.updateComponent({ id: this.compValues.id, position: pos});                
        },
        closeDragElement2(){
                console.log();
                console.log("called closeDrag Element");
                document.ontouchend = null;
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchmove = null;

                this.touchElement = null;

                //let pos = { top: this.roundTo(parseInt(this.compValues.position.top) + (this.moveY/ this.getScalefactor()), this.raster), left: this.roundTo(parseInt(this.compValues.position.left) + (this.moveX / this.getScalefactor()), this.raster) };

                // save Values:

                //let data = { id: this.id, handle: {x: this.cx + this.moveX, y: this.cy}};
                let realPositionOut = posHelper.getGlobalConnectorPosition(this.connectorOut);//this.getPosition(c_out);
                let realPositionIn = posHelper.getGlobalConnectorPosition(this.connectorIn);//this.getPosition(c_out);
                //let realPositionIn = posHelper.getGlobalConnectorPosition(this.connectorIn); //this.getPosition(c_in);
                // save Values:
               // let data = { id: this.id, handles: [{x: this.cx1-realPositionOut.x + this.moveX1, y: this.cy1 -realPositionOut.y},{x: this.cx2-realPositionIn.x, y: this.cy2-realPositionIn.y}]};

                let data = {};
                if(Math.abs(this.moveX2) > Math.abs(this.moveY2)){
                    data = { id: this.id, handles: [{x: this.cx1-realPositionOut.x, y: this.cy1 -realPositionOut.y},{x: this.cx2-realPositionIn.x + this.moveX2, y: this.cy2-realPositionIn.y}]};
                } else {
                    data = { id: this.id, handles: [{x: this.cx1-realPositionOut.x, y: this.cy1 -realPositionOut.y},{x: this.cx2-realPositionIn.x, y: this.cy2-realPositionIn.y + this.moveY2}]};
                }
                
                console.log(data);

                EventBus.$emit('updateConnection', data);
                //this.updateConnection(data);   

                //this.$el.setAttribute('transform', `translate( ${pos.left},  ${pos.top} )`);
                this.blockUnhover = true;

                this.moveX2 = 0;
                this.moveY2 = 0;
                //this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX},  ${this.moveY} )`);
                
                //this.updateComponent({ id: this.compValues.id, position: pos});                
        },
        getFormattedText: function(text){
                let lines = [];
                
                lines = text.split('\n');

                const lineLength = 43;
                // check if an lines is more than 36 lettes
                for(let i = 0; i<lines.length; i++){
                    if(lines[i].length > lineLength){
                        const tmpLine = lines[i];
                        // suche nach leerzeichen:
                        let cut = lines[i].substring(0, lineLength+3).lastIndexOf(' ');

                        if(cut > 0){
                            lines[i] = lines[i].substring(0, cut);
                            lines.splice(i+1, 0, tmpLine.substring(cut+1));
                        } else {
                            lines[i] = lines[i].substring(0, 36) + "-";
                            lines.splice(i+1, 0, tmpLine.substring(36));
                        }
                    }
                }    
                return lines;
        },
    
    },
}

</script>

<style scoped>
    polyline {
        stroke-width:2;
        fill:none;
        cursor: pointer;
    }
    .connection {
        fill:none;
        /* stroke:black; */
    }
    .connection_white {
        fill:none;
        stroke:#fff;
        stroke-width:4;
    }
    .connectionHover {
        stroke-width: 5 !important;
        fill:none;
    }

    .fillClass > polyline {
        cursor: copy;
    }

    /*ellipse {
         visibility: hidden;
    }

    line {
         visibility: hidden;
    }*/

    .invisible {
        visibility: hidden;
    }

    .grabVisible {
        visibility: visible !important;
    }

    polyline.existing {
        stroke:rgb(173, 179, 184);
    }
    polyline.coperion{
        /* stroke: #000; */
    }
    polyline.other{
        stroke:rgb(2, 148, 61);
    }
    polyline.future{
        stroke:rgb(211, 81, 0);
    }

     .air {
        /* stroke: #c1e0ff; */
        stroke: #000;
        stroke-width: 1;
    }
     .bulk {
        /* stroke: #226cee; */
        stroke: #000;
        stroke-width: 1.5;
    }
</style>