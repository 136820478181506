<template>
<div class="overlayBackground">
    <div class="overlay">
        
        <p>Release Notes: <br /></p>
        <table>
            <tr v-for="entry in releaseNotes" :key="entry.key"><td class="date">{{entry.date}}</td><td><span v-for="note in entry.notes" :key="note.key">&bull; {{note}}<br /></span></td></tr>
        </table>
        <br />
        <delete-window label="close" v-on:close-window="removeSelf"></delete-window>
    </div>
</div>
</template>

<script>
import DeleteWindow from './DeleteWindow';

export default {
    name: "ReleaseNotesWindow",
    components:{
        'delete-window': DeleteWindow,
    },
    data(){
        return{
            option: '',
            version: '',
            versions: [],
        }
    },
    methods:{
        removeSelf(){
            console.log("remove Window");
            this.$el.remove();
        }
    },
    props: {
        releaseNotes: {
            type: Array,
            require: true,
        }
        
    },
    computed: {
    },
    mounted() {
    },

}
</script>

<style scoped>

    .overlay {
        position: relative;
        min-width: 300px;
        /* min-height: 150px; */
        background-color: #fff;
        /*border: #000 solid 1px;*/
        padding: 20px;
        
    }
    .overlayBackground {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;

    }
    label {
        padding-right: 1em;
    }

    td, tr {
        vertical-align: top;
    }
    td {
        padding-right: 20px;
        padding-bottom: 12px;
    }
    
</style>