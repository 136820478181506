<template>
    <div id="menu" :class='[{menuClosed: open}, "col-10 col-md-5 col-lg-4 col-xl-3 col-xxxl-2"]'>

                
        <b-card no-body>

            <div id="menu-header" class="container-fluid"><p style="position:relative"> <b-button @click="toggleMenu()" class="close" :class='[{menuClosed: open}]'></b-button></p></div>
            

            <b-button-group class="col-10" id="toggleComponentesModules">
                <b-button class="col-sm-6" size="sm" v-b-toggle.collapseComponents>COMPONENTS</b-button>
                <b-button class="col-sm-6" size="sm" v-b-toggle.collapseModules>MODULES</b-button>
            </b-button-group>



            <!-- <div class="btn-group col-6" role="group" aria-label="Basic example">
                <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#componentsCollapse" aria-expanded="false" aria-controls="componentsCollapse">
                    COMPONENTS
                </button>
                <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#modulesCollapse" aria-expanded="false" aria-controls="modulesCollapse">
                    MODULES
                </button>
            </div> -->


                <!-- <b-card-body>
                    <b-card-title>COMPONENTS</b-card-title>
                </b-card-body> -->

            <b-collapse visible id="collapseComponents" accordion="main_accordion">
                <b-list-group class="" flush>
                    <menu-accordion v-for="t in compTopics" :key='t.title' :topic="t" accordionID="componentAccordion" type="component" v-on:create-new-element="name => $emit('create-new-component', name)"></menu-accordion>
                </b-list-group>
            </b-collapse >
                <!-- <b-card-body>
                    <b-card-title>MODULES</b-card-title>
                </b-card-body> -->
            <b-collapse  id="collapseModules" accordion="main_accordion">
                <b-list-group class="" flush>
                    <menu-accordion v-for="t in moduleTopics" :key='t.title' :topic="t" accordionID="moduleAccordion" type="module" v-on:create-new-element="name => $emit('create-new-module', name)"></menu-accordion>
                </b-list-group>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MenuAccordionBS from './MenuAccordionBS.vue'

export default {
    name: 'MenuOverlayBS',

    components: {
        'menu-accordion': MenuAccordionBS,
    },
    data(){
        return{ 
            open: false,
        }
    },
    props: {
        plantTypeMenu: {
            type: String,
            required: true
        },
    }, 
    computed: {
        ...mapGetters(["getMenu"]),
        c_types: function(){
            console.log("this.getMenu");
            console.log(this.getMenu);

            // let result = this.getMenu.filter(obj => obj.link == this.plantTypeMenu);
            // console.log(result);

            let index = this.getMenu.findIndex( (ob) => (ob.link.localeCompare( this.plantTypeMenu ) == 0));

            if(index >= 0) {
                //console.log(this.getMenu[this.plantTypeMenu].components);
                return this.getMenu[index];
            } else{
                return [];
            }
        },
        compTopics: function(){
            // console.log("this.getMenu");
            // console.log(this.getMenu);
            let index = this.getMenu.findIndex( (ob) => (ob.link.localeCompare( this.plantTypeMenu ) == 0));
            //console.log("compTopics: " + index);


            if(index >= 0) {
                return this.getMenu[index].components;
            }
            //if(this.getMenu[this.plantTypeMenu]){
            //    return this.getMenu[this.plantTypeMenu].components; // array
            //} 
            else {
                return [];
            }
        },
        moduleTopics: function(){

            let index = this.getMenu.findIndex( (ob) => (ob.link.localeCompare( this.plantTypeMenu ) == 0));

            if(index >= 0) {
                //console.log(this.getMenu[this.plantTypeMenu].components);
                return this.getMenu[index].modules;
            }else {
                return [];
            }
        },
    },
    methods: {
        toggleMenu: function(){
            console.log("toggleMenu");
            this.open = !this.open;
        }
    }
}
</script>

<style scoped>
    #menu {
        
        position: relative;
        top: 0px;
        left: 0px;
        transition: left 0.5s;
        padding: 0px;
        color: #000;
        box-shadow: 2px 1px 3px #aaa;

        /* height: 100%; */
        /* padding-left: 20px; */
    }
    #menu .card{
        background-color: #fff;
    }
    
    .card-body {
        padding-top: 10px;
        padding-bottom: 0px;
    }

    h4.card-title {
        font-size: 1.2em;
    }

    li {
        /* display: block; */
        margin: 3px;
        padding: 0px;
        color: #fff;
        list-style: none;
        /* list-style: none; */
         /* text-transform: uppercase; */
    }
    li a{
        display: block;
        padding: 6px 10px;
        transition: all 0.5s;
        cursor: pointer;
        
        /* width: 100%;
        height: 100%; */
    }
    .card {
        border: none;
    }

    a.close {
        background-color:#244e72;
        border-radius: 0px;
    }
    a:hover {
        background-color: #006dee;
    }
    a:active {
        background-color: #cecece;
    }
    ul {
        padding-inline-start: 0px;
        margin-right: 40px;
        margin-top: 50px;
    }

    .btn-secondary{
        background-color: #cecece;
        /* border-width: 0px; */
;
    }

    @media (min-width: 1600px){
        .col-xxxl-2 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 16.6666667%;
            flex: 0 0 16.6666667%;
            max-width: 16.6666667%;
        }
    }

    #menu.menuClosed{
        transition: left 0.5s;
        left: -17%;
    }

   
    @media(min-width:768px){}
    @media(max-width:1600px){
        #menu.menuClosed{
            transition: left 0.5s;
            left: -25.5%;
        }
    }
   @media(max-width:1200px){
        #menu.menuClosed{
            transition: left 0.5s;
            left: -34.5%;
        }
    }
    @media(max-width:992px){
        #menu.menuClosed{
            transition: left 0.5s;
            left: -42%;
        }
    }
     @media(max-width:767px){
        #menu.menuClosed{
            transition: left 0.5s;
            left: -85%;
        }
    }
/* @media(min-width:1200px){
    #menu.menuClosed{
        transition: left 0.5s;
        left: -32%;
    }
} */




    #menu-header {
        height: 0px;
    }
    #menu-header button.menuClosed{
        right: -75px;
        transition: right 0.5s;
    }

    #toggleComponentesModules {
        padding-top: 12px;
        padding-bottom: 10px;
        margin-left: 10px;
    }
    #toggleComponentesModules :not(.collapsed) {
        background-color: #006dee;
    }
    button.menuClosed:before {
        transition: transform 0.7s;
        transform: rotate(90deg);
    }
    button.menuClosed:after {
        transition: transform 0.7s;
        transform: rotate(0deg);
    }

    .close {
        position: absolute;
        transition: right 0.5s;
        right: 0px;
        top: 12px;
        width: 30px;
        height: 30px;
        /* opacity: 0.3; */
    }
    .close:hover {
        opacity: 1;
    }
    .close:before, .close:after {
        position: absolute;
        left: 14px;
        top: 3px;
        content: ' ';
        height: 24px;
        width: 2px;
        background-color: #fff;
    }
    .close:before {
        transition: transform 0.7s;
        transform: rotate(45deg);
    }
    .close:after {
        transition: transform 0.7s;
        transform: rotate(-45deg);
    }    
</style>