<template>

    <g :transform="transform" @click="$emit('open-showcase')" @mouseenter="setHover(true)" @mouseleave="setHover(false)">
        <rect :class='[{settingsHover: hover}, "button"]' x=0 y=0 :width='size' :height='size'></rect>
        <g :transform='transformIcon'>
            <!-- <path class="noStroke" :fill="fill"  d="M0.9-3.7l2.9,2.9c0.1,0.1,0.1,0.3,0,0.4l-7.1,7.1l-3,0.3C-6.7,7-7,6.7-7,6.3l0.3-3l7.1-7.1C0.5-3.9,0.7-3.9,0.9-3.7L0.9-3.7
	z M6.6-5.1L5.1-6.6c-0.5-0.5-1.3-0.5-1.8,0L2.1-5.4C2-5.3,2-5.1,2.1-5L5-2.1C5.1-2,5.3-2,5.4-2.1l1.2-1.2C7.1-3.8,7.1-4.6,6.6-5.1
	L6.6-5.1z"/> -->
            <path class="noStroke" :fill="fill" d="M0-9c-5,0-9,4-9,9c0,5,4,9,9,9s9-4,9-9C9-5,5-9,0-9z M0,7.3C-4,7.3-7.3,4-7.3,0C-7.3-4-4-7.3,0-7.3C4-7.3,7.3-4,7.3,0
	C7.3,4,4,7.3,0,7.3z M0-5c0.8,0,1.5,0.7,1.5,1.5S0.8-2,0-2s-1.5-0.7-1.5-1.5S-0.8-5,0-5z M2,4.2c0,0.2-0.2,0.4-0.4,0.4h-3.2
	C-1.8,4.6-2,4.5-2,4.2V3.3c0-0.2,0.2-0.4,0.4-0.4h0.4V0.6h-0.4C-1.8,0.6-2,0.4-2,0.1v-0.9C-2-1-1.8-1.2-1.6-1.2h2.3
	C1-1.2,1.2-1,1.2-0.7v3.6h0.4C1.8,2.9,2,3.1,2,3.3V4.2z"/>
        </g>

    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'ShowcaseButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;
            if(val){
                this.$emit('set-hover', val);
            }

            if(val){
                this.fill = "#006eee";
            } else {
                this.fill = "#999";
            }
        }
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999"
        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(0.9, 0.9)`;
        }
    },
}
</script>

<style scoped>
rect {
    fill: rgba(0, 0, 0, 0);
    stroke-width:1;
    stroke: none;
    cursor: pointer;
}

.noStroke {
    stroke-width:0;
}
</style>