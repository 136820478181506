<template>
    <g :transform="transform">
        <ellipse fill="#fff" stroke="#ccc" stroke-width="1.3" :cx="0" :cy="0" :rx="size/2" :ry="size/2"></ellipse>
        <g transform="translate(0,0), scale(0.04, 0.04)">
            <path class="noStroke" :fill="fill" d="M20-19.9h115.9v-76.2c0-10.7,12.9-16,20.5-8.5l96.1,96.1c4.7,4.7,4.7,12.3,0,17l-96.1,96.1c-7.6,7.6-20.5,2.2-20.5-8.5V20.1
	H20v115.8h76.1c10.7,0,16,12.9,8.5,20.5L8.5,252.5c-4.7,4.7-12.3,4.7-17,0l-96.1-96.1c-7.6-7.6-2.2-20.5,8.5-20.5H-20V20.1h-115.9
	v76.1c0,10.7-12.9,16-20.5,8.5l-96.1-96.1c-4.7-4.7-4.7-12.3,0-17l96.1-96.1c7.6-7.6,20.5-2.2,20.5,8.5v76.1H-20v-115.9h-76.1
	c-10.7,0-16-12.9-8.5-20.5l96.1-96.1c4.7-4.7,12.3-4.7,17,0l96.1,96.1c7.6,7.6,2.2,20.5-8.5,20.5H20V-19.9L20-19.9z"/>        </g>
        <!-- :class="'[{settingsHover: hover}]'" -->
        <rect v-on:mousedown="emitMouseDown" :x="-size/2" :y="-size/2" :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
        <!-- <text x=2 y=8 font-family="Verdana" font-size="8" fill="grey">Set.</text> -->
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'HandleSVG',
    methods:{
        setHover(val){
            this.hover = val;

            if(val){
                this.fill = "#006eee";
            } else {
                this.fill = "#999";
            }
        },
        emitMouseDown(e){
            this.$emit('mouse-down', e);
        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        }
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999"
        }
    }
}
</script>

<style scoped>

rect {
    fill: rgba(255,255,255,0);
    cursor: pointer;
    /* stroke: black; */
}
.settingsHover {
    fill: #2196F3;
}
.noStroke {
    stroke-width:0;
}
</style>