<template>
    <g class="module" :id="id" :transform='transform'>
        <!-- temp transform -->
        <g transform='translate(0, 0)'>
            <rect x=0 y=0 stroke="rgb(200, 200, 200)" :width='width' :height='height' :class="{addMode: editModule}" style="pointer-events: none;"></rect> <!-- v-on:mouseenter='hover' v-on:mouseleave='unhover'--> <!-- v-on:mousedown='startDragElement' v-on:touchstart='startDragElement' -->
            <move-button-svg :x="moveButtonX" :y="moveButtonY" :size="moveButtonSize" v-on:mousedown='startDragElement'></move-button-svg>

            <!-- <settings-button-svg :x="settingsX" :y="settingsY" :size=32 v-on:open-settings='openSettings'></settings-button-svg> -->
            <delete-button-svg v-if="moduleId >= 0" :x="settingsX" :y="settingsY" :size=32 v-on:delete-component='deleteModule' ></delete-button-svg>
            <edit-module-button-svg v-if="moduleId >= 0" :x="settingsX" :y="settingsY + 32" :active="editModule" :size=32 v-on:enable-edit-module='toggleEditModule' ></edit-module-button-svg>
            <duplicate-button-svg v-if="moduleId >= 0" :x="settingsX" :y="settingsY + 64" :size=32 v-on:duplicate-component='duplicatModule'></duplicate-button-svg>
        </g>
    </g>

</template>

<script>
// import SettingsButtonSVG from "./SettingsButtonSVG"
import DeleteComponentButtonSVG from "./DeleteComponentButtonSVG"
import DuplicateButtonSVG from "./DuplicateButtonSVG"
import EditModuleButtonSVG from "./EditModuleButtonSVG"
import MoveButtonSVG from "./MoveButtonSVG"
import posHelper from './PositionHelperFunctions'
import { EventBus } from './event-bus'
import { globalStore } from '../main.js'

//import { mapActions } from 'vuex';

export default {
    name: 'ModuleContainerSVG',
    components: {
        // "settings-button-svg": SettingsButtonSVG,
        "delete-button-svg": DeleteComponentButtonSVG,
        "duplicate-button-svg": DuplicateButtonSVG,
        "edit-module-button-svg": EditModuleButtonSVG,
        "move-button-svg": MoveButtonSVG,
    },
    props: {
        compIDs: {
            type: Array,
            default: function () {
                return [];
            }
        },
        moduleId: {
            type: Number,
            required: true
        },
        id: {
            type: String,
            default: "",
        }
    },
    data(){
        return {
                offset: 5,
                raster: 20,
                moveButtonSize: 32,
                pos1: '',
                pos2: '',
                pos3: '',
                pos4: '',
                moveX: 0,
                moveY: 0,
                touchElement: null,
                editModule: false,
        }
    },
    methods: {
        //...mapActions(['updateComponentOffset']),
        getScalefactor: function(){
            return globalStore.zoomfactor;
        },  
        openSettings: function(){

        },
        toggleEditModule(){
            this.editModule = !this.editModule;
            if(this.editModule){
                // deselect all other modules
                EventBus.$emit("reset-edit-module", this.moduleId);
                globalStore.editModuleID = this.moduleId;
            }else {
                globalStore.editModuleID = -1;
            }
        },
        deleteModule: function(){
            // reset edit module
            if(this.editModule){
                this.toggleEditModule();
            }

            EventBus.$emit('deleteModule', this.moduleId);  
        },
        duplicatModule: function(){
            EventBus.$emit('duplicatModule', this.moduleId);  
        },
        hover(e){
                //console.log("hover");
                e.target.classList.add('hoverClass');
        },
        unhover(e){
                //console.log("unhover");
                e.target.classList.remove('hoverClass');
        },
        startDragElement(e){						
                this.pos3 = e.clientX;
                this.pos4 = e.clientY;
                
                if(e.touches){
                    console.log(e.touches)
                    var touch = e.touches[0];
                    this.touchElement =  document.elementFromPoint(touch.pageX,touch.pageY);
                    console.log(this.touchElement);
                    document.ontouchmove = this.dragElementTouch;

                    // todo: safari kann kein ontouchend!
                    document.ontouchend = this.closeDragElement;
                } else {

                    console.log("start drag");
                    document.onmouseup = this.closeDragElement;
                    document.onmousemove = this.dragElement;
                }
        },
        dragElementTouch(e){
                e = e || window.event;
                //e.preventDefault();
                // calculate the new cursor position:
                this.pos1 = this.pos3 - e.touches[0].clientX;
                this.pos2 = this.pos4 - e.touches[0].clientY;
                this.pos3 = e.touches[0].clientX;
                this.pos4 = e.touches[0].clientY;

                // set Temp Position:
                this.moveX -= this.pos1;
                this.moveY -= this.pos2;
                this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX / this.getScalefactor()},  ${this.moveY / this.getScalefactor()} )`);
        },
        dragElement(e){
                //console.log(e);
                //this.position.top = e.
                    e = e || window.event;
                    e.preventDefault();
                    // calculate the new cursor position:
                    this.pos1 = this.pos3 - e.clientX;
                    this.pos2 = this.pos4 - e.clientY;
                    this.pos3 = e.clientX;
                    this.pos4 = e.clientY;
                    // set Temp Position:
                    console.log("dragElement");

                    // temp transform:
                    this.moveX -= this.pos1;
                    this.moveY -= this.pos2;
                    this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX / this.getScalefactor()},  ${this.moveY / this.getScalefactor()} )`);

                    if(e.touches){
                        var touch = e.touches[0];
                        if (this.touchElement !== document.elementFromPoint(touch.pageX,touch.pageY)) {
                            this.closeDragElement();
                        }
                    }
        },
        closeDragElement(){
                console.log();
                console.log("called closeDrag Element");
                document.ontouchend = null;
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchmove = null;

                this.touchElement = null;

                let offset = {top:  this.roundTo(this.moveY / this.getScalefactor(), this.raster), left: this.roundTo(this.moveX / this.getScalefactor(), this.raster)};

                console.log("move offset: " );
                console.log(offset );                
                //let pos = { top: this.roundTo(parseInt(this.position.top) + this.moveY, this.raster), left: this.roundTo(parseInt(this.position.left) + this.moveX, this.raster) };
                // console.log("new position");
                // console.log(pos);

                //this.$el.setAttribute('transform', `translate( ${pos.left},  ${pos.top} )`);

                // reset temp position
                this.moveX = 0;
                this.moveY = 0;
                //this.$el.getElementsByTagName('g')[0].setAttribute('transform', `translate( ${this.moveX},  ${this.moveY} )`);
                
                // TODO : set all Components of module:
                //this.updateComponent({ id: this.id, position: pos});  
                for(let i = 0; i<this.compIDs.length; i++){
                    let data = {
                        id: this.compIDs[i], 
                        offset: offset
                    }
                    console.log(data);

                    if(i == 0){
                        // nur ein backup bei Modulbewegung
                        data.noBackup = false;
                    } else {
                        data.noBackup = true;
                    }
                    //this.updateComponentOffset(data);

                    // Endstation EventBus: App.vue - created {}
                    EventBus.$emit('moveComponentSave', data);  
                }              
        },
        roundTo(val, roundVal){
                return Math.round(parseInt(val) / roundVal) * roundVal
        },
    },
    computed: {
        width(){
            return this.BBox.width > 0 ? this.BBox.width + 2* this.offset : 0;
        },
        height(){
            return this.BBox.height > 0 ? this.BBox.height + 2* this.offset : 0;
        },
        transform(){
            return `translate(${this.BBox.minX - this.offset}, ${this.BBox.minY - this.offset})`;
        },
        settingsX(){
            return this.BBox.width > 0 ? (this.BBox.width + 2* this.offset + 2) : 0;
        },
        settingsY(){
            return 0;
        },
        moveButtonX(){
            return this.BBox.width > 0 ? this.offset : 0;
        },
        moveButtonY(){
            return this.offset; //-this.buttonSize -2; //this.bbox.height > 0 ? (-this.buttonSize-2) : 0;
        },
        BBox(){
                let minX = 99999999;
                let maxX = -99999999;
                let minY = 99999999;
                let maxY = -99999999;
                // get min / max positon of elements

                //console.log("bbox: " +  this.moduleId);

                for(let j = 0; j<this.compIDs.length; j++){
                    //console.log("this.compIDs[" + j + "]: " + this.compIDs[j]);
                    var _bbox = posHelper.getComponentBoundingBox(this.compIDs[j]);
                    //console.log(_bbox);
                    if(_bbox){
                        if(minX > _bbox.minX){
                            minX = _bbox.minX
                        }
                        if(minY > _bbox.minY){
                            minY = _bbox.minY
                        }
                        if(maxX < _bbox.maxX){
                            maxX = _bbox.maxX
                        }
                        if(maxY < _bbox.maxY){
                            maxY = _bbox.maxY
                        }
                    }
                }
                return {
                    minX: minX - this.moveButtonSize,
                    maxX: maxX,
                    minY: minY,
                    maxY: maxY,
                    width: maxX - minX + this.moveButtonSize,
                    height: maxY - minY
                };
        }
    },
    created() {
        //console.log("module created: " + this.moduleId  + " " +  globalStore.editModuleID);
        if (this.moduleId >= 0 && this.moduleId == globalStore.editModuleID) {
            this.editModule = true;
        }



        EventBus.$on("reset-edit-module", id => {
            if (this.moduleId != id) {
                this.editModule = false;
                //this.setHover(false);
            }
        });
    }
}
</script>

<style scoped>
    .hoverClass {
        fill: rgba(235, 235, 235, 0.333);
        cursor: move;
    }
    rect {
        fill: rgba(255, 255,255, 0.01);
    }
    .addMode {
        fill: rgba(89, 158, 242, 0.3) !important;
    }
</style>