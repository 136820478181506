<template>

    <g :transform="transform" @click="$emit('toggle-module')" @mouseenter="setHover(true)" @mouseleave="setHover(false)">
        <rect :class='[{settingsHover: hover}, "button"]' :x="-size/2" :y="-size/2" :width='size' :height='size'></rect>
        <g v-if="addTo == false" :transform='transformIcon'>
            <path class="noStroke" :fill="fill" d="M5.3,1.3c0.5,0,0.9-0.4,0.9-0.9v-0.8c0-0.5-0.4-0.9-0.9-0.9H-5.3c-0.5,0-0.9,0.4-0.9,0.9v0.8c0,0.5,0.4,0.9,0.9,0.9H5.3z"/>
            <!-- <path class="noStroke" :fill="fill" d="M-7.3,1.3c-0.5,0-0.9-0.4-0.9-0.9v-0.8c0-0.5,0.4-0.9,0.9-0.9H7.3c0.5,0,0.9,0.4,0.9,0.9v0.8c0,0.5-0.4,0.9-0.9,0.9H-7.3z" -->
	/>
            <!-- <rect class="noStroke" :fill="fill" x="-7.3" y="-1" width="14.6" height="2"/> -->
            <!-- <path class="noStroke" :fill="fill" d="M0.9-3.7l2.9,2.9c0.1,0.1,0.1,0.3,0,0.4l-7.1,7.1l-3,0.3C-6.7,7-7,6.7-7,6.3l0.3-3l7.1-7.1C0.5-3.9,0.7-3.9,0.9-3.7L0.9-3.7
	z M6.6-5.1L5.1-6.6c-0.5-0.5-1.3-0.5-1.8,0L2.1-5.4C2-5.3,2-5.1,2.1-5L5-2.1C5.1-2,5.3-2,5.4-2.1l1.2-1.2C7.1-3.8,7.1-4.6,6.6-5.1
	L6.6-5.1z"/> -->
        </g>
        <g v-if="addTo == true" :transform='transformIcon'>
            <path class="noStroke" :fill="fill" d="M8.2-0.4c0-0.5-0.4-0.9-0.9-0.9H1.2v-6.1c0-0.5-0.4-0.9-0.9-0.9h-0.8c-0.5,0-0.9,0.4-0.9,0.9v6.1h-6.1
	c-0.5,0-0.9,0.4-0.9,0.9v0.8c0,0.5,0.4,0.9,0.9,0.9h6.1v6.1c0,0.5,0.4,0.9,0.9,0.9h0.8c0.5,0,0.9-0.4,0.9-0.9V1.3h6.1
	c0.5,0,0.9-0.4,0.9-0.9V-0.4z"/>
            <!-- <polygon class="noStroke" :fill="fill" points="7.3,-1 1,-1 1,-7.3 -1,-7.3 -1,-1 -7.3,-1 -7.3,1 -1,1 -1,7.3 1,7.3 1,1 7.3,1 "/> -->
            <!-- <path class="noStroke" :fill="fill"  d="M0.9-3.7l2.9,2.9c0.1,0.1,0.1,0.3,0,0.4l-7.1,7.1l-3,0.3C-6.7,7-7,6.7-7,6.3l0.3-3l7.1-7.1C0.5-3.9,0.7-3.9,0.9-3.7L0.9-3.7
	z M6.6-5.1L5.1-6.6c-0.5-0.5-1.3-0.5-1.8,0L2.1-5.4C2-5.3,2-5.1,2.1-5L5-2.1C5.1-2,5.3-2,5.4-2.1l1.2-1.2C7.1-3.8,7.1-4.6,6.6-5.1
	L6.6-5.1z"/> -->
        </g>

    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'ModuleButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;
            if(val){
                this.$emit('set-hover', val);
            }

            if(val){
                this.fill = "#006eee";
            } else {
                this.fill = "#999";
            }
        }
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true
        },
        addTo: {
            type: Boolean,
            required: true,
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999"
        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
            //return `translate(${this.x} - ${this.size/2}, ${this.y}  - ${this.size/2})`;
        },
        transformIcon(){
            //return `translate(${this.size/2}, ${this.size/2}), scale(1.3, 1.3)`;
            return `scale(1.3, 1.3)`;
        }
    },
}
</script>

<style scoped>
rect {
    fill: white;
    stroke-width:1;
    stroke: black;
    cursor: pointer;
}

.noStroke {
    stroke-width:0;
}
</style>