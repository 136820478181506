import Vuex from 'vuex';
import Vue from 'vue';
import plants from './modules/plants'

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules:{
        plants
    }
})