<template>
    <g :transform="transform">
        <rect x=0 y=0 :width='size' :height='size' class='background'><!-- <i class="fas fa-trash-alt" /> --></rect>
        <g :transform="transformIcon">
            <path class="noStroke" :fill="fill" d="M-4.6-9.9v2.7h-2.7v2.7h-2.7V9.9H4.6V7.3h2.7V4.6h2.7V-9.9H-4.6z M3.6,4.1v4.8H-8.9V-3.6H3.6L3.6,4.1z M6.3,4.1v2.2H4.6 V-4.6H-6.3v-1.7H6.3L6.3,4.1z M9,3.6H7.3V-7.3H-3.6v-1.7H8.9L9,3.6z"/>
            <polygon class="noStroke" :fill="fill" points="-6.7,3.2 -3.2,3.2 -3.2,6.8 -2.2,6.8 -2.2,3.2 1.4,3.2 1.4,2.2 -2.2,2.2 -2.2,-1.4 -3.2,-1.4 -3.2,2.2 -6.7,2.2"/>
        </g>
        <!-- :class='[{deleteHover: hover}]' class="button" -->
        <rect @click="$emit('duplicate-component')" :width='size' :height='size' @mouseenter="setHover(true)" @mouseleave="setHover(false)"><!-- <i class="fas fa-trash-alt" /> --></rect>
    </g>
</template>

<script>
// import { mapActions } from 'vuex';
export default {
    name: 'DuplicateButtonSVG',
    methods:{
        setHover(val){
            this.hover = val;

            if(val){
                this.fill = "#006eee"
            } else {
                this.fill = "#999"
            }
        },
    },
    props: {
        x: {
            type: Number,
            required: true
        },
        y: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: true,        }
    },
    computed: {
        transform(){
            return `translate(${this.x}, ${this.y})`;
        },
        transformIcon(){
            return `translate(${this.size/2}, ${this.size/2}), scale(1.2, 1.2)`;
        }
    },
    data(){
        return{ 
            hover: false,
            fill: "#999",
        }
    }
}
</script>

<style scoped>
.background{
    fill: #fff;
}
rect {
    fill: rgba(255, 255,255, 0);
    cursor: pointer;
}
.noStroke {
    stroke-width:0;
}
</style>